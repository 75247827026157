import axios from "axios";

import { getUrlInGeneric } from "./url";

import { updateIsMappingSaved } from "../slice/mappingSlice";

import { IMappingItem, ISaveMappingPostBody } from "../types/mappingTypes";
import { Dispatch, UnknownAction } from "@reduxjs/toolkit";

import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../slice/preferenceSlice";

interface IProps {
  dispatch: Dispatch<UnknownAction>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  mappings: IMappingItem[];
  mappingsFor: string;
  userIds: {
    [k: string]: string | number;
  };
  isAccordion?: boolean;
}

/**
 * This function is used to save the mappings
 */
export const onSaveMappings = async (props: IProps) => {
  const {
    dispatch,
    setIsLoading,
    mappings,
    mappingsFor,
    userIds,
    isAccordion = false,
  } = props;

  try {
    setIsLoading(true);

    const validMappings = [];

    if (isAccordion) {
      Object.keys(mappings).forEach((key) => {
        mappings[key].forEach((el: IMappingItem) => {
          const newMapping: IMappingItem = {
            CRF: el.CRF,
            HRF: el.HRF,
            Type: el.Type,
            Description: el.Description,
            Accordion: el.Accordion,
            triggerApp: {},
          };

          if (el.Required) newMapping.Required = el.Required;

          if (el?.triggerApp) {
            Object.keys(el.triggerApp).forEach((key) => {
              if (el.triggerApp[key] && el.triggerApp[key].length > 0) {
                newMapping.triggerApp[key] = el.triggerApp[key];
              }
            });
          }

          if (Object.keys(newMapping.triggerApp).length > 0)
            validMappings.push(newMapping);
        });
      });
    } else {
      mappings.forEach((el) => {
        const newMapping: IMappingItem = {
          CRF: el.CRF,
          HRF: el.HRF,
          Type: el.Type,
          Description: el.Description,
          triggerApp: {},
        };

        if (el.Required) newMapping.Required = el.Required;

        if (el?.triggerApp) {
          Object.keys(el.triggerApp).forEach((key) => {
            if (el.triggerApp[key] && el.triggerApp[key].length > 0) {
              newMapping.triggerApp[key] = el.triggerApp[key];
            }
          });
        }

        if (Object.keys(newMapping.triggerApp).length > 0)
          validMappings.push(newMapping);

        if (isAccordion) newMapping.Accordion = el.Accordion;
      });
    }

    let data: ISaveMappingPostBody = {
      ...userIds,
      mappings: validMappings,
    };

    if (mappingsFor.includes("product")) {
      data = {
        ...data,
        mappingObject: "productSync",
        subMappingObject: mappingsFor,
      };
    } else {
      data = {
        ...data,
        mappingObject: mappingsFor,
      };
    }

    await axios.post(getUrlInGeneric("REACT_APP_SAVE_MAPPINGS"), data);
    dispatch(updateIsMappingSaved({ mappingsFor }));
    dispatch(setShowSuccessModal({ message: "Mappings saved successfully" }));
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};
