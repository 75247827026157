import React from "react";

interface IProps {
  labels: {
    actionApp: string;
    triggerApp: string;
  };
}

const MappingHeader = (props: IProps) => {
  const { labels } = props;

  return (
    <thead className="table-header">
      <tr>
        <th className="label ps-4">{labels.actionApp}</th>
        <th className="label ps-4">{labels.triggerApp}</th>
        <th className="label"></th>
      </tr>
    </thead>
  );
};

export default MappingHeader;
