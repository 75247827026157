import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";

import {
  CompanySearch,
  HandlingCustomerNumber,
  CustomerNumber,
  ButtonWithTooltip,
} from "generic";
import { CmsRichText } from "cms";
import { Loading } from "pipedrive-frontend";

import { IMsbcStates } from "msbc-frontend/types/msbcTypes";
import { ICmsData } from "cms/types/cmsTypes";
import { IPdInitialState, IFieldItem } from "pipedrive-frontend/types/types";

const CustomerSyncRules = () => {
  const customerNumberFields: { title: string; value: string }[] = [
    {
      title: "Let MS business central create customer number",
      value: "action",
    },
    {
      title: "Take customer number from Pipedrive",
      value: "trigger",
    },
  ];

  const ecoCustomerNumberPreference: { [k: string]: string } = {
    name: "Customer Number",
    value: "customerNumber",
  };

  const actionAppCustomerNumberFields: IFieldItem[] = [
    {
      CRF: "customerNumber",
      HRF: "Customer Number",
      type: "string",
      description: "",
    },
  ];

  const {
    fields: {
      loading: msbcLoading,
      data: { customerSearchFields },
    },
  } = useSelector((state: { msbc: IMsbcStates }) => state.msbc);

  const {
    pipedriveUserIds,
    fields: {
      loading: pdLoading,
      data: {
        organization,
        person,
        organizationSearchFields,
        personSearchFields,
      },
    },
  } = useSelector((state: { pipedrive: IPdInitialState }) => state.pipedrive);

  const {
    cmsData: { loading: cmsLoading },
  } = useSelector((state: { cms: ICmsData }) => state.cms);

  const searchFilterOptionKeys = ["organization", "person"];

  return (
    <Container className="px-0">
      {cmsLoading || pdLoading || msbcLoading ? (
        <Loading />
      ) : (
        <div className="mb-4">
          <CompanySearch
            triggerAppLabel="Pipedrive"
            actionAppLabel="MS business central"
            triggerAppCompanySearchFields={organizationSearchFields}
            triggerAppContactSearchFields={personSearchFields}
            actionAppCustomerSearchFields={customerSearchFields}
            dropdownClassName="settings-dropdown"
            cardClassName="sync-settings-card"
            CmsRichText={CmsRichText}
          />
          <HandlingCustomerNumber
            customerNumberFields={customerNumberFields}
            CmsRichText={CmsRichText}
            cardClassName="sync-settings-card"
            cmsClassName="pipedrive"
          />
          <CustomerNumber
            actionAppCustomerFields={actionAppCustomerNumberFields}
            actionAppName="MS business central"
            triggerAppName="Pipedrive"
            dropdownClassName="settings-dropdown"
            cardClassName="sync-settings-card"
            triggerAppCompanyFields={organization}
            triggerAppContactFields={person}
            actionAppCustomerNumberPreference={ecoCustomerNumberPreference}
            cssName="settings-dropdown"
            isDisabled={true}
            CmsRichText={CmsRichText}
          />
          <ButtonWithTooltip
            actionAppName="Business Central"
            triggerAppName="Pipedrive"
            savePreferencesFor="customerSyncRules"
            preferenceType="customerSync"
            userIds={pipedriveUserIds}
            disableBtnCssName="pd-disabled-btn"
            btnCssName="pd-save-changes-btn"
            searchFilterOptionKeys={searchFilterOptionKeys}
          />
        </div>
      )}
    </Container>
  );
};

export default CustomerSyncRules;
