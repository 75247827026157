import React, { useEffect } from "react";
import { Loading, updatePipedriveUserIds } from "pipedrive-frontend";
import {
  CmsRichText,
  fetchCmsData,
  onChangeIsUserAllowed,
  CmsEditAndSave,
} from "cms";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  setShowErrorModal,
  setShowSuccessModal,
  SetupGuideHeader,
  SetupGuide,
} from "generic";

import { setupGuideKeys, setupGuideTitles } from "../helpers/setupGuideKeys";

import { IPdInitialState } from "pipedrive-frontend/types/types";
import { ICmsData } from "cms/types/cmsTypes";

import cloudifyLogo from "../assets/images/cloudifyLogo.svg";

const SetupGuidePage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );
  const {
    cmsData: { loading: cmsLoading },
    allowedUsers,
  } = useSelector((state: { cms: ICmsData }) => state.cms);

  useEffect(() => {
    dispatch(fetchCmsData());
  }, []);

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    if (companyId) {
      dispatch(updatePipedriveUserIds({ companyId, userId }));
    }
  }, []);

  useEffect(() => {
    if (allowedUsers.length > 0) {
      dispatch(onChangeIsUserAllowed({ userId: pipedriveUserIds.userId }));
    }
  }, [allowedUsers]);

  return (
    <div>
      <SetupGuideHeader cloudifyLogo={cloudifyLogo} />
      {cmsLoading ? (
        <Loading />
      ) : (
        <>
          <div className="d-flex flex-column align-items-center mt-5">
            <CmsEditAndSave
              userIds={pipedriveUserIds}
              appSlug="pipedriveMsbc-quick-install"
              setShowErrorModal={setShowErrorModal}
              setShowSuccessModal={setShowSuccessModal}
              editNotificationPath={`userId=${searchParams.get(
                "userId"
              )}&appSlug=pipedriveMsbc-quick-install`}
            />
          </div>
          <SetupGuide
            CmsRichText={CmsRichText}
            setupGuideKeys={setupGuideKeys}
            setupGuideTitles={setupGuideTitles}
            contentCssName="setup-guide"
            installUrl="https://www.pipedrive.com/en/marketplace/app/microsoft-business-central-integration/ddad0faf2cd31fc0"
          />
        </>
      )}
    </div>
  );
};

export default SetupGuidePage;
