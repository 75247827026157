import React, { useEffect } from "react";
import {
  Tabs,
  Tab,
  Container,
  OverlayTrigger,
  Tooltip,
  Col,
  Button,
} from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchCmsData,
  onChangeIsUserAllowed,
  CmsEditAndSave,
  CmsRichText,
} from "cms";
import {
  updatePipedriveUserIds,
  fetchPipedriveFields,
  initalizePipedriveSDK,
  ManagePlan,
} from "pipedrive-frontend";
import {
  setShowErrorModal,
  setShowSuccessModal,
  fetchStatus,
  onChangeSettingsTabKey,
  clearDefaultValues,
  AutoSaveModal,
  GuideTour,
  updateShowSettingsTour,
  updateSettingsStoredKey,
} from "generic";
import {
  saveContactDefaultMappings,
  fetchMsbcFields,
  getDefaultMappingFields,
} from "msbc-frontend";

import { ICmsData } from "cms/types/cmsTypes";
import { IPdInitialState } from "pipedrive-frontend/types/types";
import { IPreferenceStates } from "generic/types/preferencetypes";
import { IProductStates } from "generic/types/productTypes";
import { IContactStates } from "generic/types/contactTypes";
import { IMappingSlice } from "generic/types/mappingTypes";
import { IMsbcStates } from "msbc-frontend/types/msbcTypes";
import { IInvoiceState } from "generic/types/invoiceTypes";
import { ISubscriptionSlice } from "generic/types/subscriptionTypes";

import Contact from "../components/settings/contact/Contact";
import Product from "../components/settings/product/Product";
import Invoice from "../components/settings/invoice/Invoice";
import Connect from "./Connect";

import sparkle from "../assets/images/sparkle.png";

const Settings = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const accordionFor = {
    customerSync: true,
    invoiceSync: true,
    products: false,
    productFields: false,
  };

  const actionAppKeys = ["customer", "invoice"];

  const {
    allowedUsers,
    cmsData: { loading: cmsLoading },
  } = useSelector((state: { cms: ICmsData }) => state.cms);
  const {
    pipedriveUserIds,
    fields: { loading: pipedriveLoading },
  } = useSelector((state: { pipedrive: IPdInitialState }) => state.pipedrive);
  const {
    settingsActiveTab,
    isAppConnected,
    guidedTourSteps,
    settingsStoredKey,
    showSettingsTour,
  } = useSelector(
    (state: { preference: IPreferenceStates }) => state.preference
  );
  const { isContactSyncRulesSaved } = useSelector(
    (state: { contact: IContactStates }) => state.contact
  );
  const {
    isContactDefaultMappingsSaved,
    defaultMappings,
    companyPreference,
    environmentPreference,
    tenantId,
    isEnvPreferencesSaved,
    fields: { loading: msbcLoading, data: msbcFields },
  } = useSelector((state: { msbc: IMsbcStates }) => state.msbc);
  const { isMappingSaved } = useSelector(
    (state: { mappings: IMappingSlice }) => state.mappings
  );
  const { isInvoiceSyncRulesSaved } = useSelector(
    (state: { invoice: IInvoiceState }) => state.invoice
  );
  const { isProductSyncRulesSaved } = useSelector(
    (state: { products: IProductStates }) => state.products
  );

  const {
    subscription: { planData },
  } = useSelector(
    (state: { subscription: ISubscriptionSlice }) => state.subscription
  );

  /**
   * Render tabs based on tab id
   * @param tabId - tab id string for conditional rendering
   * @returns
   */
  const renderTabs = (tabId: string) => {
    if (
      isAppConnected &&
      companyPreference &&
      environmentPreference &&
      tenantId &&
      isEnvPreferencesSaved
    ) {
      if (tabId === "contact") {
        return (
          <Tab title="Customer" eventKey="contact">
            <Contact />
          </Tab>
        );
      } else if (tabId === "product") {
        if (planData.plan === "basic") {
          return (
            <Tab
              title={
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip(
                    "These sync is not available in the Basic plan, please upgrade to the Premium plan"
                  )}
                >
                  <span>Products</span>
                </OverlayTrigger>
              }
            ></Tab>
          );
        } else {
          return (
            <Tab title="Products" eventKey="product">
              <Product />
            </Tab>
          );
        }
      } else if (tabId === "invoice") {
        if (planData.plan === "basic") {
          return (
            <Tab
              title={
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip(
                    "These sync is not available in the Basic plan, please upgrade to the Premium plan."
                  )}
                >
                  <span>Invoices/Orders</span>
                </OverlayTrigger>
              }
            ></Tab>
          );
        } else {
          return (
            <Tab title="Invoice/Order" eventKey="invoice">
              <Invoice />
            </Tab>
          );
        }
      }
    }
  };

  /**
   * Render connect tab components
   */
  // const renderConnectTabComponents = () => {
  //   if (!isAppConnected) {
  //     return (
  //       <Col lg={7}>
  //         <Connect />
  //       </Col>
  //     );
  //   } else if (!isEnvPreferencesSaved) {
  //     return <EnvironmentSelection />;
  //   } else {
  //     return (
  //       <Col lg={7}>
  //         <Connect />
  //       </Col>
  //     );
  //   }
  // };

  const renderTooltip = (content: string) => (
    <Tooltip style={{ fontSize: "12px" }}>{content}</Tooltip>
  );

  useEffect(() => {
    const id = searchParams.get("id");
    const path = window.location.pathname;
    if (id && path === "/settings") {
      initalizePipedriveSDK(id, 700, dispatch);
    }
  }, []);

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    if (companyId && userId) {
      dispatch(updatePipedriveUserIds({ companyId, userId }));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchCmsData());
  }, []);

  useEffect(() => {
    if (allowedUsers.length > 0) {
      dispatch(onChangeIsUserAllowed({ userId: pipedriveUserIds.userId }));
    }
  }, [allowedUsers]);

  useEffect(() => {
    if (pipedriveUserIds && Object.keys(pipedriveUserIds).length > 0) {
      const path = window.location.pathname;
      const tenant = searchParams.get("tenantId");

      if (path === "/settings" && tenant) {
        dispatch(
          fetchStatus({
            userIds: pipedriveUserIds,
            isSaved: true,
            accordionFor,
            fields: msbcFields,
            fieldKeys: actionAppKeys,
          })
        );
      } else {
        dispatch(
          fetchStatus({
            userIds: pipedriveUserIds,
            accordionFor,
            fields: msbcFields,
            fieldKeys: actionAppKeys,
          })
        );
      }
    }
  }, [pipedriveUserIds, msbcFields]);

  useEffect(() => {
    if (pipedriveUserIds && Object.keys(pipedriveUserIds).length > 0) {
      dispatch(fetchPipedriveFields({ userIds: pipedriveUserIds }));
    }
  }, [pipedriveUserIds]);

  useEffect(() => {
    if (
      pipedriveUserIds &&
      Object.keys(pipedriveUserIds).length > 0 &&
      isAppConnected &&
      companyPreference &&
      environmentPreference &&
      tenantId &&
      isEnvPreferencesSaved
    ) {
      dispatch(
        fetchMsbcFields({ userIds: pipedriveUserIds, setShowErrorModal })
      );
      dispatch(
        getDefaultMappingFields({
          userIds: pipedriveUserIds,
          setShowErrorModal,
        })
      );
    }
  }, [pipedriveUserIds, isAppConnected]);

  const searchFilterOptionKeys = ["organization", "person"];
  const triggerKeys = ["deal"];

  const renderButton = () => {
    const showTourGuide = localStorage.getItem(settingsStoredKey);

    if (showTourGuide === "true")
      return (
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip>Show guide</Tooltip>}
        >
          <Button
            className="floating-button"
            onClick={() => {
              const key = settingsStoredKey;
              localStorage.setItem(settingsStoredKey, "false");
              dispatch(updateSettingsStoredKey({ key: "" }));
              dispatch(updateSettingsStoredKey({ key }));
              dispatch(updateShowSettingsTour({ value: false }));
            }}
          >
            <img src={sparkle} alt="guide logo" className="sparkle-img" />
          </Button>
        </OverlayTrigger>
      );
  };

  return (
    <>
      <AutoSaveModal
        actionAppName="Business Central"
        userIds={pipedriveUserIds}
        isContactDefaultMappingsSaved={isContactDefaultMappingsSaved}
        defaultMappings={defaultMappings}
        saveContactDefaultMappings={saveContactDefaultMappings}
        CmsRichText={CmsRichText}
        contentCssName="pipedrive"
        searchFilterOptionKeys={searchFilterOptionKeys}
        triggerAppName="Pipedrive"
        triggerKeys={triggerKeys}
        actionAppFields={msbcFields}
        actionAppKeys={actionAppKeys}
        accordionFor={accordionFor}
      />
      <GuideTour
        steps={guidedTourSteps}
        loading={
          !pipedriveLoading && !msbcLoading && !cmsLoading ? true : false
        }
        storedKey={settingsStoredKey}
        showTour={showSettingsTour}
      />
      {settingsActiveTab !== "connect" &&
        settingsActiveTab !== "managePlan" &&
        renderButton()}
      <div>
        <div className="d-flex flex-column align-items-center mt-4">
          <CmsEditAndSave
            userIds={pipedriveUserIds}
            appSlug="pipedriveMsbc-quick-install"
            setShowSuccessModal={setShowSuccessModal}
            setShowErrorModal={setShowErrorModal}
            editNotificationPath={`userId=${searchParams.get(
              "userId"
            )}&appSlug=pipedriveMsbc-quick-install`}
          />
        </div>
        <Container>
          <div className="sync-settings-tabs">
            <Tabs
              defaultActiveKey={
                isAppConnected &&
                companyPreference &&
                environmentPreference &&
                tenantId &&
                isEnvPreferencesSaved
                  ? "contact"
                  : "connect"
              }
              activeKey={
                isAppConnected &&
                companyPreference &&
                environmentPreference &&
                tenantId &&
                isEnvPreferencesSaved
                  ? settingsActiveTab
                  : "connect"
              }
              onSelect={(selectedKey) => {
                dispatch(clearDefaultValues());
                dispatch(
                  onChangeSettingsTabKey({
                    selectedTab: selectedKey,
                    setupLoading: false,
                    isContactSyncRulesSaved,
                    isContactDefaultMappingsSaved,
                    isInvoiceSyncRulesSaved,
                    isProductSyncRulesSaved,
                    isContactMappingsSaved: isMappingSaved?.customerSync,
                    isInvoiceMappingsSaved: isMappingSaved?.invoiceSync,
                    isProductFieldMappingsSaved: isMappingSaved?.productFields,
                    isAppConnected,
                  })
                );
              }}
              style={{
                top: "0px",
                paddingTop: "20px",
              }}
            >
              {/* Contact tab */}
              {renderTabs("contact")}
              {/* Product tab */}
              {renderTabs("product")}
              {/* Invoice tab */}
              {renderTabs("invoice")}

              <Tab eventKey="connect" title="Connect">
                <div className="d-flex flex-row justify-content-center">
                  <Col lg={7}>
                    <Connect />
                  </Col>
                </div>
              </Tab>

              <Tab eventKey="managePlan" title="Manage plan">
                <ManagePlan />
              </Tab>
            </Tabs>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Settings;
