import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import { EnvSelection, fetchCompanies, fetchEnvironment } from "msbc-frontend";
import {
  Loading,
  BookConsultation,
  onChangeCurrentWindow,
  updatePipedriveUserIds,
} from "pipedrive-frontend";
import { CmsRichText } from "cms";
import { fetchStatus } from "generic";

import { ICmsData } from "cms/types/cmsTypes";
import { IMsbcStates } from "msbc-frontend/types/msbcTypes";
import { IPdInitialState } from "pipedrive-frontend/types/types";

const EnvironmentSelection = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const path = window.location.pathname;

  const {
    cmsData: { loading: cmsLoading },
  } = useSelector((state: { cms: ICmsData }) => state.cms);

  const {
    environment: { loading: envLoading },
    environmentPreference,
  } = useSelector((state: { msbc: IMsbcStates }) => state.msbc);

  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );

  const changeCurrentWindow = () => {
    if (window.location.pathname === "/setup") {
      setTimeout(() => {
        dispatch(
          onChangeCurrentWindow({ currentWindow: "Subscription Window" })
        );
        navigate(
          `../${window.location.pathname}?companyId=${pipedriveUserIds.companyId}&userId=${pipedriveUserIds.userId}&page=subscriptionPage`
        );
      }, 1500);
    }
  };

  useEffect(() => {
    if (pipedriveUserIds && Object.keys(pipedriveUserIds).length > 0) {
      dispatch(fetchEnvironment({ userIds: pipedriveUserIds }));
    }
  }, [pipedriveUserIds]);

  useEffect(() => {
    if (
      pipedriveUserIds &&
      Object.keys(pipedriveUserIds).length > 0 &&
      path === "/setup"
    ) {
      dispatch(fetchStatus({ userIds: pipedriveUserIds }));
    }
  }, [pipedriveUserIds]);

  useEffect(() => {
    if (
      pipedriveUserIds &&
      Object.keys(pipedriveUserIds).length > 0 &&
      environmentPreference
    ) {
      dispatch(
        fetchCompanies({
          userIds: pipedriveUserIds,
          environment: environmentPreference,
        })
      );
    }
  }, [environmentPreference]);

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    if (companyId && path === "/environment") {
      dispatch(updatePipedriveUserIds({ companyId, userId }));
    }
  }, []);

  return (
    <>
      {cmsLoading || envLoading ? (
        <Loading />
      ) : (
        <Container>
          <Row
            className={`d-flex ${
              path === "/environment"
                ? "justify-content-center"
                : "justify-content-between"
            } align-items-center`}
          >
            <Col md={path === "/settings" ? 8 : 5}>
              <EnvSelection
                cardCssName="env-selection"
                CmsRichText={CmsRichText}
                cmsCssName="pipedrive"
                userIds={pipedriveUserIds}
                triggerAppName="pipedrive"
                changeCurrentWindow={changeCurrentWindow}
              />
            </Col>
            {path === "/setup" && (
              <Col md={6}>
                <div>
                  <BookConsultation CmsRichText={CmsRichText} />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default EnvironmentSelection;
