import React from "react";
import { Card, Dropdown } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";

import { onChangeCustomerNumberPreference } from "../slice/contactSlice";

import { IContactStates, ICustomerNumberFields } from "../types/contactTypes";

type HandlingCustomerNumberProps = {
  customerNumberFields: ICustomerNumberFields[];
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
  cardClassName: string;
  cmsClassName: string;
};

const HandlingCustomerNumber = (props: HandlingCustomerNumberProps) => {
  const { customerNumberFields, CmsRichText, cardClassName, cmsClassName } =
    props;

  const dispatch = useDispatch();

  const { customerNumberPreference } = useSelector(
    (state: { contact: IContactStates }) => state.contact
  );

  const toggleCustomerNumberText = (selectedValue: string) => {
    return customerNumberFields.find((field) => field.value === selectedValue)
      .title;
  };

  return (
    <Card className={cardClassName}>
      <Card.Body className="sync-settings-card-body">
        <div className="handling-customer-number">
          <CmsRichText
            path="cmsContent.settings.contacts.syncRules.block2.text"
            cssName={cmsClassName}
          />
          <Dropdown className="my-3">
            <Dropdown.Toggle className="sync-settings-card-dropdown">
              {toggleCustomerNumberText(customerNumberPreference.value)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {customerNumberFields &&
                customerNumberFields.map((field) => {
                  return (
                    <Dropdown.Item
                      key={field.value}
                      onClick={() => {
                        dispatch(
                          onChangeCustomerNumberPreference({
                            selectedOption: field,
                          })
                        );
                      }}
                    >
                      {field.title}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
          {customerNumberPreference.value === "action" && (
            <CmsRichText
              path="cmsContent.settings.contacts.syncRules.block2.createCustomer"
              cssName={cmsClassName}
            />
          )}
          {customerNumberPreference.value === "trigger" && (
            <CmsRichText
              path="cmsContent.settings.contacts.syncRules.block2.takeCustomer"
              cssName={cmsClassName}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default HandlingCustomerNumber;
