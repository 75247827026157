import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";

import {
  updateItemOffset,
  updatePageCount,
  updateSlicedMappings,
  updateSelectedIndex,
} from "../slice/mappingSlice";

import { IMappingItem, IMappingSlice } from "../types/mappingTypes";

interface IProps {
  mappingsFor: string;
  mappings: IMappingItem[];
}

const Pagination = (props: IProps) => {
  const { mappings, mappingsFor } = props;
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const {
    itemOffset,
    pageCount,
    selectedIndex,
    searchFilteredArray,
    searchInput,
  } = useSelector((state: { mappings: IMappingSlice }) => state.mappings);

  useEffect(() => {
    if (mappingsFor === "products") {
      if (
        mappings.length > 0 &&
        searchFilteredArray.length > 0 &&
        searchInput
      ) {
        const endOffset = itemOffset + itemsPerPage;

        const test = mappings.filter((mappedItem) => {
          if (searchFilteredArray.includes(mappedItem.id)) {
            return true;
          }
          return false;
        });
        dispatch(
          updateSlicedMappings({
            mappings: test.slice(itemOffset, endOffset),
          })
        );
        dispatch(
          updatePageCount({
            count: Math.ceil(searchFilteredArray.length / itemsPerPage),
          })
        );
      } else if (mappings.length > 0) {
        const endOffset = itemOffset + itemsPerPage;
        dispatch(
          updateSlicedMappings({
            mappings: mappings.slice(itemOffset, endOffset),
          })
        );
        dispatch(
          updatePageCount({
            count: Math.ceil(mappings.length / itemsPerPage),
          })
        );
      }
    }
  }, [itemOffset, itemsPerPage, mappings, searchFilteredArray]);

  useEffect(() => {
    if (mappingsFor === "products") {
      if (
        mappings.length > 0 &&
        searchFilteredArray.length > 0 &&
        searchInput
      ) {
        const newOffset =
          (selectedIndex * itemsPerPage) % searchFilteredArray.length;
        dispatch(updateItemOffset({ itemOffset: newOffset }));
      } else {
        const newOffset = (selectedIndex * itemsPerPage) % mappings.length;
        dispatch(updateItemOffset({ itemOffset: newOffset }));
      }
    }
  }, [selectedIndex, mappings]);

  useEffect(() => {
    if (searchFilteredArray.length > 0 && mappingsFor === "products") {
      dispatch(updateSelectedIndex({ selectedIndex: 0 }));
    }
  }, [searchFilteredArray]);

  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={(event) =>
          dispatch(updateSelectedIndex({ selectedIndex: event.selected }))
        }
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName="pagination-container"
        pageLinkClassName="page-num"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        activeLinkClassName="active"
        disabledLinkClassName="disabled"
        marginPagesDisplayed={3}
        forcePage={selectedIndex}
      />
    </div>
  );
};

export default Pagination;
