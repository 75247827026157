import React from "react";
import { MappingWindow } from "generic";
import { useSelector } from "react-redux";

import { PdSideBar } from "pipedrive-frontend";
import { CmsRichText } from "cms";

import { IPdInitialState } from "pipedrive-frontend/types/types";
import { IMappingSlice } from "generic/types/mappingTypes";
import { IMsbcStates } from "msbc-frontend/types/msbcTypes";

const ProductFieldMappings = () => {
  const {
    mapping: {
      defaultMappings: { productFields },
    },
  } = useSelector((state: { mappings: IMappingSlice }) => state.mappings);

  const {
    fields: {
      data: { productFields: productFieldMappings },
    },
    pipedriveUserIds,
  } = useSelector((state: { pipedrive: IPdInitialState }) => state.pipedrive);

  const {
    fields: {
      data: { productFieldMappings: msbcProductFields },
    },
  } = useSelector((state: { msbc: IMsbcStates }) => state.msbc);

  const triggerAppObject = {
    products: productFieldMappings,
  };

  const actionAppObject = {
    products: msbcProductFields,
  };

  const labels: { [k: string]: string } = {
    actionApp: "MS business central Fields",
    triggerApp: "Pipedrive Fields",
  };

  return (
    <div>
      <div className="my-4">
        <CmsRichText
          path="cmsContent.settings.products.fieldMappings"
          cssName="pipedrive"
        />
      </div>
      {productFields &&
        productFieldMappings &&
        productFieldMappings.length > 0 &&
        msbcProductFields &&
        msbcProductFields.length > 0 && (
          <MappingWindow
            mappings={productFields}
            primaryTriggerAppObjectKey="products"
            triggerAppObject={triggerAppObject}
            actionAppObject={actionAppObject}
            mappingsFor="productFields"
            labels={labels}
            cssClass="mapping-window"
            removeBorderThickness={true}
            isBordered={true}
            SideBar={PdSideBar}
            userIds={pipedriveUserIds}
          />
        )}
    </div>
  );
};

export default ProductFieldMappings;
