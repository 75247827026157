import React, { useEffect, useState } from "react";
import {
  Row,
  Button,
  Table,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Accordion,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

// Components
import MappingHeader from "./MappingHeader";
import RenderMappings from "./RenderMappings";
import NoSearchResultsFound from "./NoSearchResultsFound";
import Pagination from "./Pagination";

// Actions
import {
  checkUnMappedTriggerFields,
  validateTriggerFields,
  onSearchMappings,
  storeActiveAccordion,
} from "../slice/mappingSlice";

// Icons
import { GoSearch } from "react-icons/go";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";

// Types
import { IMappingItem, IField, IMappingSlice } from "../types/mappingTypes";

// Functions
import { onSaveMappings } from "../helpers/functions";
import { IPreferenceStates } from "../types/preferencetypes";

interface IProps {
  mappings: IMappingItem[];
  primaryTriggerAppObjectKey: string;
  triggerAppObject: { [k: string]: IField[] };
  actionAppObject: { [k: string]: IField[] };
  mappingsFor: string;
  labels: {
    actionApp: string;
    triggerApp: string;
  };
  cssClass: string;
  userIds: {
    [k: string]: number | string;
  };
  removeBorderThickness: boolean;
  isBordered?: boolean;
  SideBar?: React.ComponentType<{
    triggerAppObjectKey: string;
  }>;
  isAccordion?: boolean;
  isFiltered?: boolean;
  hiddenFields?: string[];
}

const ten = 10;

const MappingWindow = (props: IProps) => {
  const {
    mappings,
    primaryTriggerAppObjectKey,
    triggerAppObject,
    actionAppObject,
    mappingsFor,
    labels,
    cssClass,
    userIds,
    removeBorderThickness = false,
    isBordered = false,
    SideBar,
    isAccordion = false,
    isFiltered,
    hiddenFields,
  } = props;

  const {
    slicedMappings,
    searchFilteredArray,
    currentlyEditing,
    searchInput,
    selectedPreference,
    isEditMapping,
    deletedTriggerFields,
    activeAccordionTitle,
  } = useSelector((state: { mappings: IMappingSlice }) => state.mappings);

  const { contactActiveTab, invoiceActiveTab, productActiveTab } = useSelector(
    (state: { preference: IPreferenceStates }) => state.preference
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const fieldKeys = Object.keys(triggerAppObject);

  const primaryTriggerAppFields = triggerAppObject[primaryTriggerAppObjectKey];
  const actionAppFields = Object.entries(actionAppObject)[0][1];

  useEffect(() => {
    if (
      mappings &&
      triggerAppObject &&
      Object.keys(triggerAppObject).length > 0
    ) {
      dispatch(
        validateTriggerFields({ mappingsFor, triggerAppObject, isAccordion })
      );
    }
  }, [
    mappings,
    primaryTriggerAppFields,
    contactActiveTab,
    invoiceActiveTab,
    productActiveTab,
  ]);

  useEffect(() => {
    if (
      mappings &&
      actionAppObject &&
      Object.keys(actionAppObject).length > 0
    ) {
      dispatch(
        checkUnMappedTriggerFields({
          mappingsFor,
          actionAppObject,
          fieldKeys,
          isAccordion,
        })
      );
    }
  }, [mappings, actionAppFields, mappingsFor, fieldKeys]);

  const sendMappings = () => {
    if (mappingsFor === "products" && mappings.length > ten) {
      return slicedMappings;
    } else {
      return isAccordion
        ? mappings[activeAccordionTitle[mappingsFor]]
        : mappings;
    }
  };

  // Component for rendering each mapped row
  const mappedList =
    sendMappings()?.map((mappedItem: IMappingItem) => {
      if (
        searchFilteredArray.includes(mappedItem.id) ||
        searchInput.length === 0 ||
        !searchInput
      )
        return RenderMappings({
          mappedItem,
          mappings: mappingsFor === "products" ? slicedMappings : mappings,
          mappingsFor,
          currentlyEditing,
          searchInput,
          selectedPreference,
          isEditMapping,
          deletedTriggerFields,
          triggerAppObject,
          fieldKeys,
          isAccordion,
          removeBorderThickness,
          isFiltered,
          hiddenFields,
        });
      return null;
    }) ?? [];

  return (
    <div className={`${cssClass}`}>
      {/* Search */}
      <Row className="d-flex flex-row justify-content-between my-3 search-header">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-row search-container me-3">
            <input
              type="search"
              placeholder="Search"
              className="me-3 search-input"
              value={searchInput}
              onChange={(event) =>
                dispatch(
                  onSearchMappings({
                    mappingsFor,
                    searchString: event.target.value,
                    isAccordion,
                  })
                )
              }
            />
            <span className="my-auto">
              <GoSearch size={15} className="me-3 search-icon" />
            </span>
          </div>
          {mappingsFor !== "products" && (
            <div>
              <SideBar triggerAppObjectKey={primaryTriggerAppObjectKey} />
            </div>
          )}
        </div>
      </Row>

      {isAccordion ? (
        <>
          {(activeAccordionTitle[mappingsFor] ||
            activeAccordionTitle[mappingsFor] === null) && (
            <Accordion
              defaultActiveKey={activeAccordionTitle[mappingsFor]}
              onSelect={(eventKey) =>
                dispatch(
                  storeActiveAccordion({
                    mappingsFor,
                    activeAccordion: eventKey,
                  })
                )
              }
            >
              {mappings &&
                Object.keys(mappings).map((accTitle, index) => {
                  return (
                    <Accordion.Item
                      eventKey={accTitle}
                      key={index.toString()}
                      className="mt-3"
                    >
                      <Accordion.Header>
                        {activeAccordionTitle[mappingsFor] === accTitle ? (
                          <FaCaretDown className="me-1" size={12} />
                        ) : (
                          <FaCaretRight className="me-1" size={12} />
                        )}
                        <span className="accordion-title">{accTitle}</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        {searchInput.length > 0 &&
                        searchFilteredArray.length === 0 ? (
                          <NoSearchResultsFound />
                        ) : (
                          <Table
                            responsive
                            className="table"
                            bordered={isBordered}
                          >
                            {/* Table Header */}
                            <MappingHeader labels={labels} />

                            {/* Table Body */}
                            <tbody className="table-body">{mappedList}</tbody>
                          </Table>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>
          )}
        </>
      ) : (
        <>
          {searchInput.length > 0 && searchFilteredArray.length === 0 ? (
            <NoSearchResultsFound />
          ) : (
            <Table responsive className="table" bordered={isBordered}>
              {/* Table Header */}
              <MappingHeader labels={labels} />

              {/* Table Body */}
              <tbody className="table-body">{mappedList}</tbody>
            </Table>
          )}
        </>
      )}

      {/* Pagination */}
      {mappingsFor === "products" && mappings.length > ten && (
        <Pagination mappingsFor={mappingsFor} mappings={mappings} />
      )}

      <div
        className={`d-flex justify-content-end mb-4 ${isAccordion && "mt-3"}`}
      >
        {fieldKeys.some(
          (key) =>
            deletedTriggerFields?.[`${key}_${mappingsFor}`] &&
            deletedTriggerFields[`${key}_${mappingsFor}`].length > 0
        ) ? (
          <OverlayTrigger
            overlay={
              <Tooltip style={{ fontSize: "12px" }}>
                Please remap the deleted trigger fields.
              </Tooltip>
            }
          >
            <Button className="save-mapping-disabled">Save Mappings</Button>
          </OverlayTrigger>
        ) : (
          <Button
            className="save-mapping"
            disabled={
              isEditMapping ||
              fieldKeys.some(
                (key) =>
                  deletedTriggerFields?.[`${key}_${mappingsFor}`] &&
                  deletedTriggerFields[`${key}_${mappingsFor}`].length > 0
              )
            }
            onClick={() =>
              onSaveMappings({
                dispatch,
                setIsLoading,
                mappings,
                mappingsFor,
                userIds,
                isAccordion,
              })
            }
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Save Mappings</span>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default MappingWindow;
