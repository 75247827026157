import { createSlice } from "@reduxjs/toolkit";

import { fetchStatus } from "./mappingSlice";

import { ISubscriptionSlice } from "../types/subscriptionTypes";

const initialState: ISubscriptionSlice = {
  plansList: ["premium", "premium-cancelled", "basic", "basic-cancelled"],
  subscription: {
    loading: false,
    planData: {},
    error: "",
  },
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    updatePlanName(state, { payload }) {
      state.subscription.planData = {
        ...state.subscription.planData,
        plan: payload.plan,
        usageCount: 0,
        customerUsageCount: 0,
        customerKey: payload.customerKey,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatus.fulfilled, (state, actions) => {
      const { subscription } = actions.payload;
      if (
        subscription &&
        typeof subscription === "object" &&
        Object.keys(subscription).length > 0
      ) {
        state.subscription.planData = {
          ...subscription,
          usageCount: subscription.usageCount
            ? parseInt(subscription.usageCount)
            : 0,
          customerUsageCount: subscription.customerUsageCount
            ? parseInt(subscription.customerUsageCount)
            : 0,
        };
      }
    });
  },
});

export const { updatePlanName } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
