import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button, Spinner } from "react-bootstrap";
import { FaExternalLinkAlt } from "react-icons/fa";
import axios from "axios";

import { setShowErrorModal } from "generic";
import { getStripeUrl } from "../helpers/url";

import Loading from "./Loading";

import { ISubscriptionSlice } from "generic/types/subscriptionTypes";

const ManagePlan = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);

  const {
    subscription: { loading, planData },
  } = useSelector(
    (state: { subscription: ISubscriptionSlice }) => state.subscription
  );

  const companyId = searchParams.get("companyId");
  const userId = searchParams.get("userId");

  /**
   * This function opens the stripe customer portal
   */
  const openStripeCustomerPortal = async () => {
    try {
      setBtnLoading(true);
      const { data: response } = await axios.post(
        getStripeUrl("REACT_APP_STRIPE_BILLING_PORTAL"),
        {
          customerKey: planData.customerKey,
          returnUrl: `${window.location.origin}/setup?companyId=${companyId}&userId=${userId}&paymentStatus=none`,
        }
      );
      if (response.url) {
        window.open(response.url, "_blank");
      }
    } catch (error) {
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      dispatch(setShowErrorModal({ message: message }));
    } finally {
      setBtnLoading(false);
    }
  };

  /**
   * This function get the plan name
   */
  const getPlanName = () => {
    if (planData.plan.includes("cancelled")) {
      const planName = planData.plan.replace("-cancelled", "");
      return (
        <span>
          <span className="current-plan-heading">
            {planName[0].toUpperCase() + planName.substring(1)}
          </span>{" "}
          - Plan cancelled upgrade your plan.
        </span>
      );
    } else {
      return planData.plan[0].toUpperCase() + planData.plan.substring(1);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Card className="my-4">
          <Card.Body className="d-flex flex-row justify-content-between align-items-center">
            {planData && Object.keys(planData).length > 0 && (
              <div>
                <p>
                  Current Plan: <b>{planData?.plan && getPlanName()}</b>
                </p>
                <div className="d-flex mt-3">
                  {planData.plan !== "basic" && (
                    <>
                      <p className="me-3">
                        Invoice sync usage count:{" "}
                        <span>{planData.usageCount}</span>
                      </p>
                      {planData.plan === "trial" && (
                        <p>
                          Customer sync usage count:{" "}
                          <span>{planData.customerUsageCount}</span>
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}

            <div>
              <Button className="save-changes-button me-3">
                <a
                  href={`${window.location.origin}/subscription?companyId=${companyId}&userId=${userId}&paymentStatus=none`}
                  target="_blank"
                  style={{ textDecoration: "none", color: "#fff" }}
                  rel="noreferrer"
                >
                  Manage plan
                </a>
              </Button>
              {planData.customerKey && (
                <Button
                  className="manage-billing-button"
                  onClick={() => openStripeCustomerPortal()}
                >
                  {btnLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span>
                      View billing details{" "}
                      <FaExternalLinkAlt
                        style={{ marginTop: "-3px" }}
                        className="ms-1"
                      />
                    </span>
                  )}
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ManagePlan;
