import React from "react";
import { Navbar, Container } from "react-bootstrap";

export interface IPdNavbarProps {
  altText?: string;
  logo: string;
  text: string;
}

const PdNavbar = (props: IPdNavbarProps) => {
  return (
    <Navbar className="navbar-bg">
      <Container className="py-3">
        <Navbar.Brand className="d-flex flex-row align-items-center">
          <img
            alt={props.altText}
            src={props.logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          {props.text && <p className="app-name ms-2 mb-0">{props.text}</p>}
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default PdNavbar;
