import { createSlice } from "@reduxjs/toolkit";

import { IProductStates } from "../types/productTypes";

import { fetchStatus } from "./mappingSlice";

const initialState: IProductStates = {
  preferenceForProducts: "do not generate invoice",
  productPreference: undefined,
  preferenceForSearchParameters: "select search parameters",
  triggerAppProductFilterPreference: undefined,
  actionAppProductFilterPreference: undefined,
  isProductSyncRulesSaved: false,
  productGroupPreference: undefined,
  preferenceForCreatingProduct: false,
  defaultProductForCreating: undefined,
  dealSyncProductPreference: undefined,
  dealsSyncCheckbox: false,
  generalProductPostingGroupPreference: undefined,
  productTypePreference: undefined,
  isOverAllDiscountPresent: false,
  overAllDiscountProductPreference: undefined,
  advancedSettingStatus: false,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    onChangePreferenceForSearchParameters(state, { payload }) {
      state.preferenceForSearchParameters = payload.selected;
      state.isProductSyncRulesSaved = false;
    },
    onChangeDealsSyncCheckbox(state) {
      state.dealsSyncCheckbox = !state.dealsSyncCheckbox;
      state.isProductSyncRulesSaved = false;
    },
    onChangeIsOverAllDiscountPresent(state) {
      state.isOverAllDiscountPresent = !state.isOverAllDiscountPresent;
      state.isProductSyncRulesSaved = false;
    },
    onChangePreferenceForProducts(state, { payload }) {
      state.preferenceForProducts = payload.selected;
      if (state.preferenceForProducts !== "find create product") {
        state.preferenceForCreatingProduct = false;
        state.defaultProductForCreating = undefined;
      }
      if (state.preferenceForProducts !== "select product") {
        state.productPreference = undefined;
      }
      state.isProductSyncRulesSaved = false;
    },

    onChangePreferenceForCreatingProduct(state) {
      state.preferenceForCreatingProduct = !state.preferenceForCreatingProduct;
      if (state.preferenceForCreatingProduct === false) {
        state.defaultProductForCreating = undefined;
      }
      state.isProductSyncRulesSaved = false;
    },
    onChangeProductPreferences(state, { payload }) {
      if (payload.selectingFor === "product groups") {
        state.productGroupPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
      } else if (payload.selectingFor === "general product posting group") {
        state.generalProductPostingGroupPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
      } else if (payload.selectingFor === "product type") {
        state.productTypePreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
      } else if (payload.selectingFor === "triggerAppProductFilterField") {
        state.triggerAppProductFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
      } else if (payload.selectingFor === "actionAppProductFilterField") {
        state.actionAppProductFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
      } else if (payload.selectingFor === "defaultProduct") {
        state.defaultProductForCreating = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
      } else if (payload.selectingFor === "products") {
        state.productPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
      } else if (payload.selectingFor === "dealSyncProduct") {
        state.dealSyncProductPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
      } else if (payload.selectingFor === "overAllDiscountProduct") {
        state.overAllDiscountProductPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
      }
      state.isProductSyncRulesSaved = false;
    },
    setIsProductSyncRulesSaved(state) {
      state.isProductSyncRulesSaved = true;
    },
    onUpdateAdvancedStatus(state, { payload }) {
      state.advancedSettingStatus = payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatus.fulfilled, (state, actions) => {
      const { preferences } = actions.payload;
      const { productSync } = preferences;

      if (productSync?.generateWorkflow) {
        state.preferenceForProducts = productSync?.generateWorkflow.create
          ? "select product"
          : "do not generate invoice";
        state.productPreference = productSync?.generateWorkflow?.mapping
          ?.defaultProduct?.value
          ? productSync?.generateWorkflow?.mapping?.defaultProduct
          : undefined;
      }

      if (productSync?.createProduct) {
        if (productSync?.createProduct.create === true) {
          state.preferenceForProducts = "find create product";
        } else if (productSync?.generateWorkflow.create) {
          state.preferenceForProducts = "select product";
        } else {
          state.preferenceForProducts = "do not generate invoice";
        }

        state.preferenceForCreatingProduct =
          productSync?.createProduct?.customProduct?.useDefaultProduct;
        state.defaultProductForCreating = productSync?.createProduct
          ?.customProduct?.defaultProduct?.value
          ? productSync?.createProduct?.customProduct?.defaultProduct
          : undefined;
      }

      if (productSync?.defaultFields) {
        const { productGroups, generalProductPostingGroup, productTypes } =
          productSync.defaultFields;
        state.productGroupPreference =
          productGroups && productGroups?.value
            ? {
                name: productGroups.name,
                value: productGroups.value,
              }
            : undefined;

        state.generalProductPostingGroupPreference =
          generalProductPostingGroup && generalProductPostingGroup?.value
            ? {
                name: generalProductPostingGroup.name,
                value: generalProductPostingGroup.value,
              }
            : undefined;

        state.productTypePreference =
          productTypes && productTypes?.value
            ? {
                name: productTypes.name,
                value: productTypes.value,
              }
            : undefined;
      }

      if (productSync?.missingLineItems) {
        state.dealsSyncCheckbox = productSync?.missingLineItems?.create;
        state.dealSyncProductPreference = productSync?.missingLineItems?.mapping
          ?.defaultProduct?.value
          ? productSync?.missingLineItems?.mapping?.defaultProduct
          : undefined;
      }

      state.preferenceForSearchParameters =
        productSync?.searchParameter === true
          ? "select search parameters"
          : "set custom mappings";

      if (productSync?.searchFilterOptions) {
        state.actionAppProductFilterPreference = productSync.searchFilterOptions
          ?.filterProperty?.value
          ? productSync.searchFilterOptions?.filterProperty
          : undefined;
        state.triggerAppProductFilterPreference = productSync
          .searchFilterOptions?.triggerApp?.products?.filterProperty?.value
          ? productSync.searchFilterOptions?.triggerApp?.products
              ?.filterProperty
          : undefined;
      }

      if (productSync?.discountLineItem) {
        state.isOverAllDiscountPresent = productSync?.discountLineItem?.create;
        state.overAllDiscountProductPreference = productSync?.discountLineItem
          ?.mapping?.defaultProduct?.value
          ? productSync?.discountLineItem?.mapping?.defaultProduct
          : undefined;
      }

      if (
        (state.preferenceForProducts === "select product" &&
          state.productPreference) ||
        state.preferenceForProducts === "do not generate invoice" ||
        (state.preferenceForProducts === "find create product" &&
          (!state.preferenceForCreatingProduct ||
            (state.preferenceForCreatingProduct &&
              state.defaultProductForCreating))) ||
        (state.dealsSyncCheckbox && state.dealSyncProductPreference) ||
        (state.preferenceForSearchParameters === "select search parameters" &&
          state.triggerAppProductFilterPreference &&
          state.actionAppProductFilterPreference)
      ) {
        state.isProductSyncRulesSaved = true;
      }
    });
  },
});

export const {
  onChangePreferenceForProducts,
  onChangePreferenceForSearchParameters,
  onChangePreferenceForCreatingProduct,
  onChangeProductPreferences,
  setIsProductSyncRulesSaved,
  onChangeDealsSyncCheckbox,
  onChangeIsOverAllDiscountPresent,
  onUpdateAdvancedStatus,
} = productSlice.actions;

export default productSlice.reducer;
