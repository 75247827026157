import axios from "axios";

import { getUrlInMsbc } from "./url";

import { setIsContactDefaultMappingsSaved } from "../slice/msbcSlice";

import { setShowErrorModal, setShowSuccessModal } from "generic";

export const saveContactDefaultMappings = async (props) => {
  const {
    paymentTermsCodePreference,
    currencyCodePreference,
    dispatch,
    setIsLoading,
    userIds,
  } = props;
  try {
    setIsLoading(true);
    const data = {
      ...userIds,
      preferences: {
        defaultFields: {
          paymentTerms: paymentTermsCodePreference,
          currency: currencyCodePreference,
        },
      },
      preferenceType: "customerSync",
    };
    await axios.post(getUrlInMsbc("REACT_APP_SAVE_PREFERENCES"), data);
    dispatch(setShowSuccessModal({ message: "Saved successfully" }));
    dispatch(setIsContactDefaultMappingsSaved());
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};
