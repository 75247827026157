/**
 * This function will return the tooltip message for the install flow
 * @returns {string} - Tooltip message
 */
const installFlowTooltip = ({
  page,
  selectedPipelines,
  preferenceForProducts,
  productPreference,
  actionAppName,
  productGroupPreference,
  preferenceForCreatingProduct,
  defaultProductForCreating,
  preferenceForSearchParameters,
  checkProductMappingsCount,
  productMappings,
  dealsSyncCheckbox,
  dealSyncProductPreference,
  isOverAllDiscountPresent,
  overAllDiscountProductPreference,
  generalProductPostingGroupPreference,
  productTypePreference,
}) => {
  let tooltip = "";
  if (page === "invoiceSyncRules") {
    if (selectedPipelines.length === 0) {
      tooltip = "Please select at least one pipeline";
    }
  } else if (page === "productSyncRules") {
    if (preferenceForProducts === "select product" && !productPreference) {
      tooltip =
        "Please select the default product for using it as default product";
    } else if (
      preferenceForSearchParameters === "set custom mappings" &&
      checkProductMappingsCount({ productMappings }) === 0
    ) {
      tooltip = "Please select at least one product mapping";
    } else if (
      preferenceForCreatingProduct === true &&
      !defaultProductForCreating
    ) {
      tooltip = "Please select the default product for find/create product";
    } else if (
      actionAppName === "e-conomic" &&
      preferenceForProducts === "find create product" &&
      !productGroupPreference
    ) {
      tooltip = "Please select the product group";
    } else if (
      actionAppName === "Business Central" &&
      preferenceForProducts === "find create product" &&
      !generalProductPostingGroupPreference
    ) {
      tooltip = "Please select the general product posting group";
    } else if (
      actionAppName === "Business Central" &&
      preferenceForProducts === "find create product" &&
      !productTypePreference
    ) {
      tooltip = "Please select the product type";
    } else if (dealsSyncCheckbox && !dealSyncProductPreference) {
      tooltip = "Please select a product to sync the deal without products";
    } else if (isOverAllDiscountPresent && !overAllDiscountProductPreference) {
      tooltip = "Please select a product to handle the over all discount";
    }
  }

  return tooltip;
};

export default installFlowTooltip;
