import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddPipelines } from "pipedrive-frontend";
import { CmsRichText } from "cms";
import { fetchDealStages } from "generic";

import { IPdInitialState } from "pipedrive-frontend/types/types";
import { IInvoiceState } from "generic/types/invoiceTypes";

const InvoiceSyncRules = () => {
  const dispatch = useDispatch();
  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );

  const { pipelinePreference } = useSelector(
    (state: { invoice: IInvoiceState }) => state.invoice
  );

  useEffect(() => {
    if (
      pipedriveUserIds &&
      Object.keys(pipedriveUserIds).length > 0 &&
      pipelinePreference
    ) {
      const pipelineId = pipelinePreference.value;
      dispatch(fetchDealStages({ pipelineId, userIds: pipedriveUserIds }));
    }
  }, [pipelinePreference]);

  return (
    <div>
      <CmsRichText
        path="cmsContent.install.invoiceSyncRules.pipeline"
        cssName="pipedrive"
      />
      <div className="mb-5 mt-4">
        <AddPipelines />
      </div>
    </div>
  );
};

export default InvoiceSyncRules;
