import React from "react";
import {
  Card,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { MdDelete } from "react-icons/md";
import { SetupDropdown } from "./SetupDropdown";

import {
  onCheckAddPDF,
  onDeletePipeline,
  onAddPipeline,
  onDeleteSelectedPipelinePreferences,
  setSelectedInvoiceFields,
} from "../slice/invoiceSlice";

import { IInvoiceState, IPipelinesStructure } from "../types/invoiceTypes";

type InvoiceSyncProps = {
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
  cardCssName: string;
  dropdownCssName: string;
  triggerAppName: string;
  dropdownForInvoice?: IPipelinesStructure[];
  dropdownForInvoiceLabel?: string;
  pdfLabel?: string;
};

const InvoiceSync = (props: InvoiceSyncProps) => {
  const {
    CmsRichText,
    cardCssName,
    dropdownCssName,
    triggerAppName,
    dropdownForInvoice,
    dropdownForInvoiceLabel,
    pdfLabel,
  } = props;
  const {
    pipelines: { pipelines },
    dealStage: { dealStages },
    invoiceDropdown,
    pipelinePreference,
    dealStagePreference,
    invoicePreference,
    selectedPipelines,
    attachPDFToInvoice,
  } = useSelector((state: { invoice: IInvoiceState }) => state.invoice);

  const dispatch = useDispatch();
  const cmsCssName = triggerAppName.toLowerCase();

  return (
    <>
      <Card className={`px-0 ${cardCssName}`}>
        <Card.Body className="sync-settings-card-body">
          <div>
            <CmsRichText
              path="cmsContent.settings.invoices.syncRules.block1"
              cssName={cmsCssName}
            />
          </div>
          {selectedPipelines.length > 0 && (
            <div className="mt-3">
              <label className="my-1">Selected Pipelines</label>
              <div>
                {selectedPipelines.map((item, index) => (
                  <div
                    key={index}
                    className="settings-selected-pipeline-container my-2 p-3 d-flex flex-row justify-content-between"
                  >
                    <div className="d-flex flex-row flex-wrap">
                      <p className="me-3 mb-0">
                        <b>Pipeline: </b>
                        <span>{item.pipeline.name}</span>
                      </p>
                      <p className="me-3 mb-0">
                        <b>Deal Stage: </b>
                        <span>{item.dealStage.name}</span>
                      </p>
                      <p className="mb-0">
                        <b>Generate: </b>
                        <span>{item.generate.name}</span>
                      </p>
                    </div>
                    <button
                      className="pipeline-delete-button"
                      onClick={() => dispatch(onDeletePipeline({ index }))}
                    >
                      <MdDelete size={18} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="d-flex flex-row flex-wrap mt-3">
            {pipelines && (
              <Col sm={5} lg={3} className="me-4 generic-pipeline">
                <label className="label-text">Select a pipeline</label>
                {pipelines && (
                  <SetupDropdown
                    fieldItems={pipelines}
                    onChangeValue={setSelectedInvoiceFields}
                    selectedValue={pipelinePreference}
                    cssName={dropdownCssName}
                    dropdownFor="pipelines"
                    dropdownLabel="Pipeline"
                  />
                )}
              </Col>
            )}
            {dealStages && pipelinePreference && (
              <Col sm={5} lg={3} className="me-4 generic-deal-stage">
                <label className="label-text">Deal stage</label>
                <SetupDropdown
                  fieldItems={dealStages}
                  onChangeValue={setSelectedInvoiceFields}
                  selectedValue={dealStagePreference}
                  cssName={dropdownCssName}
                  dropdownFor="dealStage"
                  dropdownLabel="Deal stage"
                />
              </Col>
            )}
            {dealStagePreference && invoiceDropdown && !dropdownForInvoice && (
              <Col sm={5} lg={3} className="me-4 generic-generate">
                <label className="label-text">Invoice</label>
                <SetupDropdown
                  fieldItems={invoiceDropdown}
                  dropdownFor="preferenceForCreation"
                  dropdownLabel="Order/Invoice"
                  onChangeValue={setSelectedInvoiceFields}
                  selectedValue={invoicePreference}
                  cssName={dropdownCssName}
                />
              </Col>
            )}
            {dealStagePreference && dropdownForInvoice && (
              <Col sm={5} lg={3} className="me-4 generic-generate">
                <label className="label-text">Invoice</label>
                <SetupDropdown
                  fieldItems={dropdownForInvoice}
                  dropdownFor="preferenceForCreation"
                  dropdownLabel={dropdownForInvoiceLabel}
                  onChangeValue={setSelectedInvoiceFields}
                  selectedValue={invoicePreference}
                  cssName={dropdownCssName}
                />
              </Col>
            )}
          </div>
          <div className="d-flex flex-row">
            {!pipelinePreference ||
            !dealStagePreference ||
            !invoicePreference ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>
                    Please select the pipeline, dealStage and preference for
                    creation of Order/Invoice
                  </Tooltip>
                }
              >
                <Button className="save-changes-disabled-button mt-2">
                  Add Pipeline
                </Button>
              </OverlayTrigger>
            ) : (
              <Button
                className="save-changes-button mt-2"
                onClick={() => dispatch(onAddPipeline())}
              >
                Add Pipeline
              </Button>
            )}
            {pipelinePreference && (
              <Button
                className="clear-data-btn mt-2 ms-3"
                onClick={() => dispatch(onDeleteSelectedPipelinePreferences())}
              >
                Clear data
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      <Card className={cardCssName}>
        <Card.Body className="sync-settings-card-body attach-pdf-section">
          <CmsRichText
            path="cmsContent.settings.invoices.syncRules.block2"
            cssName={cmsCssName}
          />
          <div className="d-flex flex-row justify-content-between mt-3">
            <CmsRichText
              path="cmsContent.settings.invoices.syncRules.pdfLabel"
              cssName={cmsCssName}
            />
            <b>
              <Form.Check
                type="checkbox"
                id="addPDF"
                label={pdfLabel ? pdfLabel : "Attach a pdf of invoice in deal"}
                checked={attachPDFToInvoice}
                onChange={() => dispatch(onCheckAddPDF())}
              />
            </b>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default InvoiceSync;
