import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosError } from "axios";
import { getUrl } from "../helpers/url";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  onChangeCurrentWindow,
  updatePipedriveUserIds,
  Connection,
  PdButton,
  ConnectedAccount,
  Loading,
} from "pipedrive-frontend";
import {
  connectApp,
  setShowErrorModal,
  setShowSuccessModal,
  fetchStatus,
} from "generic";
import { setIsEnvPreferencesSaved } from "msbc-frontend";
import { CmsRichText } from "cms";

import { ICmsData } from "cms/types/cmsTypes";
import { IPreferenceStates } from "generic/types/preferencetypes";
import { IPdInitialState } from "pipedrive-frontend/types/types";
import { IMsbcStates } from "msbc-frontend/types/msbcTypes";

import EnvironmentSelection from "./EnvironmentSelection";

import msbcLogo from "../assets/images/logo-business-central.svg";

const Connect = () => {
  const [connectLoading, setConnectLoading] = useState(false);
  const [disConnectLoading, setDisConnectLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const path = window.location.pathname;

  const accordionFor = {
    customerSync: true,
    invoiceSync: true,
    products: false,
    productFields: false,
  };

  const actionAppKeys = ["customer", "invoice"];

  const {
    cmsData: { loading: cmsLoading },
  } = useSelector((state: { cms: ICmsData }) => state.cms);

  const { isAppConnected, connectedMail } = useSelector(
    (state: { preference: IPreferenceStates }) => state.preference
  );

  const {
    companyPreference,
    environmentPreference,
    tenantId,
    isEnvPreferencesSaved,
    fields: { data: msbcFields },
  } = useSelector((state: { msbc: IMsbcStates }) => state.msbc);

  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );

  const changeCurrentWindow = () => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    navigate(
      `../${window.location.pathname}?companyId=${companyId}&userId=${userId}&page=environmentPage`
    );
    dispatch(onChangeCurrentWindow({ currentWindow: "Environment Window" }));
  };

  const connectMsbcAccount = () => {
    try {
      setConnectLoading(true);
      window.open(
        `${getUrl("REACT_APP_INSTALL_MSBC")}?companyId=${
          pipedriveUserIds.companyId
        }&userId=${pipedriveUserIds.userId}&path=${
          window.location.pathname.split("/")[1]
        }`,
        "_self"
      );
      dispatch(setIsEnvPreferencesSaved({ isSaved: false }));
    } catch (error) {
      console.error(error);
      let errorMessage;
      if (error instanceof Error) errorMessage = error.message;
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data.message
      )
        errorMessage = error.response.data.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
    } finally {
      setConnectLoading(false);
    }
  };

  const connectMsbc = () => {
    return (
      <div>
        <CmsRichText
          path="cmsContent.authenticationPage.title"
          cssName="pipedrive"
        />
        <img src={msbcLogo} alt="msbc logo" className="mt-4 mb-5" />
        {isAppConnected && (
          <ConnectedAccount
            buttonText="Unlink"
            connectedAccount={connectedMail}
            disConnectAccount={disconnectAccount}
            disConnectBtnLoading={disConnectLoading}
          />
        )}
        {!isAppConnected ? (
          <>
            <CmsRichText
              path="cmsContent.authenticationPage.disconnected"
              cssName="pipedrive"
            />
          </>
        ) : (
          <CmsRichText
            path="cmsContent.authenticationPage.connected"
            cssName="pipedrive"
          />
        )}
        {path === "/setup" && (
          <div className="my-4">
            <PdButton
              text={`${
                isAppConnected ? "Continue" : "Connect MS Business Central"
              }`}
              click={isAppConnected ? changeCurrentWindow : connectMsbcAccount}
              className="connect-button"
            />
          </div>
        )}
        {path === "/settings" && !isAppConnected && (
          <div className="my-4">
            <PdButton
              text="Connect MS Business Central"
              click={connectMsbcAccount}
            />
          </div>
        )}
        <p className="mt-3 mb-4">
          Need help? Check the{" "}
          <a href="/setupguide" target="_blank" rel="noreferrer">
            setup guide
          </a>
        </p>
      </div>
    );
  };

  const disconnectAccount = async () => {
    try {
      setDisConnectLoading(true);
      await axios.post(getUrl("REACT_APP_DISCONNECT_MSBC"), {
        companyId: pipedriveUserIds?.companyId,
        userId: pipedriveUserIds?.userId,
      });
      dispatch(fetchStatus({ userIds: pipedriveUserIds }));
      dispatch(connectApp());
      dispatch(
        setShowSuccessModal({
          message:
            "cmsContent.notifications.authenticationPage.successContent.disconnectAcc.message",
        })
      );
    } catch (error) {
      console.error(error);
      let errorMessage;
      if (error instanceof Error) errorMessage = error.message;
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data.message
      )
        errorMessage = error.response.data.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
    } finally {
      setDisConnectLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const status = searchParams.get("status");

    if (status && status === "successfull") {
      dispatch(connectApp());
      dispatch(setIsEnvPreferencesSaved({ isSaved: false }));
      dispatch(
        setShowSuccessModal({
          message:
            "cmsContent.notifications.authenticationPage.successContent.connectAcc.message",
        })
      );

      if (isEnvPreferencesSaved === false) {
        dispatch(
          fetchStatus({
            userIds: pipedriveUserIds,
            accordionFor,
            fields: msbcFields,
            fieldKeys: actionAppKeys,
          })
        );
      }

      // Remove 'status' parameter from the URL
      searchParams.delete("status");
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
      if (path === "/setup") {
        window.location.reload();
      }
    }
  }, []);

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    if (companyId && path === "/settings") {
      dispatch(updatePipedriveUserIds({ companyId, userId }));
    }
  }, []);

  useEffect(() => {
    if (pipedriveUserIds && Object.keys(pipedriveUserIds).length > 0) {
      const tenant = searchParams.get("tenantId");

      if (path === "/settings" && tenant) {
        dispatch(
          fetchStatus({
            userIds: pipedriveUserIds,
            isSaved: true,
            accordionFor,
            fields: msbcFields,
            fieldKeys: actionAppKeys,
          })
        );
      } else {
        dispatch(
          fetchStatus({
            userIds: pipedriveUserIds,
            accordionFor,
            fields: msbcFields,
            fieldKeys: actionAppKeys,
          })
        );
      }
    }
  }, [pipedriveUserIds, msbcFields]);

  return (
    <Container>
      {cmsLoading || connectLoading ? (
        <Loading />
      ) : (
        <>
          <div className={`${path === "/settings" ? "my-3" : ""}`}>
            {path === "/settings" &&
            isAppConnected &&
            (!companyPreference ||
              !environmentPreference ||
              !tenantId ||
              !isEnvPreferencesSaved) ? (
              <EnvironmentSelection />
            ) : (
              <Connection
                actionAppConnection={connectMsbc}
                CmsRichText={CmsRichText}
                isIframe={path === "/settings" ? true : false}
              />
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default Connect;
