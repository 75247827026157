import React from "react";
import { Card, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { SetupDropdown } from "./SetupDropdown";

import {
  onChangeProductPreferences,
  onChangeIsOverAllDiscountPresent,
} from "../slice/productSlice";

import { IProductStates } from "../types/productTypes";
import { IMappingStructure } from "../types/mappingTypes";

type ProductOverallDiscountProps = {
  actionAppName: string;
  triggerAppName: string;
  actionAppProducts: IMappingStructure[];
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
  cardCssName: string;
  dropdownCssName?: string;
  dropdownLabel?: string;
};

const ProductOverallDiscount = (props: ProductOverallDiscountProps) => {
  const {
    actionAppName,
    triggerAppName,
    actionAppProducts,
    CmsRichText,
    cardCssName,
    dropdownLabel,
  } = props;

  const dispatch = useDispatch();
  const { isOverAllDiscountPresent, overAllDiscountProductPreference } =
    useSelector((state: { products: IProductStates }) => state.products);

  const cmsCssName = triggerAppName.toLowerCase();

  return (
    <Card className={cardCssName}>
      <Card.Body className="sync-settings-card-body">
        <div className="products-overall-discount">
          <CmsRichText
            path="cmsContent.settings.products.syncRules.block4.text"
            cssName={cmsCssName}
          />
          <Form.Check
            type="checkbox"
            id="overAllDiscount"
            label={
              dropdownLabel
                ? `Select a default ${dropdownLabel} to add over all discount as a line item`
                : "Select a default product to add over all discount as a line item"
            }
            checked={isOverAllDiscountPresent}
            onChange={() => dispatch(onChangeIsOverAllDiscountPresent())}
          />
          {isOverAllDiscountPresent && (
            <div
              className="mt-2"
              style={{
                margin: "0 22px 0 22px",
              }}
            >
              <CmsRichText
                path="cmsContent.settings.products.syncRules.block4.dealSyncProduct"
                cssName={cmsCssName}
              />
              <Col sm={5} lg={3} className="mt-2">
                <label className="label-text">
                  {dropdownLabel ? `Select ${dropdownLabel}` : "Select product"}
                </label>
                {actionAppProducts && actionAppProducts.length > 0 ? (
                  <SetupDropdown
                    fieldItems={actionAppProducts}
                    dropdownFor="overAllDiscountProduct"
                    selectedValue={overAllDiscountProductPreference}
                    onChangeValue={onChangeProductPreferences}
                    cssName="settings-dropdown"
                    dropdownLabel={dropdownLabel}
                  />
                ) : (
                  <div
                    className="d-flex flex-row justify-content-center w-100"
                    style={{ border: "1px solid #d2dbe6" }}
                  >
                    <p className="my-3 p-3 text-center">
                      <b>{`No product present in ${actionAppName}`}</b>
                    </p>
                  </div>
                )}
              </Col>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProductOverallDiscount;
