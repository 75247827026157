import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas, Button, Spinner, Form } from "react-bootstrap";
import { BiCodeAlt } from "react-icons/bi";
import axios from "axios";

import { getUrlInPd } from "../helpers/url";
import { setShowErrorModal, setShowSuccessModal } from "generic";
import { fetchPipedriveFields } from "../slices/pipedriveSlice";

interface IProps {
  triggerAppObjectKey: string;
}

const PdSideBar = (props: IProps) => {
  const { triggerAppObjectKey } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = useSelector((state: any) => state);
  type AppDispatch = typeof state.dispatch;
  const dispatch = useDispatch<AppDispatch>();

  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [selectedType, setSelectedType] = useState("varchar");
  const [loading, setLoading] = useState(false);

  const handleShow = () => setShow(true);

  /**
   * Creates a new Pipedrive property
   */
  const onCreatePipedriveProperty = async () => {
    if (fieldName) {
      try {
        setLoading(true);
        // Getting params from the url
        const companyId = searchParams.get("companyId");
        const userId = searchParams.get("userId");
        const data = {
          userId: parseInt(userId),
          companyId: parseInt(companyId),
          fieldName,
          fieldType: selectedType,
          updateField: triggerAppObjectKey,
        };
        await axios.post(
          getUrlInPd("REACT_APP_CREATE_TRIGGER_APP_PROPERTY"),
          data
        );

        dispatch(fetchPipedriveFields({ userIds: { companyId, userId } }));
        dispatch(
          setShowSuccessModal({
            message:
              "cmsContent.notifications.mappingWindow.successContent.createProperty.message",
          })
        );
      } catch (error) {
        console.log(error);
        let errorMessage;
        if (error.response && error.response.data.details.error) {
          errorMessage = error.response.data.details.error;
        } else errorMessage = error.message;
        dispatch(setShowErrorModal({ message: errorMessage }));
      }
      setLoading(false);
      setFieldName("");
      setSelectedType("varchar");
      setShow(false);
    }
  };

  return (
    <div>
      <button className="sidebar-button my-3 my-md-0 px-3" onClick={handleShow}>
        Create property
      </button>
      <Offcanvas
        show={show}
        placement="end"
        onHide={() => {
          setShow(false);
          setFieldName("");
        }}
        className="pd-sidebar"
      >
        <Offcanvas.Header className="sidebar-header" closeButton>
          <Offcanvas.Title className="sidebar-heading">
            Create property
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="sidebar-body">
          <div className="mt-4">
            <label className="form-label" htmlFor="customName">
              Name
            </label>
            <div className="d-flex flex-row">
              <input
                id="customName"
                type="text"
                className="form-control sidebar-input"
                value={fieldName}
                onChange={(event) => setFieldName(event.target.value)}
              />
              <span className="mx-2 my-auto">
                <BiCodeAlt size={30} className="code-icon" />
              </span>
            </div>
            <p className="hint-text mt-2">*The name of your Pipedrive field.</p>
          </div>
          <div className="mt-5">
            <p>Type</p>
            <Form.Select
              value={selectedType}
              onChange={(event) => setSelectedType(event.target.value)}
              className="selected-type"
            >
              <option value="varchar">Text</option>
              <option value="double">Number</option>
            </Form.Select>
          </div>
          <p className="hint-text mt-2">*The type of your Pipedrive fields</p>
        </Offcanvas.Body>
        <div className="sidebar-footer d-flex flex-column justify-content-center align-items-center">
          <div className="w-100 px-3 py-2 d-flex justify-content-between">
            <button
              onClick={() => {
                setShow(false);
                setFieldName("");
              }}
              className="sidebar-cancel-button me-3"
            >
              Cancel
            </button>
            <Button
              disabled={fieldName === "" || fieldName.startsWith(" ")}
              onClick={onCreatePipedriveProperty}
              className="sidebar-create-button"
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Create</span>
              )}
            </Button>
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};

export default PdSideBar;
