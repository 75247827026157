import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { driver } from "driver.js";

import { updateShowSettingsTour } from "../slice/preferenceSlice";

interface IProps {
  loading: boolean;
  steps: object[];
  showTour: boolean;
  storedKey: string;
}

const GuideTour = (props: IProps) => {
  const dispatch = useDispatch();
  const { loading, steps, showTour, storedKey } = props;

  const driverObj = driver({
    showProgress: true,
    disableActiveInteraction: true,
    steps: steps,
    nextBtnText: "Next",
    prevBtnText: "Previous",
    doneBtnText: "Done",
    onDestroyStarted: () => {
      if (
        !driverObj.hasNextStep() ||
        driverObj.destroy() ||
        showTour === true
      ) {
        driverObj.destroy();
      }
    },
    onDestroyed: () => {
      localStorage.setItem(storedKey, "true");
      dispatch(updateShowSettingsTour({ value: true }));
    },
  });

  const driverRef = useRef(driverObj);

  useEffect(() => {
    const isStored = localStorage.getItem(storedKey);

    if (loading && !showTour && (!isStored || isStored === "false")) {
      driverRef.current.drive();
    }
  }, [loading, steps, showTour, driverObj, storedKey]);

  return null;
};

export default GuideTour;
