import React from "react";
import { Card, Row, Col, Form } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { SetupDropdown } from "./SetupDropdown";
import {
  onChangeCheckboxForContact,
  onChangeContactPreferences,
} from "../slice/contactSlice";

import { IContactStates } from "../types/contactTypes";
import { IMappingStructure } from "../types/mappingTypes";

type CompanySearchProps = {
  triggerAppLabel: string;
  actionAppLabel: string;
  triggerAppCompanySearchFields: IMappingStructure[];
  triggerAppContactSearchFields?: IMappingStructure[];
  actionAppCustomerSearchFields?: IMappingStructure[];
  dropdownClassName: string;
  cardClassName: string;
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
  missingScope?: boolean;
};

const CompanySearch = (props: CompanySearchProps) => {
  const {
    triggerAppLabel,
    actionAppLabel,
    triggerAppCompanySearchFields,
    triggerAppContactSearchFields,
    actionAppCustomerSearchFields,
    dropdownClassName,
    cardClassName,
    CmsRichText,
    missingScope,
  } = props;

  const {
    triggerAppContactFilterPreference,
    triggerAppCompanyFilterPreference,
    actionAppCompanyFilterPreference,
    actionAppContactFilterPreference,
    isContactCheckbox,
  } = useSelector((state: { contact: IContactStates }) => state.contact);

  const dispatch = useDispatch();

  const cmsClassName = triggerAppLabel.toLowerCase();

  return (
    <Card className={cardClassName}>
      <Card.Body className="sync-settings-card-body">
        <CmsRichText
          path="cmsContent.settings.contacts.syncRules.block1.text"
          cssName={cmsClassName}
        />
        <div className="note-container my-4">
          <p>
            <b>Important note:</b>
          </p>
          <CmsRichText
            path="cmsContent.settings.contacts.syncRules.block1.note"
            cssName={cmsClassName}
          />
        </div>
        <div className="company-search-parameters">
          <div className="mb-3">
            <CmsRichText
              path="cmsContent.settings.contacts.syncRules.block1.company"
              cssName={cmsClassName}
            />
          </div>
          <Row>
            <Col sm={5} lg={3}>
              <label className="label-text">{triggerAppLabel}</label>
              {triggerAppCompanySearchFields && (
                <SetupDropdown
                  fieldItems={triggerAppCompanySearchFields}
                  onChangeValue={onChangeContactPreferences}
                  selectedValue={triggerAppCompanyFilterPreference}
                  dropdownFor="triggerAppCompanyFilterField"
                  dropdownLabel={triggerAppLabel}
                  cssName={dropdownClassName}
                />
              )}
            </Col>
            <Col sm={1} className="mx-0 sync-settings-card-direction">
              <div></div>
            </Col>
            <Col sm={5} lg={3}>
              <label className="label-text">{actionAppLabel}</label>
              {actionAppCustomerSearchFields && (
                <SetupDropdown
                  fieldItems={actionAppCustomerSearchFields}
                  onChangeValue={onChangeContactPreferences}
                  selectedValue={actionAppCompanyFilterPreference}
                  dropdownFor="actionAppCompanyFilterField"
                  dropdownLabel={actionAppLabel}
                  cssName={dropdownClassName}
                />
              )}
            </Col>
          </Row>
        </div>
        {!missingScope && (
          <>
            <div className="contact-search-parameters">
              <div className="my-3">
                <CmsRichText
                  path="cmsContent.settings.contacts.syncRules.block1.contact.heading"
                  cssName={cmsClassName}
                />
              </div>

              <div>
                <Form.Check
                  type="checkbox"
                  id="contact"
                  label="Enable Primary Contact Sync"
                  checked={isContactCheckbox}
                  onChange={() => dispatch(onChangeCheckboxForContact())}
                  className="mt-3"
                />
              </div>

              {isContactCheckbox && (
                <>
                  <div className="my-3">
                    <CmsRichText
                      path="cmsContent.settings.contacts.syncRules.block1.contact.description"
                      cssName={cmsClassName}
                    />
                  </div>
                  <Row className="mt-3">
                    <Col sm={5} lg={3}>
                      <label className="label-text">{triggerAppLabel}</label>
                      {triggerAppContactSearchFields && (
                        <SetupDropdown
                          fieldItems={triggerAppContactSearchFields}
                          onChangeValue={onChangeContactPreferences}
                          selectedValue={triggerAppContactFilterPreference}
                          dropdownFor="triggerAppContactFilterField"
                          dropdownLabel={triggerAppLabel}
                          cssName={dropdownClassName}
                        />
                      )}
                    </Col>
                    <Col
                      sm={1}
                      className="mx-0 sync-settings-card-direction"
                    ></Col>
                    <Col sm={5} lg={3}>
                      <label className="label-text">{actionAppLabel}</label>
                      {actionAppCustomerSearchFields && (
                        <SetupDropdown
                          fieldItems={actionAppCustomerSearchFields}
                          onChangeValue={onChangeContactPreferences}
                          selectedValue={actionAppContactFilterPreference}
                          dropdownFor="actionAppContactFilterField"
                          dropdownLabel={actionAppLabel}
                          cssName={dropdownClassName}
                        />
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default CompanySearch;
