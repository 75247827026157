import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { GoSearch } from "react-icons/go";

// Types
import { IMappingItem, IField, IMappingSlice } from "../types/mappingTypes";

// Actions
import { setSelectedPreference } from "../slice/mappingSlice";

interface IProps {
  mappedItem: IMappingItem;
  mappings: IMappingItem[];
  triggerAppObject: {
    [k: string]: IField[];
  };
  mappedItemKey: string;
  isAccordion?: boolean;
}

const MappingDropdown = (props: IProps) => {
  const { mappedItem, mappings, triggerAppObject, mappedItemKey, isAccordion } =
    props;
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState({});

  const { selectedPreference } = useSelector(
    (state: { mappings: IMappingSlice }) => state.mappings
  );

  /**
   * @description - Function searches the trigger app fields inside the dropdown
   */
  const onSearchFields = (enteredInput) => {
    setSearchInput(enteredInput);
    const searchArray = {};

    if (triggerAppObject && Object.keys(triggerAppObject).length > 0) {
      Object.keys(triggerAppObject).forEach((key) => {
        searchArray[key] = triggerAppObject[key].filter((fieldItem) =>
          fieldItem.HRF.toLowerCase().includes(enteredInput.toLowerCase())
        );
      });
    }
    setSearchResults(searchArray);
  };

  /**
   * This function is responsible for displaying text
   * @returns selected value title
   */
  const dropdownToggleText = () => {
    if (selectedPreference) {
      return selectedPreference.HRF;
    } else if (!selectedPreference && !mappedItemKey) {
      return "Select";
    } else return mappedItem?.triggerApp?.[mappedItemKey][0]?.HRF;
  };

  const disabledAccordionFields = (fieldName, key) => {
    return Object.keys(mappings).some((item) => {
      return mappings[item].some((mappedItem) => {
        return (
          mappedItem?.triggerApp?.[key] &&
          mappedItem?.triggerApp?.[key]
            ?.map((field) => field.CRF)
            .includes(fieldName)
        );
      });
    });
  };

  /**
   * @description This function is responsible for disabling the mapped fields inside the dropdown
   */
  const checkDisabledField = (fieldName, key) => {
    let mappedFields = [] as (string | number)[];
    if (!isAccordion)
      mappedFields = mappings.flatMap(
        (mapping) =>
          mapping?.triggerApp?.[key] &&
          mapping?.triggerApp?.[key]?.map((field) => field.CRF)
      );
    const isFound = isAccordion
      ? disabledAccordionFields(fieldName, key)
      : mappedFields.includes(fieldName);
    if (isFound) {
      return (
        mappedItem?.triggerApp?.[key] &&
        !mappedItem?.triggerApp?.[key]
          .map((field) => field.CRF)
          .includes(fieldName)
      );
    }

    return isFound;
  };

  return (
    <Dropdown className="mapping-dropdown">
      <Dropdown.Toggle className="toggle-dropdown">
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {dropdownToggleText()}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dropdown-menu"
        renderOnMount={true}
        popperConfig={{ strategy: "fixed" }}
      >
        <div className="p-3 search-container">
          <div className="d-flex flex-row search">
            <input
              type="search"
              placeholder="search.."
              className="w-100 search-input"
              onChange={(event) => onSearchFields(event.target.value)}
            />
            <span className="my-auto">
              <GoSearch size={15} className="me-3 search-icon" />
            </span>
          </div>
        </div>

        <div className="mb-3 dropdown-items-container">
          {triggerAppObject &&
            Object.keys(triggerAppObject).map((key) => (
              <>
                <Dropdown.ItemText>
                  <b style={{ textTransform: "capitalize" }}>{key}</b>
                  <hr className="my-0 py-0" />
                </Dropdown.ItemText>

                {searchInput.length > 0 &&
                  searchResults?.[key] &&
                  searchResults[key].length === 0 && (
                    <div className="d-flex flex-column justify-content-center align-items-center my-2">
                      <p style={{ fontSize: "14px", fontWeight: 600 }}>
                        No results found
                      </p>
                    </div>
                  )}

                {(searchInput.length > 0
                  ? searchResults[key]
                  : triggerAppObject[key]
                ).map((fieldItem) => (
                  <Dropdown.Item
                    disabled={checkDisabledField(fieldItem.CRF, key)}
                    key={`${fieldItem.CRF}-${key}`}
                    onClick={() =>
                      dispatch(
                        setSelectedPreference({
                          selectedOption: fieldItem,
                          selectedKey: key,
                        })
                      )
                    }
                  >
                    {fieldItem.HRF}
                  </Dropdown.Item>
                ))}
              </>
            ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MappingDropdown;
