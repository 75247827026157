import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { SetupDropdown } from "./SetupDropdown";
import { onChangeContactPreferences } from "../slice/contactSlice";

import { IContactStates, IPreference } from "../types/contactTypes";
import { IMappingStructure } from "../types/mappingTypes";

import { FaArrowRightLong } from "react-icons/fa6";

type CustomerNumberProps = {
  triggerAppName: string;
  actionAppName: string;
  dropdownClassName: string;
  cardClassName: string;
  triggerAppCompanyFields: IMappingStructure[];
  triggerAppContactFields: IMappingStructure[];
  actionAppCustomerFields: IMappingStructure[];
  actionAppCustomerNumberPreference: IPreference;
  actionAppConditionBasedPreference?: IPreference;
  isDisabled?: boolean;
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
};

const CustomerNumber = (props: CustomerNumberProps) => {
  const {
    triggerAppName,
    actionAppName,
    dropdownClassName,
    cardClassName,
    triggerAppCompanyFields,
    triggerAppContactFields,
    actionAppCustomerFields,
    actionAppCustomerNumberPreference,
    isDisabled,
    CmsRichText,
    actionAppConditionBasedPreference,
  } = props;

  const {
    customerNumberPreference,
    triggerAppContactNumberPreference,
    triggerAppCompanyNumberPreference,
    isContactCheckbox,
  } = useSelector((state: { contact: IContactStates }) => state.contact);
  const cmsCssName = triggerAppName.toLowerCase();
  return (
    <Card className={cardClassName}>
      <Card.Body
        className={`sync-settings-card-body ${
          customerNumberPreference.value === "action"
            ? "customer-number-selection-action"
            : "customer-number-selection-trigger"
        }`}
      >
        <CmsRichText
          path="cmsContent.settings.contacts.syncRules.block3.text"
          cssName={cmsCssName}
        />
        {customerNumberPreference.value === "action" && (
          <CmsRichText
            path="cmsContent.settings.contacts.syncRules.block3.company.createCustomer"
            cssName={cmsCssName}
          />
        )}
        {customerNumberPreference.value === "trigger" && (
          <CmsRichText
            path="cmsContent.settings.contacts.syncRules.block3.company.takeCustomer"
            cssName={cmsCssName}
          />
        )}
        <Row>
          <Col sm={5} lg={3}>
            <label className="label-text">{triggerAppName}</label>
            {triggerAppCompanyFields && (
              <SetupDropdown
                fieldItems={triggerAppCompanyFields}
                onChangeValue={onChangeContactPreferences}
                selectedValue={triggerAppCompanyNumberPreference}
                dropdownFor="triggerAppCompanyNumberField"
                dropdownLabel={triggerAppName}
                cssName={dropdownClassName}
              />
            )}
          </Col>
          <Col sm={1} className="mx-0 sync-settings-card-direction">
            <FaArrowRightLong
              style={{
                transform:
                  customerNumberPreference.value === "action"
                    ? "scaleX(-1)"
                    : "",
              }}
            />
          </Col>
          <Col sm={5} lg={3}>
            <label className="label-text">{actionAppName}</label>
            {actionAppCustomerFields && (
              <SetupDropdown
                isDisabled={isDisabled}
                fieldItems={actionAppCustomerFields}
                selectedValue={
                  actionAppConditionBasedPreference &&
                  customerNumberPreference.value === "action"
                    ? actionAppConditionBasedPreference
                    : actionAppCustomerNumberPreference
                }
                dropdownFor="actionAppCustomerNumberField"
                cssName={dropdownClassName}
              />
            )}
          </Col>
        </Row>
        {isContactCheckbox && (
          <div className="mt-3">
            {customerNumberPreference.value === "action" && (
              <CmsRichText
                path="cmsContent.settings.contacts.syncRules.block3.contact.createCustomer"
                cssName={cmsCssName}
              />
            )}
            {customerNumberPreference.value === "trigger" && (
              <CmsRichText
                path="cmsContent.settings.contacts.syncRules.block3.contact.takeCustomer"
                cssName={cmsCssName}
              />
            )}
            <Row>
              <Col sm={5} lg={3}>
                <label className="label-text">{triggerAppName}</label>
                {triggerAppContactFields && (
                  <SetupDropdown
                    fieldItems={triggerAppContactFields}
                    onChangeValue={onChangeContactPreferences}
                    selectedValue={triggerAppContactNumberPreference}
                    dropdownFor="triggerAppContactNumberField"
                    dropdownLabel={triggerAppName}
                    cssName={dropdownClassName}
                  />
                )}
              </Col>
              <Col sm={1} className="mx-0 sync-settings-card-direction">
                <FaArrowRightLong
                  style={{
                    transform:
                      customerNumberPreference.value === "action"
                        ? "scaleX(-1)"
                        : "",
                  }}
                />
              </Col>
              <Col sm={5} lg={3}>
                <label className="label-text">{actionAppName}</label>
                {actionAppCustomerFields && (
                  <SetupDropdown
                    isDisabled={isDisabled}
                    fieldItems={actionAppCustomerFields}
                    selectedValue={
                      actionAppConditionBasedPreference &&
                      customerNumberPreference.value === "action"
                        ? actionAppConditionBasedPreference
                        : actionAppCustomerNumberPreference
                    }
                    dropdownFor="actionAppCustomerNumberField"
                    cssName={dropdownClassName}
                  />
                )}
              </Col>
            </Row>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default CustomerNumber;
