import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Spinner, Card } from "react-bootstrap";
import { FaExternalLinkAlt } from "react-icons/fa";
import axios from "axios";

import { getStripeUrl } from "../helpers/url";
import { updatePlanName } from "../slice/subscriptionSlice";
import { ISubscriptionSlice } from "../types/subscriptionTypes";
import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../slice/preferenceSlice";
import { fetchStatus } from "../slice/mappingSlice";

import { FcInfo } from "react-icons/fc";

interface ICardsData {
  id: number;
  path: string;
  name: string;
}

interface IProps {
  appSlug?: string;
  userIds?: { [k: string]: string | number };
  CmsRichText?: React.ComponentType<{ path: string; cssName?: string }>;
  cardsData?: ICardsData[];
  customUrl?: string;
  subscriptionData?: {
    [k: string]: string;
  };
}

const PricingCards = (props: IProps) => {
  const {
    cardsData,
    appSlug,
    userIds,
    CmsRichText,
    customUrl,
    subscriptionData,
  } = props;
  const {
    queryParams,
    createCustomerMetaData,
    checkoutMetaData,
    checkoutDescription,
  } = subscriptionData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    subscription: { planData },
    plansList,
  } = useSelector(
    (state: { subscription: ISubscriptionSlice }) => state.subscription
  );
  const [paymentStatus, setPaymentStatus] = useState("");
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [downgradeLoading, setDowngradeLoading] = useState(false);
  const [billingPortalLoading, setBillingPortalLoading] = useState(false);
  const [customerKey, setCustomerKey] = useState("");
  const [updatedPlan, setUpdatedPlan] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");

  const openNewWindow = () => {
    window.addEventListener("message", (event) => {
      if (event.data?.paymentStatus && event.data?.plan) {
        console.log({
          paymentTerms: event.data?.paymentStatus,
          plan: event.data?.plan,
        });
        setPaymentStatus(event.data?.paymentStatus);
        setUpdatedPlan(event.data?.plan);
      }
    });
  };
  const onUpgradePlan = async ({ plan }) => {
    try {
      setSelectedPlan(plan);
      setUpgradeLoading(true);

      // Find/Create Customer in stripe
      const { data: customer } = await axios.post(
        getStripeUrl("REACT_APP_FIND_CREATE_CUSTOMER"),
        {
          appSlug: appSlug,
          metaData: createCustomerMetaData,
        }
      );
      setCustomerKey(customer.id);

      if (planData.plan === "basic") {
        const { data: res } = await axios.post(
          getStripeUrl("REACT_APP_UPDATE_PLAN"),
          {
            customerKey: customer.id,
            appSlug: appSlug,
            plan,
          }
        );

        dispatch(updatePlanName({ plan, customerKey: customer.id }));
        let msg;
        msg =
          "Your plan has been successfully upgraded to Premium. Please refresh the page if you do not see the changes.";
        if (res.message) msg = msg + ` ${res.message}`;

        dispatch(setShowSuccessModal({ message: msg }));
      } else if (planData.plan === "premium") {
        // Downgrade
        const { data: res } = await axios.post(
          getStripeUrl("REACT_APP_DOWNGRADE"),
          {
            customerKey: customer.id,
            appSlug: appSlug,
            plan,
          }
        );

        let msg;
        msg = "Your downgrade has been successfully scheduled.";

        if (res.message) msg = msg + ` ${res.message}`;
        console.log(msg);
        dispatch(
          setShowSuccessModal({
            message: msg,
          })
        );
      } else {
        // Create Checkout
        const { data: res } = await axios.post(
          getStripeUrl("REACT_APP_STRIPE_CHECKOUT"),
          {
            customerKey: customer.id,
            appSlug: appSlug,
            plan: plan,
            successUrl: `${window.location.origin}/redirect?${queryParams}&paymentStatus=cardAdded&plan=${plan}`,
            cancelUrl: `${window.location.origin}/redirect?${queryParams}&paymentStatus=failed&plan=${plan}`,
            subscriptionData: {
              description: checkoutDescription,
              metaData: checkoutMetaData,
            },
          }
        );

        if (res.url) {
          // Redirects to the stripe window
          window.open(res.url, "_blank");
        }
      }
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      console.log(errorMessage);
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
    } finally {
      setUpgradeLoading(false);
      setSelectedPlan("");
    }
  };

  const onCancelSubscription = async ({ plan }) => {
    try {
      setSelectedPlan(plan);
      setDowngradeLoading(true);
      const { data: res } = await axios.post(
        getStripeUrl("REACT_APP_SUBSCRIPTION_SCHEDULED"),
        {
          customerKey: planData.customerKey,
          appSlug: appSlug,
        }
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(fetchStatus({ userIds: userIds }) as any);
      let msg = "Cancellation has been successfully scheduled.";
      if (res.message) msg = msg + ` ${res.message}`;
      console.log(msg);
      dispatch(
        setShowSuccessModal({
          message: msg,
        })
      );
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      console.log(errorMessage);
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
    } finally {
      setDowngradeLoading(false);
      setSelectedPlan("");
    }
  };

  const paymentStatusCheck = () => {
    if (paymentStatus === "cardAdded" && updatedPlan) {
      dispatch(updatePlanName({ plan: updatedPlan, customerKey }));
      navigate(
        `..${window.location.pathname}?${queryParams}&paymentStatus=none`
      );
      dispatch(
        setShowSuccessModal({
          message:
            "cmsContent.notifications.subscriptionPage.successContent.upgradePlan.message",
        })
      );
    }
    setPaymentStatus("");
    setUpdatedPlan("");
  };

  const onOpenStripeBillingPortal = async () => {
    try {
      setBillingPortalLoading(true);

      const { data: response } = await axios.post(
        getStripeUrl("REACT_APP_STRIPE_BILLING_PORTAL"),
        {
          customerKey: planData.customerKey,
          returnUrl: `${window.location.origin}/redirect?${queryParams}&paymentStatus=none`,
        }
      );

      if (response.url) {
        window.open(response.url, "_blank");
      }
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      console.log(errorMessage);
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
    } finally {
      setBillingPortalLoading(false);
    }
  };

  useEffect(() => {
    openNewWindow();
    if (paymentStatus) {
      paymentStatusCheck();
    }
  }, [paymentStatus, updatedPlan]);

  const renderButtons = (card, upgradeLoading, downgradeLoading) => {
    const usageCountLimit = 15;
    const customerUsageCountLimit = 15;
    if (planData.plan === "trial" && card.name === "Free Trial") {
      return (
        <>
          {planData.usageCount >= usageCountLimit &&
          planData.customerUsageCount >= customerUsageCountLimit ? (
            <Button className="pricing-card-button-disable mt-3 mb-3" disabled>
              Trial Expired
            </Button>
          ) : (
            <Button className="pricing-card-button-disable mt-3 mb-3" disabled>
              Selected
            </Button>
          )}
        </>
      );
    } else if (
      (planData.plan === "trial" || planData.plan === "premium") &&
      card.name === "Basic"
    ) {
      return (
        <Button
          className="pricing-card-button mt-3 mb-3"
          disabled={upgradeLoading}
          onClick={() => onUpgradePlan({ plan: "basic" })}
        >
          {selectedPlan === "basic" && upgradeLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>{planData.plan === "trial" ? "Upgrade" : "Downgrade"}</span>
          )}
        </Button>
      );
    } else if (
      (planData.plan === "trial" || planData.plan === "basic") &&
      card.name === "Premium"
    ) {
      return (
        <Button
          className="pricing-card-button mt-3 mb-3"
          disabled={upgradeLoading}
          onClick={() => onUpgradePlan({ plan: "premium" })}
        >
          {selectedPlan === "premium" && upgradeLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Upgrade</span>
          )}
        </Button>
      );
    } else if (planData.plan === "basic" && card.name === "Basic") {
      return (
        <div className="mt-3 mb-3 d-flex flex-column">
          <Button className="pricing-card-button-disable" disabled>
            Selected
          </Button>
          <Button
            className="pricing-card-cancel-button mt-2"
            disabled={downgradeLoading}
            onClick={() => onCancelSubscription({ plan: "basic" })}
          >
            {selectedPlan === "basic" && downgradeLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Cancel Subscription</span>
            )}
          </Button>
        </div>
      );
    } else if (planData.plan === "premium" && card.name === "Premium") {
      return (
        <div className="mt-3 mb-3 d-flex flex-column">
          <Button className="pricing-card-button-disable" disabled>
            Selected
          </Button>
          <Button
            className="pricing-card-cancel-button mt-2"
            disabled={downgradeLoading}
            onClick={() => onCancelSubscription({ plan: "premium" })}
          >
            {selectedPlan === "premium" && downgradeLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Cancel Subscription</span>
            )}
          </Button>
        </div>
      );
    } else if (
      (planData.plan === "premium-cancelled" ||
        planData.plan === "basic-cancelled") &&
      card.name === "Premium"
    ) {
      return (
        <Button
          className="mt-3 mb-3 pricing-card-button"
          disabled={upgradeLoading}
          onClick={() => onUpgradePlan({ plan: "premium" })}
        >
          {selectedPlan === "premium" && upgradeLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Upgrade</span>
          )}
        </Button>
      );
    } else if (
      (planData.plan === "premium-cancelled" ||
        planData.plan === "basic-cancelled") &&
      card.name === "Basic"
    ) {
      return (
        <Button
          className="mt-3 mb-3 pricing-card-button"
          disabled={upgradeLoading}
          onClick={() => onUpgradePlan({ plan: "basic" })}
        >
          {selectedPlan === "basic" && upgradeLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Upgrade</span>
          )}
        </Button>
      );
    } else if (card.name === "Custom") {
      return (
        <Button
          href={customUrl}
          target="_blank"
          variant="success"
          className="mt-3 mb-3 pricing-card-button d-flex align-items-center justify-content-center"
        >
          <span>Explore now</span>
        </Button>
      );
    }
  };

  const renderText = () => {
    const usageCountLimit = 15;
    const customerUsageCountLimit = 15;
    if (planData.plan === "trial") {
      if (
        planData.usageCount >= usageCountLimit &&
        planData?.customerUsageCount >= customerUsageCountLimit
      ) {
        return "The Trial period of this app has expired. To continue using it, please upgrade to the Basic or Premium plan.";
      } else if (
        planData?.customerUsageCount < customerUsageCountLimit &&
        planData.usageCount >= usageCountLimit
      ) {
        return "The Trial period of using invoice sync has expired. To continue using it, please upgrade to the Basic or Premium plan.";
      } else if (
        planData?.customerUsageCount >= customerUsageCountLimit &&
        planData.usageCount < usageCountLimit
      ) {
        return "The Trial period of using Customer sync has expired. To continue using it, please upgrade to the Basic or Premium plan.";
      } else {
        return "You are currently in Trial plan.";
      }
    } else if (planData.plan === "basic") {
      return "You are currently subscribed to the Basic plan.";
    } else if (planData.plan === "basic-cancelled") {
      return "Your Basic plan has been cancelled. Please upgrade to Basic or Premium to continue using the app";
    } else if (planData.plan === "premium") {
      return "You are currently subscribed to the Premium plan.";
    } else if (planData.plan === "premium-cancelled") {
      return "Your Premium plan has been cancelled. Please upgrade to Basic or Premium to continue using the app";
    }
    return "";
  };

  return (
    <div>
      <div className="d-flex subscription-info-container">
        <div>
          <FcInfo className="info-icon" />
        </div>
        <div>
          <p>
            If the popup does not appear after clicking the upgrade button,
            please enable popups in your browser and proceed with the
            subscription process.
          </p>
        </div>
      </div>
      <Card className="subscription-card mt-4 mb-3">
        <Card.Body className="subscription-card-body">
          <p
            className="my-1"
            style={{
              color: `${
                planData.plan === "trial" &&
                planData.usageCount >= 15 &&
                planData.customerUsageCount >= 15 &&
                "#f34541"
              }`,
              fontWeight: `${
                planData.plan === "trial" &&
                planData.usageCount >= 15 &&
                planData.customerUsageCount >= 15 &&
                "600"
              }`,
            }}
          >
            {renderText()}
          </p>
          <div className="d-flex flex-row justify-content-between align-items-center">
            {planData.plan !== "basic" && (
              <p className="my-0">
                <span>
                  <b>Invoice sync usage: </b>
                  {planData.plan === "trial" && planData.usageCount > 15
                    ? 15
                    : planData.usageCount}
                </span>
                {planData.plan === "trial" && (
                  <span className="ms-3">
                    <b>Customer sync usage: </b> {planData.customerUsageCount}
                  </span>
                )}
              </p>
            )}
            {planData.customerKey && (
              <Button
                className="manage-billing-button"
                onClick={() => onOpenStripeBillingPortal()}
              >
                {billingPortalLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>
                    view and manage billing details{" "}
                    <FaExternalLinkAlt style={{ marginTop: "-3px" }} />
                  </span>
                )}
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      {/* )} */}

      <Row
        className={`pricing-cards-container h-100 px-2 ${
          plansList.includes(planData.plan) &&
          "d-flex flex-row justify-content-center"
        }`}
      >
        {cardsData &&
          planData.plan &&
          cardsData.map((card, index) => (
            <Col
              md={6}
              lg={5}
              className={`pricing-card my-3 ${
                plansList.includes(planData.plan) &&
                card.name === "Free Trial" &&
                "d-none"
              }`}
              style={{
                marginRight: `${plansList.includes(planData.plan) && "30px"}`,
              }}
              key={index}
            >
              <div>
                <div className="pricing-card-header px-2">
                  <CmsRichText
                    path={`cmsContent.subscriptionPage.${card.path}.text`}
                  />
                </div>
                <div className="pricing-card-price-container">
                  <CmsRichText
                    path={`cmsContent.subscriptionPage.${card.path}.price`}
                  />
                  <div style={{ padding: "0 20px 0 20px" }}>
                    {renderButtons(card, upgradeLoading, downgradeLoading)}
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <CmsRichText
                      path={`cmsContent.subscriptionPage.${card.path}.points`}
                    />
                  </div>
                </div>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default PricingCards;
