import React, { useState } from "react";

import { Dropdown } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";

import { IInvoiceState } from "../types/invoiceTypes";
import { IContactStates } from "../types/contactTypes";
import { IMappingStructure } from "../types/mappingTypes";
import { IProductGroups } from "../types/productTypes";
import { IFieldItem } from "../types/settingPageTypes";

interface IProps {
  dropdownFor: string;
  selectedValue: {
    name: string;
    value: string;
  };
  dropdownLabel?: string;
  fieldItems:
    | IMappingStructure[]
    | { [k: string]: string | number }[]
    | IProductGroups[]
    | IFieldItem[];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onChangeValue?: any;
  cssName: string;
  isDisabled?: boolean;
  dropdownWidth?: number;
  dropdownMenuWidth?: number;
}

export const SetupDropdown = (props: IProps) => {
  const {
    dropdownFor,
    selectedValue,
    dropdownLabel,
    fieldItems,
    onChangeValue,
    cssName,
    isDisabled,
    dropdownWidth,
    dropdownMenuWidth,
  } = props;
  const dispatch = useDispatch();

  const { selectedPipelines } = useSelector(
    (state: { invoice: IInvoiceState }) => state.invoice
  );

  const { customerNumberPreference } = useSelector(
    (state: { contact: IContactStates }) => state.contact
  );

  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const disableSelectedPipelines = (value: string) => {
    return Boolean(
      selectedPipelines.find((item) => item.pipeline.value === value)
    );
  };

  const disabledCustomerItems = (fieldItem) => {
    return fieldItem.CRF === "doNotAdd";
  };

  const searchFields = (enteredInput) => {
    setSearchInput(enteredInput);
    let searchArray;
    if (
      dropdownFor === "triggerAppContactFilterField" ||
      dropdownFor === "triggerAppProductFilterField" ||
      dropdownFor === "triggerAppContactNumberField" ||
      dropdownFor === "triggerAppCompanyNumberField" ||
      dropdownFor === "actionAppContactFilterField" ||
      dropdownFor === "actionAppProductFilterField" ||
      dropdownFor === "triggerAppCompanyFilterField" ||
      dropdownFor === "actionAppCompanyFilterField" ||
      dropdownFor === "products" ||
      dropdownFor === "defaultProduct" ||
      dropdownFor === "dealSyncProduct" ||
      dropdownFor === "overAllDiscountProduct"
    ) {
      searchArray = (fieldItems as IMappingStructure[]).filter((fieldItem) =>
        fieldItem.HRF.toLowerCase().includes(enteredInput.toLowerCase())
      );
    } else {
      searchArray = (fieldItems as IProductGroups[]).filter((fieldItem) =>
        fieldItem.title.toLowerCase().includes(enteredInput.toLowerCase())
      );
    }
    setSearchResults(searchArray);
  };

  const findSyncFieldsTitle = (value: string) => {
    if (
      dropdownFor === "triggerAppContactFilterField" ||
      dropdownFor === "triggerAppProductFilterField" ||
      dropdownFor === "triggerAppContactNumberField" ||
      dropdownFor === "triggerAppCompanyFilterField" ||
      dropdownFor === "triggerAppCompanyNumberField"
    ) {
      const field = (fieldItems as IMappingStructure[]).find(
        (item) => item.CRF === value
      );
      if (field) {
        return field.HRF;
      } else {
        return "custom property deleted";
      }
    } else if (
      dropdownFor === "actionAppContactFilterField" ||
      dropdownFor === "actionAppProductFilterField" ||
      dropdownFor === "actionAppCustomerNumberField" ||
      dropdownFor === "actionAppCompanyFilterField" ||
      dropdownFor === "products" ||
      dropdownFor === "defaultProduct" ||
      dropdownFor === "dealSyncProduct" ||
      dropdownFor === "overAllDiscountProduct"
    ) {
      const field = (fieldItems as IMappingStructure[]).find(
        (item) => item.CRF === value
      );
      if (field) {
        return field.HRF;
      } else {
        return "property deleted";
      }
    } else {
      const field = (fieldItems as IProductGroups[]).find(
        (item) => item.value === value
      );
      if (field) {
        if (field.title === "") {
          return field.value;
        }
        return field.title;
      } else {
        return "property deleted";
      }
    }
  };

  const toggleDropdownText = () => {
    if (
      dropdownFor === "actionAppProductFilterField" ||
      dropdownFor === "actionAppContactFilterField" ||
      dropdownFor === "actionAppCustomerNumberField" ||
      dropdownFor === "actionAppCompanyFilterField"
    ) {
      if (!selectedValue) {
        return `Select ${dropdownLabel} Field`;
      } else {
        return findSyncFieldsTitle(selectedValue.value);
      }
    } else if (
      dropdownFor === "triggerAppContactFilterField" ||
      dropdownFor === "triggerAppProductFilterField" ||
      dropdownFor === "triggerAppContactNumberField" ||
      dropdownFor === "triggerAppCompanyFilterField" ||
      dropdownFor === "triggerAppCompanyNumberField"
    ) {
      if (!selectedValue) {
        return `Select ${dropdownLabel} Field`;
      } else {
        return findSyncFieldsTitle(selectedValue.value);
      }
    } else if (
      dropdownFor === "products" ||
      dropdownFor === "defaultProduct" ||
      dropdownFor === "dealSyncProduct" ||
      dropdownFor === "overAllDiscountProduct"
    ) {
      if (!selectedValue) {
        return dropdownLabel ? `Select ${dropdownLabel}` : "Select product";
      } else {
        return findSyncFieldsTitle(selectedValue.value) === "property deleted"
          ? "product deleted"
          : findSyncFieldsTitle(selectedValue.value);
      }
    } else if (dropdownFor === "defaultFields") {
      if (!selectedValue) {
        return `Select ${dropdownLabel}`;
      } else {
        return findSyncFieldsTitle(selectedValue.value) === "property deleted"
          ? `${dropdownLabel} deleted`
          : findSyncFieldsTitle(selectedValue.value);
      }
    } else if (dropdownFor === "customerNumber") {
      return findSyncFieldsTitle(selectedValue.value);
    } else if (
      dropdownFor === "pipelines" ||
      dropdownFor === "dealStage" ||
      dropdownFor === "preferenceForCreation"
    ) {
      if (!selectedValue) {
        return `Select ${dropdownLabel}`;
      } else {
        return findSyncFieldsTitle(selectedValue.value) === "property deleted"
          ? `${dropdownLabel} deleted`
          : findSyncFieldsTitle(selectedValue.value);
      }
    }
  };
  return (
    <Dropdown className={`${cssName} ${isDisabled && "disabled-dropdown"}`}>
      <Dropdown.Toggle
        className={
          toggleDropdownText() === "property deleted" ||
          toggleDropdownText() === "product deleted" ||
          toggleDropdownText() === "custom property deleted" ||
          toggleDropdownText() === `${dropdownLabel} deleted`
            ? "setup-dropdown-color my-2"
            : "setup-dropdown my-2"
        }
        style={{ width: `${dropdownWidth}` }}
        disabled={isDisabled}
      >
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {toggleDropdownText()}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        renderOnMount={true}
        popperConfig={{ strategy: "fixed" }}
        style={{ zIndex: 1080 }}
      >
        <div className="p-3 dropdown-search-container">
          <div className="d-flex flex-row dropdown-search-box">
            <input
              type="search"
              placeholder="search.."
              className="w-100 dropdown-search"
              onChange={(event) => searchFields(event.target.value)}
            />
            <span className="my-auto">
              <FaSearch size={15} className="me-2 search-icon" />
            </span>
          </div>
        </div>
        {dropdownFor === "triggerAppContactFilterField" ||
        dropdownFor === "actionAppContactFilterField" ||
        dropdownFor === "triggerAppContactNumberField" ||
        dropdownFor === "actionAppCustomerNumberField" ||
        dropdownFor === "triggerAppProductFilterField" ||
        dropdownFor === "actionAppProductFilterField" ||
        dropdownFor === "triggerAppCompanyFilterField" ||
        dropdownFor === "actionAppCompanyFilterField" ||
        dropdownFor === "triggerAppCompanyNumberField" ||
        dropdownFor === "products" ||
        dropdownFor === "dealSyncProduct" ||
        dropdownFor === "defaultProduct" ||
        dropdownFor === "overAllDiscountProduct" ? (
          <div
            className="setup-dropdown-menu-container"
            style={{ width: `${dropdownMenuWidth}` }}
          >
            {searchInput.length > 0 && searchResults.length === 0 && (
              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <p style={{ fontSize: "14px", fontWeight: 600 }}>
                  No results found
                </p>
              </div>
            )}
            {(searchInput.length > 0 ? searchResults : fieldItems).map(
              (fieldItem) => (
                <Dropdown.Item
                  key={fieldItem.CRF}
                  disabled={
                    customerNumberPreference.value === "trigger" &&
                    disabledCustomerItems(fieldItem)
                  }
                  onClick={() =>
                    dispatch(
                      onChangeValue({
                        selectingFor: dropdownFor,
                        selectedOption: fieldItem,
                      })
                    )
                  }
                >
                  {fieldItem.HRF}
                </Dropdown.Item>
              )
            )}
          </div>
        ) : (
          <div
            className="setup-dropdown-menu-container"
            style={{ width: `${dropdownMenuWidth}` }}
          >
            {searchInput.length > 0 && searchResults.length === 0 && (
              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <p style={{ fontSize: "14px", fontWeight: 600 }}>
                  No results found
                </p>
              </div>
            )}
            {fieldItems.length === 0 && (
              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <p style={{ fontSize: "14px", fontWeight: 600 }}>
                  No items found
                </p>
              </div>
            )}
            {(searchInput.length > 0 ? searchResults : fieldItems).map(
              (fieldItem) => (
                <Dropdown.Item
                  key={fieldItem.value}
                  disabled={
                    dropdownFor === "pipelines" &&
                    disableSelectedPipelines(fieldItem.value)
                  }
                  onClick={() =>
                    dispatch(
                      onChangeValue({
                        selectingFor: dropdownLabel,
                        selectedOption: fieldItem,
                      })
                    )
                  }
                >
                  {fieldItem.title}
                </Dropdown.Item>
              )
            )}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
