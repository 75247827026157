import React from "react";

export interface IHorizontalProgressBar {
  progress: {
    [key: string]: boolean;
  };
  progressBarLabels?: {
    [key: string]: string;
  };
}

const HorizontalProgressBar = (props: IHorizontalProgressBar) => {
  const { progressBarLabels } = props;

  type Labels = {
    [key: string]: string;
  };
  const predefinedLabels: Labels = {
    "Invoice/Order Creation Rules": "Invoice Sync Rules",
    "Customer Search Rules": "Customer Preferences",
    "Product Sync Rules": "Product Sync Rules",
  };

  const labels = progressBarLabels ? progressBarLabels : predefinedLabels;

  return (
    <div className="horizontal-progress-bar mb-5">
      <div className="circles-container">
        {Object.keys(labels).length > 0 &&
          Object.keys(labels).map((label, index) => (
            <React.Fragment key={index}>
              <div
                className={
                  props.progress[labels[label]]
                    ? "active-page"
                    : "not-active-page"
                }
              >
                {props.progress[labels[label]] && <label>{index + 1}</label>}
              </div>
              {index !== Object.keys(labels).length - 1 && (
                <div className="line"></div>
              )}
            </React.Fragment>
          ))}
      </div>
      <div className="labels-container">
        {Object.keys(labels).length > 0 &&
          Object.keys(labels).map((label, index) => (
            <label
              key={index}
              className={`text-center mt-2 ${labels[label]
                .toLocaleLowerCase()
                .split(" ")
                .join("-")}`}
              style={{ width: "118px" }}
            >
              {label}
            </label>
          ))}
      </div>
    </div>
  );
};

export default HorizontalProgressBar;
