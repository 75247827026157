import axios from "axios";
import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../slice/preferenceSlice";
import { setIsInvoiceSyncRulesSaved } from "../slice/invoiceSlice";

import { getUrlInGeneric } from "./url";

export const invoiceSyncRulesSave = async (props) => {
  const {
    setIsLoading,
    dispatch,
    selectedPipelines,
    attachPDFToInvoice,
    triggerKeys,
    userIds,
    notification,
  } = props;
  try {
    setIsLoading(true);
    const data = {
      ...userIds,
      preferences: {
        associatePdf: attachPDFToInvoice,
      },
      preferenceType: "invoiceSync",
      trigger: {
        [triggerKeys[0]]: selectedPipelines,
      },
    };
    await axios.post(getUrlInGeneric("REACT_APP_SAVE_PREFERENCES"), data);
    dispatch(setIsInvoiceSyncRulesSaved());
    if (notification) {
      dispatch(
        setShowSuccessModal({
          message:
            "cmsContent.notifications.syncSettings.successContent.invoiceSyncRules.message",
        })
      );
    }
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};

export default invoiceSyncRulesSave;
