import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  PricingCards,
  setShowErrorModal,
  setShowSuccessModal,
  fetchStatus,
} from "generic";

import {
  fetchCmsData,
  onChangeIsUserAllowed,
  CmsEditAndSave,
  CmsRichText,
} from "cms";
import {
  updatePipedriveUserIds,
  Loading,
  onChangeCurrentWindow,
} from "pipedrive-frontend";

import { ICmsData } from "cms/types/cmsTypes";
import { IPdInitialState } from "pipedrive-frontend/types/types";

interface ICardData {
  id: number;
  path: string;
  name: string;
}

const Subscription = () => {
  const cardsData: ICardData[] = [
    {
      id: 1,
      path: "card1",
      name: "Free Trial",
    },
    {
      id: 2,
      path: "card2",
      name: "Basic",
    },
    {
      id: 3,
      path: "card3",
      name: "Premium",
    },
    {
      id: 4,
      path: "card4",
      name: "Custom",
    },
  ];

  const {
    allowedUsers,
    cmsData: { loading: cmsLoading },
  } = useSelector((state: { cms: ICmsData }) => state.cms);
  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const companyId = searchParams.get("companyId");
  const userId = searchParams.get("userId");

  const subscriptionData = {
    queryParams: `companyId=${companyId}&userId=${userId}`,
    createCustomerMetaData: {
      pipedriveUserId: userId,
    },
    checkoutMetaData: {
      companyId,
    },
    checkoutDescription: `Subscription active for the company with the companyId ${companyId}`,
  };

  useEffect(() => {
    if (window.location.pathname === "/subscription") {
      dispatch(fetchCmsData());
    }
  }, []);

  useEffect(() => {
    if (
      allowedUsers.length > 0 &&
      window.location.pathname === "/subscription"
    ) {
      dispatch(onChangeIsUserAllowed({ userId: pipedriveUserIds.userId }));
    }
  }, [allowedUsers]);

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    if (companyId && window.location.pathname === "/subscription") {
      dispatch(updatePipedriveUserIds({ companyId, userId }));
    }
  }, []);

  useEffect(() => {
    if (pipedriveUserIds && Object.keys(pipedriveUserIds).length > 0) {
      dispatch(fetchStatus({ userIds: pipedriveUserIds }));
    }
  }, [pipedriveUserIds]);

  const changeCurrentWindow = () => {
    navigate(
      `../${window.location.pathname}?companyId=${pipedriveUserIds.companyId}&userId=${pipedriveUserIds.userId}&page=invoiceSyncRules`
    );
    dispatch(onChangeCurrentWindow({ currentWindow: "Sync Window" }));
  };

  return (
    <div>
      {window.location.pathname === "/subscription" && (
        <div className="d-flex flex-column align-items-center my-3">
          <CmsEditAndSave
            userIds={pipedriveUserIds}
            appSlug="pipedriveMsbc-quick-install"
            setShowErrorModal={setShowErrorModal}
            setShowSuccessModal={setShowSuccessModal}
            editNotificationPath={`userId=${searchParams.get(
              "userId"
            )}&appSlug=pipedriveMsbc-quick-install`}
          />
        </div>
      )}
      <Container className="mt-4 mb-5">
        <Row className="d-flex flex-row justify-content-center">
          <Col xs={12} className="px-0">
            <div className="subscription-container mb-3">
              <CmsRichText
                path="cmsContent.subscriptionPage.text"
                cssName="pipedrive"
              />
            </div>

            <div>
              {cmsLoading ? (
                <Loading />
              ) : (
                <>
                  <PricingCards
                    cardsData={cardsData}
                    appSlug="pipedriveMsbc"
                    userIds={pipedriveUserIds}
                    CmsRichText={CmsRichText}
                    subscriptionData={subscriptionData}
                    customUrl="https://www.cloudify.biz/marketplace/packages/msbusinesscentral-pipedrive"
                  />
                  {window.location.pathname === "/setup" && (
                    <div className="mt-5 d-flex justify-content-end">
                      <Button
                        className="pd-button"
                        onClick={() => changeCurrentWindow()}
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Subscription;
