import React from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { IMappingItem, IField } from "../types/mappingTypes";

import {
  onEditMapping,
  onDeleteExistingMappings,
  onSaveEditedMapping,
  onCancelMapping,
  onSearchMappings,
} from "../slice/mappingSlice";

import MappingDropdown from "./Dropdown";
import MultiSelectDropdown from "./MultiSelectDropdown";

interface IProps {
  mappedItem: IMappingItem;
  mappings: IMappingItem[];
  mappingsFor: string;
  currentlyEditing: null | string;
  searchInput: string;
  selectedPreference: null | IField;
  isEditMapping: boolean;
  deletedTriggerFields: {
    [k: string]: string[];
  };
  triggerAppObject: {
    [k: string]: IField[];
  };
  fieldKeys: string[];
  isAccordion?: boolean;
  activeAccordion?: string;
  removeBorderThickness: boolean;
  isFiltered?: boolean;
  hiddenFields?: string[];
}

const RenderMappings = (props: IProps) => {
  const dispatch = useDispatch();

  const {
    mappedItem,
    mappings,
    mappingsFor,
    currentlyEditing,
    searchInput,
    selectedPreference,
    isEditMapping,
    deletedTriggerFields,
    triggerAppObject,
    fieldKeys,
    isAccordion,
    removeBorderThickness,
    isFiltered,
    hiddenFields,
  } = props;

  // Memoize getMappedField function
  const getMappedField = () => {
    const isFoundKey = Object.keys(mappedItem.triggerApp).find(
      (key) => fieldKeys.includes(key) && mappedItem.triggerApp[key].length > 0
    );
    return isFoundKey || null;
  };

  // Use the memoized getMappedField result
  const mappedItemKey = getMappedField();

  if (mappedItem.id === currentlyEditing) {
    return (
      <tr key={mappedItem.id} className="edit-row">
        <td style={{ width: "400px" }} className="ps-4 display-field-label">
          <span>{mappedItem.HRF}</span>
        </td>
        <td className="ps-4" style={{ width: "400px" }}>
          <MappingDropdown
            mappedItem={mappedItem}
            mappings={mappings}
            triggerAppObject={triggerAppObject}
            mappedItemKey={mappedItemKey}
            isAccordion={isAccordion}
          />
        </td>
        <td
          style={{ border: `${removeBorderThickness && "0px"}` }}
          className="d-flex flex-row justify-content-end align-items-center pe-4"
        >
          <div className="d-flex flex-row justify-content-end align-items-center">
            {selectedPreference && (
              <Button
                className="me-2 save-button"
                onClick={() =>
                  dispatch(
                    onSaveEditedMapping({
                      id: mappedItem.id,
                      mappingsFor,
                      isAccordion,
                      fieldKeys,
                    })
                  )
                }
              >
                Save
              </Button>
            )}

            <Button
              className="cancel-button"
              onClick={() => dispatch(onCancelMapping())}
            >
              Cancel
            </Button>
          </div>
        </td>
      </tr>
    );
  } else {
    return (
      <tr
        key={mappedItem.id}
        className={`normal-row ${
          hiddenFields &&
          hiddenFields.includes(mappedItem.CRF.toString()) &&
          isFiltered
            ? "d-none"
            : ""
        }`}
      >
        <td style={{ width: "400px" }} className="ps-4 display-field-label">
          <span>{mappedItem.HRF}</span>
          <br />
          {mappingsFor === "products" ? (
            <span className="description-text">
              {mappedItem.Description ?? ""}
            </span>
          ) : (
            <span className="description-text">
              {mappedItem.Description ?? ""}
              {mappedItem.Type ? ` (${mappedItem.Type})` : ""}
            </span>
          )}
        </td>
        {mappingsFor === "products" ? (
          <td className="ps-4">
            <MultiSelectDropdown
              mappedItem={mappedItem}
              mappingsFor={mappingsFor}
              deletedTriggerFields={deletedTriggerFields}
              triggerAppObject={triggerAppObject}
              mappings={mappings}
            />
          </td>
        ) : (
          <td style={{ width: "400px" }} className="ps-4 display-field-label">
            <span>
              {!mappedItemKey ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ fontSize: "12px" }}>
                      To add mapping for this field click on Action-edit or
                      leave as it is to not map this field.
                    </Tooltip>
                  }
                >
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#7a91ab",
                    }}
                  >
                    Not Mapped
                  </span>
                </OverlayTrigger>
              ) : (
                <>
                  <span>{mappedItem.triggerApp[mappedItemKey][0].HRF}</span>
                  <br />
                  {mappingsFor === "products" ? (
                    <span className="description-text">
                      {mappedItem.triggerApp[mappedItemKey][0].Description ??
                        ""}
                    </span>
                  ) : (
                    <span className="description-text">
                      {mappedItem.triggerApp[mappedItemKey][0].Description ??
                        ""}
                      {mappedItem.triggerApp[mappedItemKey][0].Type
                        ? ` (${mappedItem.triggerApp[mappedItemKey][0].Type})`
                        : ""}
                    </span>
                  )}
                  <br />
                  {mappedItem.triggerApp[mappedItemKey].length === 0
                    ? ""
                    : deletedTriggerFields?.[
                        `${mappedItemKey}_${mappingsFor}`
                      ] &&
                      deletedTriggerFields[
                        `${mappedItemKey}_${mappingsFor}`
                      ].includes(
                        mappedItem.triggerApp[mappedItemKey][0].CRF as string
                      ) && (
                        <span className="error-text">
                          (custom property deleted)
                        </span>
                      )}
                </>
              )}
            </span>
          </td>
        )}
        <td
          style={{ border: `${removeBorderThickness && "0px"}` }}
          className="pe-4 d-flex flex-row justify-content-end align-items-center"
        >
          <div className="d-flex flex-row justify-content-end align-items-center">
            {mappingsFor !== "products" && (
              <Button
                className="me-3 edit-button"
                disabled={isEditMapping}
                onClick={() => dispatch(onEditMapping({ id: mappedItem.id }))}
              >
                Edit
              </Button>
            )}
            <Button
              className="delete-button"
              disabled={
                isEditMapping ||
                mappedItem.Required ||
                !mappedItem?.triggerApp?.[mappedItemKey] ||
                (mappedItem?.triggerApp[mappedItemKey] &&
                  mappedItem?.triggerApp[mappedItemKey].length === 0)
              }
              onClick={() => {
                dispatch(
                  onDeleteExistingMappings({
                    mappingsFor,
                    id: mappedItem.id,
                    fieldKeys,
                    isAccordion,
                  })
                );
                dispatch(
                  onSearchMappings({
                    mappingsFor,
                    searchString: searchInput,
                    isAccordion,
                  })
                );
              }}
            >
              Delete
            </Button>
          </div>
        </td>
      </tr>
    );
  }
};

export default RenderMappings;
