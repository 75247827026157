import React from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { SetupDropdown } from "./SetupDropdown";

import {
  onChangeProductPreferences,
  onChangePreferenceForProducts,
} from "../slice/productSlice";

import { IProductStates, IProductGroups } from "../types/productTypes";
import { IMappingStructure } from "../types/mappingTypes";

type ProductNotFoundProps = {
  productGroups?: IProductGroups[];
  generalProductPostingGroups?: IProductGroups[];
  productTypes?: IProductGroups[];
  actionAppName: string;
  triggerAppName: string;
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
  cardCssName: string;
  dropdownCssName: string;
  actionAppProducts: IMappingStructure[];
  dropdownLabel?: string;
};

const ProductNotFound = (props: ProductNotFoundProps) => {
  const {
    productGroups,
    generalProductPostingGroups,
    productTypes,
    actionAppName,
    triggerAppName,
    actionAppProducts,
    CmsRichText,
    cardCssName,
    dropdownCssName,
    dropdownLabel,
  } = props;

  const dispatch = useDispatch();
  const {
    preferenceForProducts,
    productPreference,
    productGroupPreference,
    generalProductPostingGroupPreference,
    productTypePreference,
  } = useSelector((state: { products: IProductStates }) => state.products);

  const cmsCssName = triggerAppName.toLowerCase();

  return (
    <Card className={cardCssName}>
      <Card.Body className="sync-settings-card-body">
        <CmsRichText
          path="cmsContent.settings.products.syncRules.block2.text"
          cssName={cmsCssName}
        />
        <Row>
          <div className="d-flex flex-column mt-2">
            <div>
              <span className="do-not-generate-invoice">
                <Form.Check
                  type="radio"
                  id="doNotGenerateInvoice"
                  label="Do not generate invoice"
                  name="productPreference"
                  value="do not generate invoice"
                  onChange={(event) =>
                    dispatch(
                      onChangePreferenceForProducts({
                        selected: event.target.value,
                      })
                    )
                  }
                  checked={preferenceForProducts === "do not generate invoice"}
                />
              </span>
              {preferenceForProducts === "do not generate invoice" && (
                <div style={{ margin: "0 22px 0 22px" }}>
                  <CmsRichText
                    path="cmsContent.settings.products.syncRules.block2.doNotGenerateInvoice"
                    cssName={cmsCssName}
                  />
                </div>
              )}
            </div>
            <div className="mt-2">
              <span className="use-default-product">
                <Form.Check
                  type="radio"
                  id="UseDefaultProduct"
                  label={
                    dropdownLabel
                      ? `Use a default ${dropdownLabel}`
                      : "Use a default product"
                  }
                  name="productPreference"
                  value="select product"
                  onChange={(event) => {
                    dispatch(
                      onChangePreferenceForProducts({
                        selected: event.target.value,
                      })
                    );
                  }}
                  checked={preferenceForProducts === "select product"}
                />
              </span>
              {preferenceForProducts === "select product" && (
                <div style={{ margin: "0 22px 0 22px" }}>
                  <CmsRichText
                    path="cmsContent.settings.products.syncRules.block2.defaultProduct"
                    cssName={cmsCssName}
                  />
                </div>
              )}
              {preferenceForProducts === "select product" && (
                <Col
                  sm={5}
                  lg={3}
                  className="mt-2"
                  style={{ margin: "0 22px 0 22px" }}
                >
                  <label>
                    {dropdownLabel
                      ? `Select ${dropdownLabel}`
                      : "Select product"}
                  </label>
                  {actionAppProducts && actionAppProducts.length > 0 ? (
                    <SetupDropdown
                      fieldItems={actionAppProducts}
                      selectedValue={productPreference}
                      dropdownFor="products"
                      onChangeValue={onChangeProductPreferences}
                      cssName={dropdownCssName}
                      dropdownLabel={dropdownLabel}
                    />
                  ) : (
                    <div
                      className="d-flex flex-row justify-content-center align-items-center w-100"
                      style={{ border: "1px solid #d2dbe6" }}
                    >
                      <p className="text-center">
                        <b>{`No product present in ${actionAppName}`}</b>
                      </p>
                    </div>
                  )}
                </Col>
              )}
            </div>
            <div className="mt-2">
              <span className="find-create-product">
                <Form.Check
                  type="radio"
                  id="findCreateProduct"
                  label={
                    dropdownLabel
                      ? `Create a new ${dropdownLabel}`
                      : "Create a new product"
                  }
                  name="productPreference"
                  value="find create product"
                  onChange={(event) =>
                    dispatch(
                      onChangePreferenceForProducts({
                        selected: event.target.value,
                      })
                    )
                  }
                  checked={preferenceForProducts === "find create product"}
                />
              </span>
              {preferenceForProducts === "find create product" && (
                <div style={{ margin: "0 22px 0 22px" }} className="mb-3">
                  <CmsRichText
                    path="cmsContent.settings.products.syncRules.block2.createNewProduct"
                    cssName={cmsCssName}
                  />
                  {actionAppName === "e-conomic" && (
                    <Col sm={5} lg={3} className="mt-2">
                      <label>Select Product Group</label>
                      {productGroups && (
                        <SetupDropdown
                          fieldItems={productGroups}
                          selectedValue={productGroupPreference}
                          dropdownFor="defaultFields"
                          dropdownLabel="product groups"
                          onChangeValue={onChangeProductPreferences}
                          cssName={dropdownCssName}
                        />
                      )}
                    </Col>
                  )}
                  {actionAppName === "MS business central" && (
                    <>
                      <Col sm={5} lg={3} className="mt-2">
                        <label>Select General Product Posting Groups</label>
                        {generalProductPostingGroups && (
                          <SetupDropdown
                            fieldItems={generalProductPostingGroups}
                            selectedValue={generalProductPostingGroupPreference}
                            dropdownFor="defaultFields"
                            dropdownLabel="general product posting group"
                            onChangeValue={onChangeProductPreferences}
                            cssName={dropdownCssName}
                          />
                        )}
                      </Col>
                      <Col sm={5} lg={3} className="mt-2">
                        <label>Select Product Types</label>
                        {productTypes && (
                          <SetupDropdown
                            fieldItems={productTypes}
                            selectedValue={productTypePreference}
                            dropdownFor="defaultFields"
                            dropdownLabel="product type"
                            onChangeValue={onChangeProductPreferences}
                            cssName={dropdownCssName}
                          />
                        )}
                      </Col>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductNotFound;
