export const checkProductMappingsCount = (props) => {
  const { productMappings } = props;
  let count = 0;
  productMappings.forEach((mappedItem) => {
    if (mappedItem.triggerApp && mappedItem.triggerApp.products.length > 0) {
      count = count + 1;
    }
  });
  return count;
};
