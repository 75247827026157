import axios from "axios";

import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../slice/preferenceSlice";

import { setIsProductSyncRulesSaved } from "../slice/productSlice";

import { getUrlInGeneric } from "./url";

export const saveProductSyncRules = async (props) => {
  const {
    setIsLoading,
    userIds,
    preferenceForProducts,
    productPreference,
    preferenceForCreatingProduct,
    defaultProductForCreating,
    preferenceForSearchParameters,
    triggerAppProductFilterPreference,
    actionAppProductFilterPreference,
    dealSyncProductPreference,
    dealsSyncCheckbox,
    actionAppName,
    dispatch,
    notification,
    productGroupPreference,
    generalProductPostingGroupPreference,
    productTypePreference,
    triggerAppName,
    isOverAllDiscountPresent,
    overAllDiscountProductPreference,
  } = props;

  try {
    setIsLoading(true);
    const data = {
      ...userIds,
      preferences: {
        searchParameter:
          preferenceForSearchParameters === "select search parameters"
            ? true
            : false,
        searchFilterOptions: {
          filterProperty: actionAppProductFilterPreference,
          triggerApp: {
            products: {
              filterProperty: triggerAppProductFilterPreference,
            },
          },
        },
        createProduct: {
          create:
            preferenceForProducts === "find create product" ? true : false,
          customProduct: {
            useDefaultProduct:
              preferenceForProducts === "find create product" &&
              preferenceForCreatingProduct
                ? true
                : false,

            defaultProduct:
              preferenceForCreatingProduct &&
              preferenceForProducts === "find create product" &&
              defaultProductForCreating?.value
                ? defaultProductForCreating
                : {},
          },
        },
        generateWorkflow: {
          create:
            preferenceForProducts === "do not generate invoice" ||
            preferenceForProducts === "find create product"
              ? false
              : true,
          mapping: {
            defaultProduct:
              preferenceForProducts === "select product" &&
              productPreference?.value
                ? productPreference
                : {},
          },
        },
        missingLineItems: {
          create: dealsSyncCheckbox,
          mapping: {
            defaultProduct: dealSyncProductPreference,
          },
        },
      },
      preferenceType: "productSync",
    };

    if (triggerAppName && triggerAppName.toLowerCase() === "pipedrive")
      data.preferences.discountLineItem = {
        create: isOverAllDiscountPresent,
        mapping: {
          defaultProduct:
            isOverAllDiscountPresent && overAllDiscountProductPreference?.value
              ? overAllDiscountProductPreference
              : {},
        },
      };

    if (
      actionAppName === "e-conomic" &&
      preferenceForProducts === "find create product" &&
      productGroupPreference
    ) {
      data.preferences.defaultFields = {
        productGroups: productGroupPreference,
      };
    } else if (
      actionAppName === "Business Central" &&
      preferenceForProducts === "find create product" &&
      generalProductPostingGroupPreference &&
      productTypePreference
    ) {
      data.preferences.defaultFields = {
        generalProductPostingGroup: generalProductPostingGroupPreference,
        productTypes: productTypePreference,
      };
    }
    await axios.post(getUrlInGeneric("REACT_APP_SAVE_PREFERENCES"), data);
    if (notification) {
      dispatch(
        setShowSuccessModal({
          message:
            "cmsContent.notifications.syncSettings.successContent.productSyncRules.message",
        })
      );
    }
    dispatch(setIsProductSyncRulesSaved());
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};
