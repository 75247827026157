import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Row, Col, Spinner, Dropdown, Button } from "react-bootstrap";
import axios from "axios";

import {
  fetchFinancialData,
  onChangeTime,
  setShowErrorModal,
  setShowSuccessModal,
} from "generic";

import { getUrlInPd } from "../helpers/url";

import { IPreferenceStates } from "generic/types/preferencetypes";
import { IPdInitialState } from "../types/types";

interface IProps {
  actionAppName: string;
}

const FinancialIframe = (props: IProps) => {
  const { actionAppName } = props;
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [btnLoading, setBtnLoading] = useState(false);

  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );
  const {
    timeDropdownFields,
    timePreference,
    financialObject,
    lastSynced,
    financialData: { loading },
    /* eslint-disable @typescript-eslint/no-explicit-any */
  } = useSelector((state: { financial: any }) => state.financial);

  const { isAppConnected } = useSelector(
    (state: { preference: IPreferenceStates }) => state.preference
  );

  /**
   * Displays selected time preference
   * @returns {string} - Selected time preference
   */
  const toggleDropdownText = () => {
    if (!timePreference) {
      return "Select";
    } else {
      return timeDropdownFields.find((field) => field.value === timePreference)
        .name;
    }
  };

  /**
   * Updates financial data
   */
  const onUpdateFinancialData = async () => {
    try {
      setBtnLoading(true);
      const objectId = searchParams.get("selectedIds");
      let syncType = searchParams.get("syncType");

      syncType = syncType ? syncType : "organization";

      if (!timePreference) throw new Error("Please select the time preference");
      if (!isAppConnected)
        throw new Error(`Please connect to ${actionAppName}`);

      // Update financial data
      await axios.post(getUrlInPd("REACT_APP_UPDATE_FINANCIAL_DATA"), {
        ...pipedriveUserIds,
        objectId,
        timePreference,
        syncType,
      });
      dispatch(
        fetchFinancialData({
          userIds: pipedriveUserIds,
          objectId:
            syncType === "organization"
              ? `org_${objectId}`
              : `person_${objectId}`,
        }) as any
      );
      dispatch(
        setShowSuccessModal({ message: "Financial data updated successfully" })
      );
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <Row className="w-100 d-flex flex-row justify-content-center">
      <Col xs={12} lg={6} className="financial-iframe">
        {loading ? (
          <div className="d-flex flex-column justify-content-center align-items-center my-5">
            <Spinner
              animation="border"
              variant="success"
              role="status"
            ></Spinner>
          </div>
        ) : (
          <div className="py-3">
            <div className="text-center">
              <h5>Financial Report</h5>
              {lastSynced && <p>(Last Updated on {lastSynced})</p>}
            </div>

            <div className="d-flex flex-row justify-content-between mt-3">
              <Col xs={6}>
                <p className="mb-1">
                  <b>Time:</b>
                </p>
                <Dropdown className="w-100">
                  <Dropdown.Toggle className="financial-card-dropdown">
                    {toggleDropdownText()}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="dropdown-container"
                    renderOnMount={true}
                    popperConfig={{ strategy: "fixed" }}
                  >
                    {timeDropdownFields &&
                      timeDropdownFields.map((field) => (
                        <Dropdown.Item
                          key={field.value}
                          onClick={() =>
                            dispatch(
                              onChangeTime({
                                selectedOption: field.value,
                              })
                            )
                          }
                        >
                          {field.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>

              <Col
                xs={6}
                className="d-flex flex-row justify-content-end align-items-end"
              >
                <Button
                  className="save-changes-button"
                  disabled={btnLoading}
                  onClick={() => onUpdateFinancialData()}
                  style={{
                    minWidth: "100px",
                  }}
                >
                  {btnLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Col>
            </div>

            <ul className="mt-4 ps-0 financial-unordered-list">
              {financialObject &&
                Object.keys(financialObject).map((key, index) => (
                  <li
                    key={index}
                    className="py-2"
                    style={{
                      borderTop: "1px solid #656e7a",

                      borderBottom: `${index === 4 && "1px solid #656e7a"}`,
                    }}
                  >
                    <p className="mb-0 my-auto d-flex flex-row justify-content-between align-items-center">
                      <Col xs={8}>
                        <b>{key}</b>
                      </Col>
                      <Col xs={4} className="text-end">
                        <b>{financialObject[key]}</b>
                      </Col>
                    </p>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default FinancialIframe;
