import axios from "axios";
import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../slice/preferenceSlice";

import { setIsContactSyncRulesSaved } from "../slice/contactSlice";

import { getUrlInGeneric } from "./url";

export const saveCustomerSyncRules = async (props) => {
  const {
    setIsLoading,
    userIds,
    actionAppCompanyFilterPreference,
    triggerAppCompanyFilterPreference,
    triggerAppContactFilterPreference,
    actionAppContactFilterPreference,
    customerNumberPreference,
    triggerAppCompanyNumberPreference,
    triggerAppContactNumberPreference,
    isContactCheckbox,
    searchFilterOptionKeys,
    dispatch,
    notification,
  } = props;
  try {
    setIsLoading(true);
    const data = {
      ...userIds,
      preferences: {
        searchFilterOptions: {
          triggerApp: {
            [searchFilterOptionKeys[0]]: {
              filterProperty: triggerAppCompanyFilterPreference,
              actionApp: {
                filterProperty: actionAppCompanyFilterPreference,
              },
            },
            [searchFilterOptionKeys[1]]: {
              filterProperty: triggerAppContactFilterPreference,
              actionApp: {
                filterProperty: actionAppContactFilterPreference,
              },
            },
          },
        },
        customerNumber: {
          direction: customerNumberPreference.value,
          mapping: {
            triggerApp: {
              [searchFilterOptionKeys[0]]: {
                triggerProperty: triggerAppCompanyNumberPreference,
              },
              [searchFilterOptionKeys[1]]: {
                triggerProperty: triggerAppContactNumberPreference,
              },
            },
          },
        },
        syncOptions: {
          triggerApp: {
            useSecondarySync:
              isContactCheckbox === "" ? false : isContactCheckbox,
          },
        },
      },
      preferenceType: "customerSync",
    };
    await axios.post(getUrlInGeneric("REACT_APP_SAVE_PREFERENCES"), data);
    dispatch(setIsContactSyncRulesSaved());
    if (notification) {
      dispatch(
        setShowSuccessModal({
          message:
            "cmsContent.notifications.syncSettings.successContent.contactSyncRules.message",
        })
      );
    }
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    console.log(errorMessage);
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};
