import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setShowErrorModal } from "./preferenceSlice";

import { IInvoiceState } from "../types/invoiceTypes";

import { getUrlInGeneric } from "../helpers/url";

import { fetchStatus } from "./mappingSlice";

const initialState: IInvoiceState = {
  selectedPipelines: [],
  attachPDFToInvoice: false,
  pipelines: {
    loading: false,
    pipelines: [],
    error: "",
  },
  dealStage: {
    loading: false,
    dealStages: [],
    error: "",
  },
  invoiceDropdown: [
    {
      title: "Create draft invoice",
      value: "invoice",
    },
    {
      title: "Create draft order",
      value: "order",
    },
  ],
  pipelinePreference: undefined,
  dealStagePreference: undefined,
  invoicePreference: undefined,
  isInvoiceSyncRulesSaved: false,
};

interface IFetchProps {
  userIds: {
    [k: string]: string | number;
  };
  pipelineId?: string | number;
}

export const fetchPipelines = createAsyncThunk(
  "invoiceSync/fetchPipelines",
  async (props: IFetchProps, { dispatch }) => {
    try {
      const { userIds } = props;
      const res = await axios.get(
        getUrlInGeneric("REACT_APP_FETCH_TRIGGER_APP_PIPELINES"),
        {
          params: userIds,
        }
      );
      return res.data;
    } catch (error) {
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    }
  }
);

export const fetchDealStages = createAsyncThunk(
  "invoiceSync/fetchDealStages",
  async (props: IFetchProps, { dispatch }) => {
    try {
      const { userIds, pipelineId } = props;
      const res = await axios.get(
        getUrlInGeneric("REACT_APP_FETCH_TRIGGER_APP_DEAL_STAGES"),
        {
          params: { ...userIds, pipelineId },
        }
      );
      return res.data;
    } catch (error) {
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    }
  }
);

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    onCheckAddPDF(state) {
      state.attachPDFToInvoice = !state.attachPDFToInvoice;
      state.isInvoiceSyncRulesSaved = false;
    },
    setSelectedInvoiceFields(state, { payload }) {
      if (payload.selectingFor === "Pipeline") {
        state.pipelinePreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.dealStagePreference = undefined;
      } else if (payload.selectingFor === "Deal stage") {
        state.dealStagePreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
      } else if (payload.selectingFor.includes("Invoice")) {
        state.invoicePreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
      }
    },
    onDeletePipeline(state, { payload }) {
      state.selectedPipelines.splice(payload.index, 1);
      state.isInvoiceSyncRulesSaved = false;
    },
    onAddPipeline(state) {
      if (
        state.pipelinePreference &&
        state.dealStagePreference &&
        state.invoicePreference
      ) {
        state.selectedPipelines.push({
          pipeline: state.pipelinePreference,
          dealStage: state.dealStagePreference,
          generate: state.invoicePreference,
        });
        state.isInvoiceSyncRulesSaved = false;
        state.pipelinePreference = undefined;
        state.dealStagePreference = undefined;
        state.invoicePreference = undefined;
      }
    },
    onDeleteSelectedPipelinePreferences(state) {
      state.pipelinePreference = undefined;
      state.dealStagePreference = undefined;
      state.invoicePreference = undefined;
    },
    setIsInvoiceSyncRulesSaved(state) {
      state.isInvoiceSyncRulesSaved = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPipelines.pending, (state) => {
      if (!state.pipelines.loading) {
        state.pipelines.loading = true;
      }
    });
    builder.addCase(fetchPipelines.fulfilled, (state, actions) => {
      if (state.pipelines.loading) {
        state.pipelines.loading = false;
        state.pipelines.pipelines = actions.payload;
        state.pipelines.error = "";
      }
    });
    builder.addCase(fetchPipelines.rejected, (state, actions) => {
      if (state.pipelines.loading) {
        state.pipelines.loading = false;
        state.pipelines.pipelines = [];
        state.pipelines.error = actions.error.message ?? "";
      }
    });

    builder.addCase(fetchDealStages.pending, (state) => {
      if (!state.dealStage.loading) {
        state.dealStage.loading = true;
      }
    });
    builder.addCase(fetchDealStages.fulfilled, (state, actions) => {
      if (state.dealStage.loading) {
        state.dealStage.loading = false;
        state.dealStage.dealStages = actions.payload;
        state.dealStage.error = "";
      }
    });
    builder.addCase(fetchDealStages.rejected, (state, actions) => {
      if (state.dealStage.loading) {
        state.dealStage.loading = false;
        state.dealStage.dealStages = [];
        state.dealStage.error = actions.error.message ?? "";
      }
    });

    builder.addCase(fetchStatus.fulfilled, (state, actions) => {
      const { preferences, triggers } = actions.payload;
      const { invoiceSync } = preferences;

      if (invoiceSync) {
        state.attachPDFToInvoice = invoiceSync.associatePdf;
      }

      if (triggers && Object.keys(triggers).length > 0) {
        const firstKey = Object.keys(triggers)[0];
        state.selectedPipelines = triggers[firstKey];
      }

      if (state.selectedPipelines.length > 0) {
        state.isInvoiceSyncRulesSaved = true;
      }
    });
  },
});

export const {
  onCheckAddPDF,
  setSelectedInvoiceFields,
  onDeletePipeline,
  onAddPipeline,
  onDeleteSelectedPipelinePreferences,
  setIsInvoiceSyncRulesSaved,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
