import React from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { SetupDropdown } from "./SetupDropdown";

import {
  onChangePreferenceForSearchParameters,
  onChangeProductPreferences,
} from "../slice/productSlice";

import { IProductStates } from "../types/productTypes";
import { IMappingStructure } from "../types/mappingTypes";

type ProductSearchProps = {
  actionAppName: string;
  triggerAppName: string;
  actionAppProductSearchFields: IMappingStructure[];
  triggerAppProductSearchFields: IMappingStructure[];
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
  cardCssName: string;
  dropdownCssName: string;
  CustomProductMapping: React.ComponentType;
};

const ProductSearch = (props: ProductSearchProps) => {
  const {
    actionAppName,
    triggerAppName,
    actionAppProductSearchFields,
    triggerAppProductSearchFields,
    CmsRichText,
    cardCssName,
    dropdownCssName,
    CustomProductMapping,
  } = props;

  const dispatch = useDispatch();
  const {
    preferenceForSearchParameters,
    triggerAppProductFilterPreference,
    actionAppProductFilterPreference,
  } = useSelector((state: { products: IProductStates }) => state.products);

  const cmsCssName = triggerAppName.toLowerCase();

  return (
    <>
      <Card className={cardCssName} style={{ marginTop: "29px" }}>
        <Card.Body className="sync-settings-card-body">
          <div>
            <CmsRichText
              path="cmsContent.settings.products.syncRules.block1.text"
              cssName={cmsCssName}
            />
            <span className="product-search-parameters">
              <Form.Check
                type="radio"
                id="selectSearchParameters"
                name="productSearchParameters"
                value="select search parameters"
                label="Select search parameters"
                className="mb-2 mt-3"
                checked={
                  preferenceForSearchParameters === "select search parameters"
                }
                onChange={(event) => {
                  dispatch(
                    onChangePreferenceForSearchParameters({
                      selected: event.target.value,
                    })
                  );
                }}
              />
            </span>
            {preferenceForSearchParameters === "select search parameters" && (
              <div className="ms-3 mb-3">
                <CmsRichText
                  path="cmsContent.settings.products.syncRules.block1.searchParameters"
                  cssName={cmsCssName}
                />
                {triggerAppProductSearchFields &&
                  triggerAppProductSearchFields.length > 0 &&
                  actionAppProductSearchFields &&
                  actionAppProductSearchFields.length > 0 && (
                    <Row className="mt-2">
                      <Col sm={5} lg={3}>
                        <label>{triggerAppName}</label>
                        <SetupDropdown
                          fieldItems={triggerAppProductSearchFields}
                          selectedValue={triggerAppProductFilterPreference}
                          onChangeValue={onChangeProductPreferences}
                          dropdownFor="triggerAppProductFilterField"
                          dropdownLabel={triggerAppName}
                          cssName={dropdownCssName}
                        />
                      </Col>
                      <Col
                        sm={1}
                        className="mx-0 sync-settings-card-direction"
                      ></Col>
                      <Col sm={5} lg={3}>
                        <label>{actionAppName}</label>
                        <SetupDropdown
                          fieldItems={actionAppProductSearchFields}
                          selectedValue={actionAppProductFilterPreference}
                          dropdownFor="actionAppProductFilterField"
                          onChangeValue={onChangeProductPreferences}
                          dropdownLabel={actionAppName}
                          cssName={dropdownCssName}
                        />
                      </Col>
                    </Row>
                  )}
              </div>
            )}
            <span className="custom-product-mapping">
              <Form.Check
                type="radio"
                id="setCustomMappings"
                name="productSearchParameters"
                value="set custom mappings"
                label="Set custom mappings"
                className="mb-2"
                checked={
                  preferenceForSearchParameters === "set custom mappings"
                }
                onChange={(event) => {
                  dispatch(
                    onChangePreferenceForSearchParameters({
                      selected: event.target.value,
                    })
                  );
                }}
              />
            </span>
            {preferenceForSearchParameters === "set custom mappings" && (
              <div className="ms-3 mb-3">
                <CmsRichText
                  path="cmsContent.settings.products.syncRules.block1.customMappings"
                  cssName={cmsCssName}
                />
                <CustomProductMapping />
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProductSearch;
