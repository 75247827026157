export const showTooltipText = (props) => {
  const { paymentTermsCodePreference, currencyCodePreference } = props;

  let tooltipText = "";

  if (!paymentTermsCodePreference) {
    tooltipText = "Please select payment terms code.";
  } else if (!currencyCodePreference) {
    tooltipText = "Please select currency code.";
  }
  return tooltipText;
};
