import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import {
  onChangeContactPreferences,
  onChangeCheckboxForContact,
  SetupDropdown,
} from "generic";

import { IPdInitialState } from "pipedrive-frontend/types/types";
import { IMsbcStates } from "msbc-frontend/types/msbcTypes";
import { IContactStates } from "generic/types/contactTypes";

import { CmsRichText } from "cms";

const CustomerSearchRules = () => {
  const {
    fields: {
      data: {
        organizationSearchFields: triggerAppCompaniesSearchFields,
        personSearchFields: triggerAppContactsSearchFields,
      },
    },
  } = useSelector((state: { pipedrive: IPdInitialState }) => state.pipedrive);

  const {
    fields: {
      data: { customerSearchFields: actionAppCustomerSearchFields },
    },
  } = useSelector((state: { msbc: IMsbcStates }) => state.msbc);

  const {
    triggerAppCompanyFilterPreference,
    triggerAppContactFilterPreference,
    actionAppCompanyFilterPreference,
    actionAppContactFilterPreference,
    isContactCheckbox,
  } = useSelector((state: { contact: IContactStates }) => state.contact);

  const dispatch = useDispatch();

  return (
    <div>
      <CmsRichText
        path="cmsContent.install.customerSearchRules.headerContent"
        cssName="pipedrive"
      />
      <div className="install-company-search-parameters">
        <div className="mt-5">
          <CmsRichText
            path="cmsContent.install.customerSearchRules.identifyCustomer.title"
            cssName="pipedrive"
          />
          <CmsRichText
            path="cmsContent.install.customerSearchRules.identifyCustomer.description"
            cssName="pipedrive"
          />
          <div className="mt-3">
            <SetupDropdown
              fieldItems={triggerAppCompaniesSearchFields}
              selectedValue={triggerAppCompanyFilterPreference}
              onChangeValue={onChangeContactPreferences}
              dropdownFor="triggerAppCompanyFilterField"
              dropdownLabel="Pipedrive"
              cssName="install-flow-dropdown"
            />
          </div>
        </div>
        <div className="mt-5">
          <CmsRichText
            path="cmsContent.install.customerSearchRules.msbcCustomer.title"
            cssName="pipedrive"
          />
          <CmsRichText
            path="cmsContent.install.customerSearchRules.msbcCustomer.description"
            cssName="pipedrive"
          />
          <div className="mt-3">
            <SetupDropdown
              fieldItems={actionAppCustomerSearchFields}
              selectedValue={actionAppCompanyFilterPreference}
              onChangeValue={onChangeContactPreferences}
              dropdownFor="actionAppCompanyFilterField"
              dropdownLabel="MS business central"
              cssName="install-flow-dropdown"
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="install-contact-checkbox">
          <div>
            <CmsRichText
              path="cmsContent.install.customerSearchRules.pipedrivePerson.title"
              cssName="pipedrive"
            />
          </div>
          <Form.Check
            type="checkbox"
            id="contactSearchParameter"
            label={`B2C Sync Activation and enable primary contact sync.`}
            checked={isContactCheckbox}
            onChange={() => dispatch(onChangeCheckboxForContact())}
            className="install-form-check-label"
          />
        </div>
        {isContactCheckbox && (
          <div className="mt-3 install-contact-search-parameters">
            <CmsRichText
              path="cmsContent.install.customerSearchRules.pipedrivePerson.description"
              cssName="pipedrive"
            />
            <div className="mt-3">
              <SetupDropdown
                fieldItems={triggerAppContactsSearchFields}
                selectedValue={triggerAppContactFilterPreference}
                onChangeValue={onChangeContactPreferences}
                dropdownFor="triggerAppContactFilterField"
                cssName="install-flow-dropdown"
              />
            </div>
            <div className="mt-5">
              <CmsRichText
                path="cmsContent.install.customerSearchRules.msbcContact.title"
                cssName="pipedrive"
              />
              <CmsRichText
                path="cmsContent.install.customerSearchRules.msbcContact.description"
                cssName="pipedrive"
              />
              <div>
                <SetupDropdown
                  fieldItems={actionAppCustomerSearchFields}
                  selectedValue={actionAppContactFilterPreference}
                  onChangeValue={onChangeContactPreferences}
                  dropdownFor="actionAppContactFilterField"
                  dropdownLabel="MS business central"
                  cssName="install-flow-dropdown"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerSearchRules;
