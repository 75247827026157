import React, { useState, useEffect } from "react";
import { Button, Card, Container, Spinner, Col } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ImRadioChecked2 } from "react-icons/im";
import { FaCheckCircle } from "react-icons/fa";
import axios from "axios";
import { getUrlInGeneric } from "../helpers/url";

import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../slice/preferenceSlice";

interface IProps {
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
  actionAppName: string;
  triggerAppName: string;
  retriggerStages: {
    [k: string]: {
      label: string;
      value: boolean;
    };
  };
  retriggerBody: { [k: string]: string | number };
}

const Retrigger = (props: IProps) => {
  const {
    CmsRichText,
    actionAppName,
    triggerAppName,
    retriggerStages,
    retriggerBody,
  } = props;

  const [stageData, setStageData] = useState(retriggerStages);
  const [isLoading, setIsLoading] = useState(false);
  const [retriggeredNote, setRetriggeredNote] = useState("");
  const [dealStage, setDealStage] = useState("");
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const onRetriggerFlow = async () => {
    try {
      setRetriggeredNote("");
      setIsLoading(true);

      await axios.post(getUrlInGeneric("REACT_APP_RETRIGGER"), retriggerBody);

      for (const stage in stageData) {
        stageData[stage].value = true;
      }
      setStageData({ ...stageData });
      setDealStage("");

      setRetriggeredNote(
        `The ${actionAppName} draft invoice has been generated successfully`
      );

      dispatch(
        setShowSuccessModal({
          message:
            "cmsContent.notifications.retrigger.successContent.retriggerSuccess.message",
        })
      );
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      setDealStage(error.response.data.errorStage);
      setRetriggeredNote(
        `The workflow execution was failed due to this error : ${errorMessage}`
      );
      dispatch(
        setShowErrorModal({
          message:
            "cmsContent.notifications.retrigger.failContent.retriggerFailed.message",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const stageName = searchParams.get("stageName");
    const errorLogs = searchParams.get("errorLogs");
    if (errorLogs) {
      setRetriggeredNote(
        `The workflow execution was failed due to this error : ${errorLogs}`
      );
    }
    setDealStage(stageName);
  }, []);

  useEffect(() => {
    if (dealStage) {
      for (const stage in stageData) {
        stageData[stage].value = undefined;
      }
      setStageData({ ...stageData });

      for (const stage in stageData) {
        if (stage === dealStage) {
          stageData[stage].value = false;
          break;
        } else {
          stageData[stage].value = true;
        }
      }
    }
    setStageData({ ...stageData });
  }, [dealStage]);

  const renderIcons = (value) => {
    if (value === true) {
      return <FaCheckCircle size={25} className="me-3 logs-success-icon" />;
    } else if (value === false) {
      return <ImRadioChecked2 size={25} className="me-3 logs-failed-icon" />;
    } else {
      return <div className="logs-empty-icon me-3"></div>;
    }
  };

  return (
    <Container className="d-flex flex-row justify-content-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Col md={8} className="mb-5">
          <CmsRichText
            path="cmsContent.retrigger.text"
            cssName={triggerAppName.toLowerCase()}
          />
        </Col>
        <Col md={6} className="d-flex flex-row justify-content-center">
          <div className="w-100">
            {retriggeredNote && (
              <Card
                className="sync-settings-card"
                style={{ marginBottom: "27px", marginTop: "0px" }}
              >
                <Card.Body className="sync-settings-card-body">
                  <p className="my-1">
                    <b>Note: </b> <br />
                    {retriggeredNote}
                  </p>
                </Card.Body>
              </Card>
            )}

            <div className="d-flex flex-row justify-content-center w-100">
              <div>
                {stageData &&
                  Object.keys(stageData).length > 0 &&
                  Object.keys(stageData).map((stage) => (
                    <div key={stage}>
                      <div className="d-flex flex-row align-items-center">
                        {renderIcons(stageData[stage].value)}

                        <CmsRichText
                          path={`cmsContent.retrigger.${stageData[stage].label}`}
                          cssName="retrigger-label"
                        />
                      </div>
                      {stage !== "syncDeal" && (
                        <div
                          style={{
                            borderLeft: `${
                              stageData[stage].value === true
                                ? "4px solid #08a742"
                                : "4px solid #d9d9d9"
                            }`,
                            height: "30px",
                            marginLeft: "10px",
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                <div className="d-flex flex-row justify-content-center mb-5">
                  <Button
                    className="logs-retrigger-button mt-5"
                    onClick={() => onRetriggerFlow()}
                    disabled={isLoading || !dealStage}
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <span>Replay</span>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default Retrigger;
