import { createSlice } from "@reduxjs/toolkit";

import { IPreferenceStates } from "../types/preferencetypes";

import { fetchStatus } from "./mappingSlice";

const initialState: IPreferenceStates = {
  settingsActiveTab: "contact",
  contactActiveTab: "syncRules",
  invoiceActiveTab: "syncRules",
  productActiveTab: "syncRules",
  isAppConnected: false,
  connectedMail: "",
  saveModalPreference: false,
  selectedTabValues: {
    tab: "",
    subTab: "",
  },
  errorMessage: [],
  successMessage: "",
  guidedTourSteps: [],
  settingsStoredKey: "",
  showSettingsTour: false,
};

export const preferenceSlice = createSlice({
  name: "preference",
  initialState,
  reducers: {
    connectApp(state) {
      state.isAppConnected = !state.isAppConnected;
    },
    setShowSuccessModal(state, { payload }) {
      state.successMessage = payload.message;
    },

    onHideSuccessModal(state) {
      state.successMessage = "";
    },

    setShowErrorModal(state, { payload }) {
      state.errorMessage.push(payload.message);
    },

    onHideErrorModal(state) {
      state.errorMessage.shift();
    },
    onChangeSettingsTabKey(state, { payload }) {
      if (
        payload.isAppConnected &&
        !payload.setupLoading &&
        state.settingsActiveTab === "contact" &&
        (payload.selectedTab === "invoice" ||
          payload.selectedTab === "product") &&
        (!payload.isContactSyncRulesSaved ||
          !payload.isContactDefaultMappingsSaved ||
          !payload.isContactMappingsSaved)
      ) {
        state.selectedTabValues = {
          tab: payload.selectedTab,
          subTab: "syncRules",
        };
        state.saveModalPreference = true;
      } else if (
        payload.isAppConnected &&
        !payload.setupLoading &&
        state.settingsActiveTab === "invoice" &&
        (payload.selectedTab === "contact" ||
          payload.selectedTab === "product") &&
        (!payload.isInvoiceMappingsSaved || !payload.isInvoiceSyncRulesSaved)
      ) {
        state.selectedTabValues = {
          tab: payload.selectedTab,
          subTab: "syncRules",
        };
        state.saveModalPreference = true;
      } else if (
        payload.isAppConnected &&
        !payload.setupLoading &&
        state.settingsActiveTab === "product" &&
        (payload.selectedTab === "contact" ||
          payload.selectedTab === "invoice") &&
        (!payload.isProductSyncRulesSaved ||
          !payload.isProductFieldMappingsSaved)
      ) {
        state.selectedTabValues = {
          tab: payload.selectedTab,
          subTab: "syncRules",
        };
        state.saveModalPreference = true;
      } else {
        state.settingsActiveTab = payload.selectedTab;
      }
    },
    onChangeInvoiceActiveTab(state, { payload }) {
      if (
        payload.isAppConnected &&
        !payload.setupLoading &&
        state.invoiceActiveTab === "syncRules" &&
        !payload.isInvoiceSyncRulesSaved &&
        payload.selectedTab === "fieldMappings"
      ) {
        state.selectedTabValues = {
          tab: "invoice",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        payload.isAppConnected &&
        !payload.setupLoading &&
        state.invoiceActiveTab === "fieldMappings" &&
        !payload.isInvoiceMappingsSaved &&
        payload.selectedTab === "syncRules"
      ) {
        state.selectedTabValues = {
          tab: "invoice",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else {
        state.invoiceActiveTab = payload.selectedTab;
      }
    },
    onChangeContactActiveTab(state, { payload }) {
      if (
        payload.isAppConnected &&
        !payload.setupLoading &&
        state.contactActiveTab === "syncRules" &&
        !payload.isContactSyncRulesSaved &&
        (payload.selectedTab === "defaultMappings" ||
          payload.selectedTab === "companyFieldMappings" ||
          payload.selectedTab === "contactFieldMappings")
      ) {
        state.selectedTabValues = {
          tab: "contact",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        payload.isAppConnected &&
        !payload.setupLoading &&
        state.contactActiveTab === "defaultMappings" &&
        !payload.isContactDefaultMappingsSaved &&
        (payload.selectedTab === "syncRules" ||
          payload.selectedTab === "companyFieldMappings" ||
          payload.selectedTab === "contactFieldMappings")
      ) {
        state.selectedTabValues = {
          tab: "contact",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        payload.isAppConnected &&
        !payload.setupLoading &&
        state.contactActiveTab === "companyFieldMappings" &&
        !payload.isContactMappingsSaved &&
        (payload.selectedTab === "syncRules" ||
          payload.selectedTab === "defaultMappings" ||
          payload.selectedTab === "contactFieldMappings")
      ) {
        state.selectedTabValues = {
          tab: "contact",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        payload.isAppConnected &&
        !payload.setupLoading &&
        state.contactActiveTab === "contactFieldMappings" &&
        !payload.isContactMappingsSaved &&
        (payload.selectedTab === "syncRules" ||
          payload.selectedTab === "defaultMappings" ||
          payload.selectedTab === "companyFieldMappings")
      ) {
        state.selectedTabValues = {
          tab: "contact",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else {
        state.contactActiveTab = payload.selectedTab;
      }
    },
    onChangeProductActiveTab(state, { payload }) {
      if (
        payload.isAppConnected &&
        !payload.setupLoading &&
        state.productActiveTab === "syncRules" &&
        !payload.isProductSyncRulesSaved &&
        payload.selectedTab === "fieldMappings"
      ) {
        state.selectedTabValues = {
          tab: "product",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        payload.isAppConnected &&
        !payload.setupLoading &&
        state.productActiveTab === "fieldMappings" &&
        !payload.isProductFieldMappingsSaved &&
        payload.selectedTab === "syncRules"
      ) {
        state.selectedTabValues = {
          tab: "product",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else {
        state.productActiveTab = payload.selectedTab;
      }
    },
    onChangeSaveModalPreference(state, { payload }) {
      state.saveModalPreference = payload.show;
    },
    onChangeSelectedTabValues(state) {
      window.scrollTo(0, 0);
      if (state.selectedTabValues.tab === "contact") {
        state.settingsActiveTab = state.selectedTabValues.tab;
        state.contactActiveTab = state.selectedTabValues.subTab;
      } else if (state.selectedTabValues.tab === "invoice") {
        state.settingsActiveTab = state.selectedTabValues.tab;
        state.invoiceActiveTab = state.selectedTabValues.subTab;
      } else {
        state.settingsActiveTab = state.selectedTabValues.tab;
        state.productActiveTab = state.selectedTabValues.subTab;
      }
    },
    onUpdateTabs(state, { payload }) {
      state.selectedTabValues.tab = payload.tab;
      state.selectedTabValues.subTab = payload.subTab;
    },
    updateGuideSteps(state, { payload }) {
      state.guidedTourSteps = payload.steps;
    },
    updateSettingsStoredKey(state, { payload }) {
      state.settingsStoredKey = payload.key;
    },
    updateShowSettingsTour(state, { payload }) {
      state.showSettingsTour = payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatus.fulfilled, (state, actions) => {
      const { creds, userData } = actions.payload;

      if (creds?.actionApp) {
        state.isAppConnected = true;
      }

      if (userData?.actionApp) {
        state.connectedMail = userData?.actionApp?.emailId;
      }
    });
  },
});

export const {
  connectApp,
  setShowSuccessModal,
  setShowErrorModal,
  onHideSuccessModal,
  onHideErrorModal,
  onChangeSettingsTabKey,
  onChangeInvoiceActiveTab,
  onChangeContactActiveTab,
  onChangeProductActiveTab,
  onChangeSaveModalPreference,
  onChangeSelectedTabValues,
  onUpdateTabs,
  updateGuideSteps,
  updateSettingsStoredKey,
  updateShowSettingsTour,
} = preferenceSlice.actions;
export default preferenceSlice.reducer;
