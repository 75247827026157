import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  updatePipedriveUserIds,
  FinancialIframe,
  initalizePipedriveSDK,
} from "pipedrive-frontend";
import { fetchStatus, fetchFinancialData } from "generic";

import { IPdInitialState } from "pipedrive-frontend/types/types";

const FinancialIframePage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      initalizePipedriveSDK(id, 350, dispatch);
    }
  }, []);

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    if (companyId) {
      dispatch(updatePipedriveUserIds({ companyId, userId }));
    }
  }, []);

  useEffect(() => {
    const objectId = searchParams.get("selectedIds");
    let syncType = searchParams.get("syncType");

    syncType = syncType ? syncType : "organization";

    if (pipedriveUserIds && Object.keys(pipedriveUserIds).length > 0) {
      dispatch(fetchStatus({ userIds: pipedriveUserIds }));
      dispatch(
        fetchFinancialData({
          userIds: pipedriveUserIds,
          objectId:
            syncType === "organization"
              ? `org_${objectId}`
              : `person_${objectId}`,
        })
      );
    }
  }, [pipedriveUserIds]);

  return (
    <div>
      <Container className="d-flex flex-row justify-content-center">
        <FinancialIframe actionAppName="Microsoft Business Central" />
      </Container>
    </div>
  );
};

export default FinancialIframePage;
