import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { getUrlInGeneric } from "../helpers/url";
import { fetchStatus } from "./mappingSlice";
import { setShowErrorModal } from "./preferenceSlice";

import { IFinancialProps, IFinancialStates } from "../types/financialTypes";

const initialState: IFinancialStates = {
  timeDropdownFields: [
    {
      name: "Last Year",
      value: "lastYear",
    },
    {
      name: "Last Quarter",
      value: "lastQuarter",
    },
    {
      name: "Last 12 Months",
      value: "last12Months",
    },
    {
      name: "Year To Date",
      value: "yearToDate",
    },
  ],
  financialData: {
    loading: false,
    data: {},
    error: "",
    currentRequestId: undefined,
  },
  lastSynced: undefined,
  timePreference: undefined,
  financialObject: {},
};

export const fetchFinancialData = createAsyncThunk(
  "financial/fetchFinancialData",
  async (props: IFinancialProps, { dispatch }) => {
    const { userIds, objectId } = props;
    try {
      const res = await axios.get(
        getUrlInGeneric("REACT_APP_FETCH_FINANCIAL_DATA"),
        {
          params: {
            ...userIds,
            objectId,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    }
  }
);

const financialSlice = createSlice({
  name: "financial",
  initialState,
  reducers: {
    onChangeTime(state, { payload }) {
      state.timePreference = payload.selectedOption;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      const { preferences } = action.payload;
      const { customerSync } = preferences;

      state.timePreference = customerSync?.time
        ? customerSync?.time
        : undefined;
    });

    builder.addCase(fetchFinancialData.pending, (state, action) => {
      if (!state.financialData.loading) {
        state.financialData.loading = true;
        state.financialData.currentRequestId = action.meta.requestId;
      }
    });
    builder.addCase(fetchFinancialData.fulfilled, (state, action) => {
      if (
        state.financialData.loading &&
        state.financialData.currentRequestId === action.meta.requestId
      ) {
        state.financialData.loading = false;
        state.financialData.data = action.payload;
        state.financialData.error = "";
        state.financialData.currentRequestId = undefined;

        const {
          totalBookedInvoices,
          sumOfBookedInvoices,
          averageOfBookedInvoices,
          accountBalance,
          totalOverdueInvoices,
          lastSynced,
        } = state.financialData.data;

        state.lastSynced = lastSynced ? lastSynced : undefined;

        state.financialObject = {
          "Total number of booked invoices generated": totalBookedInvoices,
          "Sales revenue of booked invoices": sumOfBookedInvoices,
          "Average of booked invoices": averageOfBookedInvoices,
          "Account balance": accountBalance,
          "Total number of overdue invoices": totalOverdueInvoices,
        };
      }
    });
    builder.addCase(fetchFinancialData.rejected, (state, action) => {
      if (
        state.financialData.loading &&
        state.financialData.currentRequestId === action.meta.requestId
      ) {
        state.financialData.loading = false;
        state.financialData.error = action.error.message ?? "";
        state.financialData.data = {};
        state.financialData.currentRequestId = undefined;
      }
    });
  },
});

export const { onChangeTime } = financialSlice.actions;

export default financialSlice.reducer;
