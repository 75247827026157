import React, { useState } from "react";
import { Dropdown, Stack, Badge, CloseButton, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { onAddMultipleProducts } from "../slice/mappingSlice";

import { IField, IMappingItem, IMappingSlice } from "../types/mappingTypes";

interface IProps {
  mappingsFor: string;
  mappedItem: IMappingItem;
  deletedTriggerFields: {
    [k: string]: string[];
  };
  triggerAppObject: { [k: string]: IField[] };
  mappings: IMappingItem[];
}

const MultiSelectDropdown = (props: IProps) => {
  const dispatch = useDispatch();
  const {
    mappingsFor,
    mappedItem,
    deletedTriggerFields,
    triggerAppObject,
    mappings,
  } = props;

  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState({});

  const {
    mapping: {
      defaultMappings: { products },
    },
  } = useSelector((state: { mappings: IMappingSlice }) => state.mappings);

  /**
   * @description Creates an array of trigger app products mapped to the a particular action app product
   * @returns Array
   */
  const getMappedFields = (key) => {
    if (mappedItem.triggerApp[key] && mappedItem.triggerApp[key].length > 0) {
      return mappedItem.triggerApp[key].map(
        (triggerAppProduct) => triggerAppProduct.CRF
      );
    } else {
      return [];
    }
  };

  /**
   * @description This function is responsible for searching the fields inside dropdown
   * @param {String} enteredInput input value used for searching
   */
  const searchFields = (enteredInput) => {
    setSearchInput(enteredInput);
    const searchObject = {};

    if (triggerAppObject && Object.keys(triggerAppObject).length > 0) {
      Object.keys(triggerAppObject).forEach((key) => {
        searchObject[key] = triggerAppObject[key].filter((fieldItem) =>
          fieldItem.HRF.toLowerCase().includes(enteredInput.toLowerCase())
        );
      });
    }
    setSearchResults(searchObject);
  };

  /**
   * @description Disables the fields in the dropdown that are already mapped
   * @param {String} fieldItem - HubSpot product sku value
   * @returns
   */
  const disableCheckedFields = (fieldItem, key) => {
    const mappedFields = (
      mappingsFor === "products" ? products : mappings
    ).flatMap((mapping) => mapping.triggerApp[key].map((field) => field.CRF));

    const isFound = mappedFields.includes(fieldItem);
    if (isFound) {
      return !mappedItem.triggerApp[key]
        .map((product) => product.CRF)
        .includes(fieldItem);
    }

    return isFound;
  };

  return (
    <Dropdown className="custom-product-dropdown">
      <Dropdown.Toggle className="toggle-dropdown">
        {mappedItem?.triggerApp &&
        Object.keys(mappedItem.triggerApp).length > 0 &&
        Object.values(mappedItem.triggerApp).some(
          (fields) => fields.length > 0
        ) ? (
          <Stack direction="horizontal" gap={2} style={{ flexWrap: "wrap" }}>
            {Object.keys(mappedItem.triggerApp).map((key) =>
              mappedItem.triggerApp[key].map((field) => (
                <Badge
                  key={field.CRF}
                  className="d-flex flex-row justify-content-between badge-key"
                >
                  <span className="me-2">
                    {field.HRF}
                    {deletedTriggerFields?.[`${key}_${mappingsFor}`] &&
                      deletedTriggerFields[`${key}_${mappingsFor}`].includes(
                        field.CRF as string
                      ) && <span className="error-text">(deleted)</span>}
                  </span>
                  <CloseButton
                    style={{ fontSize: "8px" }}
                    onClick={() =>
                      dispatch(
                        onAddMultipleProducts({
                          checked: false,
                          mappingsFor,
                          mappedItem,
                          selectedValue: field,
                          selectedKey: key,
                        })
                      )
                    }
                  />
                </Badge>
              ))
            )}
          </Stack>
        ) : (
          <span style={{ fontSize: "14px" }}>Not mapped</span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu
        renderOnMount={true}
        popperConfig={{ strategy: "fixed" }}
        className="dropdown-menu"
      >
        <div className="p-3 search-container">
          <div className="d-flex flex-row search">
            <input
              type="search"
              placeholder="search.."
              className="w-100 search-input"
              onChange={(event) => searchFields(event.target.value)}
            />
          </div>
        </div>

        <div className="dropdown-items-container">
          {triggerAppObject &&
            Object.keys(triggerAppObject).map((key) => (
              <>
                <Dropdown.ItemText>
                  <b style={{ textTransform: "capitalize" }}>{key}</b>
                  <hr className="my-0 py-0" />
                </Dropdown.ItemText>

                {searchInput.length > 0 &&
                  searchResults?.[key] &&
                  searchResults[key].length === 0 && (
                    <div className="d-flex flex-column justify-content-center align-items-center my-2">
                      <p style={{ fontSize: "14px", fontWeight: 600 }}>
                        No results found
                      </p>
                    </div>
                  )}

                {(searchInput.length > 0
                  ? searchResults[key]
                  : triggerAppObject[key]
                ).map((fieldItem, index) => (
                  <div key={index} className="px-3 py-2 w-100">
                    <Form.Check
                      type="checkbox"
                      id={`${fieldItem.CRF}-${mappedItem.id}`}
                      label={fieldItem.HRF}
                      style={{ fontSize: "14px" }}
                      name="triggerAppDropdownFields"
                      value={fieldItem.CRF}
                      disabled={disableCheckedFields(fieldItem.CRF, key)}
                      checked={getMappedFields(key).includes(fieldItem.CRF)}
                      onChange={(event) => {
                        dispatch(
                          onAddMultipleProducts({
                            checked: event.target.checked,
                            mappingsFor,
                            mappedItem,
                            selectedValue: fieldItem,
                            selectedKey: key,
                          })
                        );
                      }}
                    />
                  </div>
                ))}
              </>
            ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelectDropdown;
