import React from "react";
import { Container, Row, Col } from "react-bootstrap";

interface IProps {
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
  contentCssName: string;
  cssName: string;
}

const Confirmation = (props: IProps) => {
  const { CmsRichText, contentCssName, cssName } = props;
  return (
    <div>
      <Container>
        <Row className="d-flex justify-content-center">
          <Col lg={10}>
            <div className={`${cssName} pt-5`}>
              <CmsRichText
                path="cmsContent.confirmationPage"
                cssName={contentCssName}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Confirmation;
