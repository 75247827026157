import React from "react";
import { Button } from "react-bootstrap";

export interface IPdButton {
  text: string;
  click?: () => void;
  fullWidth?: boolean;
  withArrow?: boolean;
  outlineButton?: boolean;
  className?: string;
}

const PdButton = (props: IPdButton) => {
  return (
    <div>
      <Button
        onClick={() => props.click()}
        className={`${props.className} pd-button ${
          props.fullWidth && "w-100"
        } ${props.outlineButton && "pd-outline-button"}`}
      >
        {props.text}
      </Button>
    </div>
  );
};

export default PdButton;
