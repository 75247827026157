import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { setShowErrorModal, fetchStatus } from "generic";

import { getUrlInMsbc } from "../helpers/url";

import { IMsbcProps, IMsbcStates } from "../types/msbcTypes";

const initialState: IMsbcStates = {
  fields: {
    loading: false,
    data: {},
    error: "",
    currentRequestId: null,
  },
  msbcDefaultFields: {
    loading: false,
    data: {},
    error: "",
    currentRequestId: null,
  },
  defaultMappings: {
    paymentTermsCodePreference: undefined,
    currencyCodePreference: undefined,
  },
  isContactDefaultMappingsSaved: false,
  isEnvPreferencesSaved: false,
  environmentPreference: undefined,
  companyPreference: undefined,
  environment: {
    loading: false,
    environmentDetails: {},
    error: "",
  },
  company: {
    loading: false,
    companyDetails: {},
    error: "",
  },
  tenantId: "",
};

export const fetchMsbcFields = createAsyncThunk(
  "msbc/fetchMsbcFields",
  async (props: IMsbcProps, { dispatch }) => {
    const { userIds } = props;
    try {
      const res = await axios.get(
        getUrlInMsbc("REACT_APP_FETCH_ACTION_APP_FIELDS"),
        {
          params: userIds,
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error?.response && error.response?.data?.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    }
  }
);

export const fetchEnvironment = createAsyncThunk(
  "msbc/fetchEnvironment",
  async (props: IMsbcProps, { dispatch }) => {
    const { userIds } = props;
    try {
      const res = await axios.get(getUrlInMsbc("REACT_APP_GET_ENVIRONMENT"), {
        params: userIds,
      });
      return res.data;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error?.response && error.response?.data?.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    }
  }
);

export const fetchCompanies = createAsyncThunk(
  "msbc/fetchCompanies",
  async (props: IMsbcProps, { dispatch }) => {
    const { userIds, environment } = props;
    try {
      const res = await axios.get(getUrlInMsbc("REACT_APP_GET_COMPANIES"), {
        params: {
          ...userIds,
          environment,
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error?.response && error.response?.data?.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    }
  }
);

export const getDefaultMappingFields = createAsyncThunk(
  "contact/getDefaultMappingFields",
  async (props: IMsbcProps, { dispatch }) => {
    const { userIds } = props;
    try {
      const { data: response } = await axios.get(
        getUrlInMsbc("REACT_APP_FETCH_ACTION_APP_DEFAULT_FIELDS"),
        {
          params: userIds,
        }
      );
      return response;
    } catch (error) {
      let errorMessage;
      if (error?.response && error.response?.data?.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    }
  }
);

export const msbcSlice = createSlice({
  name: "msbc",
  initialState,
  reducers: {
    onChangeMsbcDefaultFields(state, { payload }) {
      if (payload.selectingFor === "payment terms code") {
        state.defaultMappings.paymentTermsCodePreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "currency code") {
        state.defaultMappings.currencyCodePreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      }
    },

    setDropdownPreferences(state, { payload }) {
      if (payload.dropdownFor === "environment") {
        state.environmentPreference = payload.selectedOption;
      } else if (payload.dropdownFor === "company") {
        state.companyPreference = payload.selectedOption;
      }
      state.isEnvPreferencesSaved = false;
    },

    setIsEnvPreferencesSaved(state, { payload }) {
      state.isEnvPreferencesSaved = payload.isSaved;
    },

    setIsContactDefaultMappingsSaved(state) {
      state.isContactDefaultMappingsSaved = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEnvironment.pending, (state) => {
      if (!state.environment.loading) {
        state.environment.loading = true;
      }
    });
    builder.addCase(fetchEnvironment.fulfilled, (state, actions) => {
      if (state.environment.loading) {
        state.environment.environmentDetails = actions.payload;
        state.environment.loading = false;
        state.environment.error = "";
      }
    });
    builder.addCase(fetchEnvironment.rejected, (state, actions) => {
      if (state.environment.loading) {
        state.environment.loading = false;
        state.environment.environmentDetails = {};
        state.environment.error = actions.error.message ?? "";
      }
    });

    builder.addCase(fetchCompanies.pending, (state) => {
      if (!state.company.loading) {
        state.company.loading = true;
      }
    });
    builder.addCase(fetchCompanies.fulfilled, (state, actions) => {
      if (state.company.loading) {
        state.company.loading = false;
        state.company.companyDetails = actions.payload;
        state.company.error = "";
      }
    });
    builder.addCase(fetchCompanies.rejected, (state, actions) => {
      if (state.company.loading) {
        state.company.loading = false;
        state.company.companyDetails = {};
        state.company.error = actions.error.message ?? "";
      }
    });

    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      const isEnvSaved = action?.meta?.arg?.isSaved;

      const { preferences, userData } = action.payload;

      const { actionApp } = userData;

      const { customerSync } = preferences;

      const { defaultFields } = customerSync;

      if (actionApp && Object.keys(actionApp).length > 0) {
        state.tenantId = actionApp?.tenantId;
        state.companyPreference = actionApp?.companyId;
        state.environmentPreference = actionApp?.environment;
      }

      if (
        state.environmentPreference &&
        state.companyPreference &&
        isEnvSaved
      ) {
        state.isEnvPreferencesSaved = false;
      } else {
        state.isEnvPreferencesSaved = true;
      }

      if (defaultFields && Object.keys(defaultFields).length > 0) {
        const defaultFieldsData = {
          paymentTermsCodePreference: defaultFields?.paymentTerms?.value
            ? defaultFields?.paymentTerms
            : undefined,
          currencyCodePreference: defaultFields?.currency?.value
            ? defaultFields?.currency
            : undefined,
        };
        state.defaultMappings = defaultFieldsData;

        if (
          state.defaultMappings.paymentTermsCodePreference &&
          state.defaultMappings.currencyCodePreference
        ) {
          state.isContactDefaultMappingsSaved = true;
        }
      }
    });

    builder.addCase(fetchMsbcFields.pending, (state, action) => {
      if (!state.fields.loading) {
        state.fields.loading = true;
        state.fields.currentRequestId = action.meta.requestId;
      }
    });

    builder.addCase(fetchMsbcFields.fulfilled, (state, action) => {
      if (
        state.fields.loading &&
        state.fields.currentRequestId === action.meta.requestId
      ) {
        state.fields.loading = false;
        state.fields.data = action.payload;
        state.fields.error = "";
        state.fields.currentRequestId = undefined;
      }
    });

    builder.addCase(fetchMsbcFields.rejected, (state, action) => {
      if (
        state.fields.loading &&
        state.fields.currentRequestId === action.meta.requestId
      ) {
        state.fields.loading = false;
        state.fields.data = {};
        state.fields.error = action.error.message ?? "";
        state.fields.currentRequestId = undefined;
      }
    });

    builder.addCase(getDefaultMappingFields.pending, (state, actions) => {
      if (!state.msbcDefaultFields.loading) {
        state.msbcDefaultFields.loading = true;
        state.msbcDefaultFields.currentRequestId = actions.meta.requestId;
      }
    });
    builder.addCase(getDefaultMappingFields.fulfilled, (state, actions) => {
      if (
        state.msbcDefaultFields.loading &&
        state.msbcDefaultFields.currentRequestId === actions.meta.requestId
      ) {
        state.msbcDefaultFields.loading = false;
        state.msbcDefaultFields.data = actions.payload;
        state.msbcDefaultFields.error = "";
        state.msbcDefaultFields.currentRequestId = undefined;
      }
    });
    builder.addCase(getDefaultMappingFields.rejected, (state, actions) => {
      if (
        state.msbcDefaultFields.loading &&
        state.msbcDefaultFields.currentRequestId === actions.meta.requestId
      ) {
        state.msbcDefaultFields.loading = false;
        state.msbcDefaultFields.data = {};
        state.msbcDefaultFields.error = actions.error.message ?? "";
        state.msbcDefaultFields.currentRequestId = undefined;
      }
    });
  },
});

export const {
  setDropdownPreferences,
  setIsEnvPreferencesSaved,
  onChangeMsbcDefaultFields,
  setIsContactDefaultMappingsSaved,
} = msbcSlice.actions;
export default msbcSlice.reducer;
