import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { saveCustomerSyncRules } from "../helpers/saveCustomerSyncRules";
import { saveProductSyncRules } from "../helpers/saveProductSyncRules";
import invoiceSyncRulesSave from "../helpers/invoiceSyncRulesSave";
import { checkProductMappingsCount } from "../helpers/checkProductMappings";

import { IContactStates } from "../types/contactTypes";
import { IProductStates } from "../types/productTypes";
import { IInvoiceState } from "../types/invoiceTypes";
import { IMappingSlice } from "../types/mappingTypes";
import { IPreferenceStates } from "../types/preferencetypes";

type ButtonWithTooltipProps = {
  triggerAppName: string;
  actionAppName: string;
  savePreferencesFor: string;
  preferenceType: string;
  userIds: { [key: string]: unknown };
  disableBtnCssName: string;
  btnCssName: string;
  searchFilterOptionKeys: string[];
  triggerKeys: string[];
};

const ButtonWithTooltip = (props: ButtonWithTooltipProps) => {
  const {
    triggerAppName,
    actionAppName,
    savePreferencesFor,
    preferenceType,
    userIds,
    disableBtnCssName,
    btnCssName,
    searchFilterOptionKeys,
    triggerKeys,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    triggerAppContactFilterPreference,
    triggerAppCompanyFilterPreference,
    actionAppContactFilterPreference,
    actionAppCompanyFilterPreference,
    triggerAppCompanyNumberPreference,
    triggerAppContactNumberPreference,
    customerNumberPreference,
    isContactCheckbox,
  } = useSelector((state: { contact: IContactStates }) => state.contact);

  const {
    preferenceForProducts,
    productPreference,
    preferenceForSearchParameters,
    triggerAppProductFilterPreference,
    actionAppProductFilterPreference,
    preferenceForCreatingProduct,
    defaultProductForCreating,
    productGroupPreference,
    generalProductPostingGroupPreference,
    productTypePreference,
    dealSyncProductPreference,
    dealsSyncCheckbox,
    isOverAllDiscountPresent,
    overAllDiscountProductPreference,
  } = useSelector((state: { products: IProductStates }) => state.products);

  const {
    selectedPipelines,
    pipelinePreference,
    dealStagePreference,
    invoicePreference,
    attachPDFToInvoice,
  } = useSelector((state: { invoice: IInvoiceState }) => state.invoice);

  const {
    mapping: {
      defaultMappings: { products: productMappings },
    },
    isMappingSaved: { products },
  } = useSelector((state: { mappings: IMappingSlice }) => state.mappings);

  const { isAppConnected } = useSelector(
    (state: { preference: IPreferenceStates }) => state.preference
  );

  const showToolTipText = () => {
    if (!isAppConnected) {
      return `Please connect your ${actionAppName} account to proceed further.`;
    } else if (savePreferencesFor === "customerSyncRules") {
      if (!triggerAppCompanyFilterPreference) {
        return `The search parameter for ${triggerAppName} has not been selected. Please select it before proceeding.`;
      } else if (!actionAppCompanyFilterPreference) {
        return `The search parameter for ${actionAppName} has not been selected. Please select it before proceeding.`;
      } else if (!triggerAppCompanyNumberPreference) {
        return `${actionAppName} Customer Number ${triggerAppName} property is not selected. Please select it before proceeding.`;
      } else if (isContactCheckbox && !triggerAppContactNumberPreference) {
        return `The ${triggerAppName} Contact Number property is not selected. Please select it before proceeding.`;
      }
    } else if (savePreferencesFor === "productSyncRules") {
      if (preferenceForProducts === "select product" && !productPreference) {
        return "Please select the default product for using it as default product";
      } else if (
        preferenceForSearchParameters === "select search parameters" &&
        !triggerAppProductFilterPreference
      ) {
        return `Please select ${triggerAppName} search parameter`;
      } else if (
        preferenceForSearchParameters === "select search parameters" &&
        !actionAppProductFilterPreference
      ) {
        return `Please select ${actionAppName} search parameter`;
      } else if (
        preferenceForSearchParameters === "set custom mappings" &&
        checkProductMappingsCount({ productMappings }) === 0
      ) {
        return "Please select at least one product mapping";
      } else if (
        preferenceForSearchParameters === "set custom mappings" &&
        checkProductMappingsCount({ productMappings }) > 0 &&
        !products
      ) {
        return "Please save product mappings before proceeding";
      } else if (
        actionAppName === "e-conomic" &&
        preferenceForProducts === "find create product" &&
        !productGroupPreference
      ) {
        return "Please select product group";
      } else if (
        actionAppName === "Business Central" &&
        preferenceForProducts === "find create product" &&
        !generalProductPostingGroupPreference
      ) {
        return "Please select product posting group";
      } else if (
        actionAppName === "Business Central" &&
        preferenceForProducts === "find create product" &&
        !productTypePreference
      ) {
        return "Please select product type";
      } else if (
        isOverAllDiscountPresent &&
        !overAllDiscountProductPreference
      ) {
        return "Please select product for over all discount";
      } else if (dealsSyncCheckbox && !dealSyncProductPreference) {
        return "Please select product to sync deal without products";
      }
    } else if (savePreferencesFor === "invoiceSyncRules") {
      if (selectedPipelines.length === 0) {
        return "Please select the pipelines";
      } else if (pipelinePreference && !dealStagePreference) {
        return "please select the deal stage to add the pipeline";
      } else if (
        pipelinePreference &&
        dealStagePreference &&
        !invoicePreference
      ) {
        return "please select the option for creating invoice or order";
      }
    }
  };

  const savePreferences = () => {
    if (savePreferencesFor === "customerSyncRules") {
      saveCustomerSyncRules({
        triggerAppContactFilterPreference,
        triggerAppCompanyFilterPreference,
        actionAppCompanyFilterPreference,
        actionAppContactFilterPreference,
        triggerAppCompanyNumberPreference,
        customerNumberPreference,
        triggerAppContactNumberPreference,
        isContactCheckbox,
        searchFilterOptionKeys,
        setIsLoading,
        preferenceType,
        userIds,
        dispatch,
        notification: true,
      });
    } else if (savePreferencesFor === "productSyncRules") {
      saveProductSyncRules({
        preferenceForProducts,
        productPreference,
        preferenceForSearchParameters,
        triggerAppProductFilterPreference,
        actionAppProductFilterPreference,
        preferenceForCreatingProduct,
        defaultProductForCreating,
        dealSyncProductPreference,
        dealsSyncCheckbox,
        actionAppName,
        preferenceType,
        userIds,
        setIsLoading,
        dispatch,
        notification: true,
        ...(actionAppName === "e-conomic" ? { productGroupPreference } : {}),
        ...(actionAppName === "Business Central"
          ? { generalProductPostingGroupPreference, productTypePreference }
          : {}),
        triggerAppName,
        isOverAllDiscountPresent,
        overAllDiscountProductPreference,
      });
    } else if (savePreferencesFor === "invoiceSyncRules") {
      invoiceSyncRulesSave({
        setIsLoading,
        dispatch,
        selectedPipelines,
        attachPDFToInvoice,
        triggerKeys,
        userIds,
        notification: true,
      });
    }
  };

  return (
    <div
      className="d-flex flex-row justify-content-end"
      style={{ marginTop: "27px" }}
    >
      {!isAppConnected ||
      (savePreferencesFor === "customerSyncRules" &&
        (!triggerAppCompanyFilterPreference ||
          !actionAppCompanyFilterPreference ||
          !triggerAppCompanyNumberPreference ||
          (isContactCheckbox && !triggerAppContactNumberPreference))) ||
      (savePreferencesFor === "productSyncRules" &&
        ((actionAppName === "Business Central" &&
          preferenceForProducts === "find create product" &&
          (!generalProductPostingGroupPreference || !productTypePreference)) ||
          (isOverAllDiscountPresent && !overAllDiscountProductPreference) ||
          (dealsSyncCheckbox && !dealSyncProductPreference) ||
          (preferenceForProducts === "select product" && !productPreference) ||
          (preferenceForSearchParameters === "select search parameters" &&
            (!triggerAppProductFilterPreference ||
              !actionAppProductFilterPreference)) ||
          (preferenceForSearchParameters === "set custom mappings" &&
            (checkProductMappingsCount({ productMappings }) === 0 ||
              (checkProductMappingsCount({ productMappings }) > 0 &&
                !products))))) ||
      (savePreferencesFor === "invoiceSyncRules" &&
        (selectedPipelines.length === 0 ||
          (pipelinePreference && !dealStagePreference) ||
          (pipelinePreference &&
            dealStagePreference &&
            !invoicePreference))) ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip style={{ fontSize: "12px" }}>{showToolTipText()}</Tooltip>
          }
        >
          <Button className={`${disableBtnCssName}`}>Save</Button>
        </OverlayTrigger>
      ) : (
        <Button className={`${btnCssName}`} onClick={() => savePreferences()}>
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Save</span>
          )}
        </Button>
      )}
    </div>
  );
};

export default ButtonWithTooltip;
