import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  onChangeSaveModalPreference,
  onChangeSelectedTabValues,
  setShowErrorModal,
  setShowSuccessModal,
} from "../slice/preferenceSlice";

import { updateIsMappingSaved, fetchStatus } from "../slice/mappingSlice";

import { checkProductMappingsCount } from "../helpers/checkProductMappings";

import { saveCustomerSyncRules } from "../helpers/saveCustomerSyncRules";
import { saveProductSyncRules } from "../helpers/saveProductSyncRules";
import invoiceSyncRulesSave from "../helpers/invoiceSyncRulesSave";
import { onSaveMappings } from "../helpers/functions";

import { IContactStates } from "../types/contactTypes";
import { IProductStates } from "../types/productTypes";
import { IInvoiceState } from "../types/invoiceTypes";
import { IPreferenceStates } from "../types/preferencetypes";
import { IMappingSlice } from "../types/mappingTypes";

interface IActionAppFields {
  HRF: string;
  CRF: string | number;
  type: string;
  description: string;
  required?: boolean;
}

interface IProps {
  actionAppName: string;
  saveContactDefaultMappings: (object) => object;
  userIds: { [k: string]: string | number };
  /* eslint-disable @typescript-eslint/no-explicit-any */
  isContactDefaultMappingsSaved: any;
  defaultMappings: {
    [k: string]: unknown;
  };
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
  contentCssName: string;
  searchFilterOptionKeys: string[];
  triggerAppName: string;
  triggerKeys: string[];
  accordionFor?: {
    [key: string]: boolean;
  };
  actionAppKeys?: string[];
  actionAppFields?: {
    [key: string]: IActionAppFields[];
  };
}

const AutoSaveModal = (props: IProps) => {
  const {
    actionAppName,
    saveContactDefaultMappings,
    userIds,
    isContactDefaultMappingsSaved,
    defaultMappings,
    CmsRichText,
    contentCssName,
    searchFilterOptionKeys,
    triggerAppName,
    accordionFor,
    actionAppFields,
    actionAppKeys,
    triggerKeys,
  } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    saveModalPreference,
    contactActiveTab,
    invoiceActiveTab,
    productActiveTab,
    settingsActiveTab,
  } = useSelector(
    (state: { preference: IPreferenceStates }) => state.preference
  );

  const {
    isContactSyncRulesSaved,
    customerNumberPreference,
    triggerAppContactFilterPreference,
    actionAppContactFilterPreference,
    triggerAppCompanyFilterPreference,
    actionAppCompanyFilterPreference,
    triggerAppContactNumberPreference,
    triggerAppCompanyNumberPreference,
    isContactCheckbox,
  } = useSelector((state: { contact: IContactStates }) => state.contact);

  const { selectedPipelines, attachPDFToInvoice, isInvoiceSyncRulesSaved } =
    useSelector((state: { invoice: IInvoiceState }) => state.invoice);

  const {
    preferenceForProducts,
    productPreference,
    preferenceForCreatingProduct,
    defaultProductForCreating,
    preferenceForSearchParameters,
    triggerAppProductFilterPreference,
    actionAppProductFilterPreference,
    productGroupPreference,
    generalProductPostingGroupPreference,
    productTypePreference,
    isProductSyncRulesSaved,
    dealSyncProductPreference,
    dealsSyncCheckbox,
    isOverAllDiscountPresent,
    overAllDiscountProductPreference,
  } = useSelector((state: { products: IProductStates }) => state.products);

  const {
    isMappingSaved,
    mapping: {
      loading: setupLoading,
      defaultMappings: {
        products: productMappings,
        customerSync: customerMappings,
        invoiceSync: invoiceMappings,
        productFields: productFieldMappings,
      },
    },
  } = useSelector((state: { mappings: IMappingSlice }) => state.mappings);

  const customMappingWindowHeight = 200;

  const onSaveUnsavedChanges = async () => {
    if (settingsActiveTab === "contact") {
      if (contactActiveTab === "syncRules" && !isContactSyncRulesSaved) {
        await saveCustomerSyncRules({
          setIsLoading,
          userIds,
          triggerAppCompanyFilterPreference,
          actionAppCompanyFilterPreference,
          triggerAppContactFilterPreference,
          actionAppContactFilterPreference,
          triggerAppContactNumberPreference,
          triggerAppCompanyNumberPreference,
          customerNumberPreference,
          isContactCheckbox,
          searchFilterOptionKeys,
          notification: true,
          dispatch,
        });
      } else if (
        contactActiveTab === "defaultMappings" &&
        !isContactDefaultMappingsSaved
      ) {
        await saveContactDefaultMappings({
          setIsLoading,
          dispatch,
          userIds,
          setShowSuccessModal,
          setShowErrorModal,
          ...defaultMappings,
        });
      } else if (
        contactActiveTab === "companyFieldMappings" &&
        !isMappingSaved.customerSync
      ) {
        await onSaveMappings({
          mappings: customerMappings,
          dispatch,
          setIsLoading,
          mappingsFor: "customerSync",
          userIds,
          isAccordion: accordionFor.customerSync,
        });
      } else if (
        contactActiveTab === "contactFieldMappings" &&
        !isMappingSaved.customerSync
      ) {
        await onSaveMappings({
          mappings: customerMappings,
          dispatch,
          setIsLoading,
          mappingsFor: "customerSync",
          userIds,
          isAccordion: accordionFor.customerSync,
        });
      }
    } else if (settingsActiveTab === "invoice") {
      if (invoiceActiveTab === "syncRules" && !isInvoiceSyncRulesSaved) {
        await invoiceSyncRulesSave({
          setIsLoading,
          dispatch,
          selectedPipelines,
          attachPDFToInvoice,
          userIds,
          notification: true,
          triggerKeys,
        });
      } else if (
        invoiceActiveTab === "fieldMappings" &&
        !isMappingSaved.invoiceSync
      ) {
        await onSaveMappings({
          setIsLoading,
          dispatch,
          userIds,
          mappings: invoiceMappings,
          mappingsFor: "invoiceSync",
          isAccordion: accordionFor.invoiceSync,
        });
      }
    } else if (settingsActiveTab === "product") {
      if (productActiveTab === "syncRules" && !isProductSyncRulesSaved) {
        await saveProductSyncRules({
          setIsLoading,
          userIds,
          preferenceForProducts,
          productPreference,
          preferenceForCreatingProduct,
          defaultProductForCreating,
          preferenceForSearchParameters,
          triggerAppProductFilterPreference,
          actionAppProductFilterPreference,
          dealSyncProductPreference,
          dealsSyncCheckbox,
          actionAppName,
          dispatch,
          notification: true,
          ...(actionAppName === "e-conomic" ? { productGroupPreference } : {}),
          ...(actionAppName === "Business Central"
            ? { generalProductPostingGroupPreference, productTypePreference }
            : {}),
          triggerAppName,
          isOverAllDiscountPresent,
          overAllDiscountProductPreference,
        });

        if (
          !isMappingSaved.products &&
          preferenceForSearchParameters === "set custom mappings" &&
          checkProductMappingsCount({ productMappings }) > 0
        ) {
          await onSaveMappings({
            mappings: productMappings,
            dispatch,
            setIsLoading,
            mappingsFor: "products",
            userIds,
          });
        }
      } else if (
        productActiveTab === "fieldMappings" &&
        !isMappingSaved.productFields
      ) {
        await onSaveMappings({
          dispatch,
          setIsLoading,
          userIds,
          mappings: productFieldMappings,
          mappingsFor: "productFields",
        });
      }
    }
    dispatch(onChangeSaveModalPreference({ show: false }));
    dispatch(onChangeSelectedTabValues());
  };

  const onDiscardChanges = () => {
    dispatch(
      fetchStatus({
        userIds,
        accordionFor,
        fields: actionAppFields,
        fieldKeys: actionAppKeys,
      }) as any
    );
    dispatch(updateIsMappingSaved({ mappingsFor: "customerSync" }));
    dispatch(updateIsMappingSaved({ mappingsFor: "productFields" }));
    dispatch(updateIsMappingSaved({ mappingsFor: "invoiceSync" }));
    dispatch(updateIsMappingSaved({ mappingsFor: "products" }));
    dispatch(onChangeSaveModalPreference({ show: false }));
    dispatch(onChangeSelectedTabValues());
  };

  const renderContent = () => {
    if (
      selectedPipelines.length === 0 &&
      settingsActiveTab === "invoice" &&
      invoiceActiveTab === "syncRules"
    ) {
      return (
        <div>
          <CmsRichText
            path="cmsContent.notifications.autoSave.successContent.pipelineAndDealStage.message"
            cssName={contentCssName}
          />
          <div className="d-flex flex-row justify-content-end">
            <Button
              onClick={() => {
                dispatch(onChangeSaveModalPreference({ show: false }));
                window.scrollTo(0, 0);
              }}
              className="ms-3 save-changes-button"
            >
              Edit
            </Button>
          </div>
        </div>
      );
    } else if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      preferenceForSearchParameters === "set custom mappings" &&
      checkProductMappingsCount({ productMappings }) === 0
    ) {
      return (
        <div>
          <CmsRichText
            path="cmsContent.notifications.autoSave.successContent.productMapping.message"
            cssName={contentCssName}
          />
          <div className="d-flex flex-row justify-content-end">
            <Button
              onClick={() => {
                dispatch(onChangeSaveModalPreference({ show: false }));
                window.scrollTo(0, customMappingWindowHeight);
              }}
              className="ms-3 save-changes-button"
            >
              Edit
            </Button>
          </div>
        </div>
      );
    } else if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      preferenceForProducts === "select product" &&
      !productPreference
    ) {
      return (
        <div>
          <CmsRichText
            path="cmsContent.notifications.autoSave.successContent.useDefaultProduct.message"
            cssName={contentCssName}
          />
          <div className="d-flex flex-row justify-content-end">
            <Button
              className="save-changes-button"
              onClick={() => {
                dispatch(onChangeSaveModalPreference({ show: false }));
                window.scrollTo(0, document.body.scrollHeight);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
      );
    } else if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      dealsSyncCheckbox &&
      !dealSyncProductPreference
    ) {
      return (
        <div>
          <CmsRichText
            path="cmsContent.notifications.autoSave.successContent.missingLineItems.message"
            cssName={contentCssName}
          />
          <div className="d-flex flex-row justify-content-end">
            <Button
              className="save-changes-button"
              onClick={() => {
                dispatch(onChangeSaveModalPreference({ show: false }));
                window.scrollTo(0, document.body.scrollHeight);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
      );
    } else if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      isOverAllDiscountPresent &&
      !overAllDiscountProductPreference
    ) {
      return (
        <div>
          <CmsRichText
            path="cmsContent.notifications.autoSave.successContent.overAllDiscount.message"
            cssName={contentCssName}
          />
          <div className="d-flex flex-row justify-content-end">
            <Button
              className="save-changes-button"
              onClick={() => {
                dispatch(onChangeSaveModalPreference({ show: false }));
                window.scrollTo(0, document.body.scrollHeight);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
      );
    } else if (
      settingsActiveTab === "contact" &&
      contactActiveTab === "syncRules" &&
      (!triggerAppCompanyNumberPreference ||
        (isContactCheckbox && !triggerAppContactNumberPreference))
    ) {
      return (
        <div>
          <CmsRichText
            path="cmsContent.notifications.autoSave.successContent.missingContactNumber.message"
            cssName={contentCssName}
          />
          <div className="d-flex flex-row justify-content-end">
            <Button
              className="save-changes-button"
              onClick={() => {
                dispatch(onChangeSaveModalPreference({ show: false }));
                window.scrollTo(0, document.body.scrollHeight);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <CmsRichText
            path="cmsContent.notifications.autoSave.successContent.unSavedChanges.message"
            cssName={contentCssName}
          />
          <div className="d-flex flex-row justify-content-end">
            <Button
              className="discard-changes-button"
              onClick={() => onDiscardChanges()}
              disabled={setupLoading}
            >
              {setupLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Discard</span>
              )}
            </Button>
            <Button
              className="ms-3 save-changes-button"
              onClick={() => onSaveUnsavedChanges()}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Save changes</span>
              )}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <Modal
      show={saveModalPreference}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>{renderContent()}</Modal.Body>
    </Modal>
  );
};

export default AutoSaveModal;
