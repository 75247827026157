import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { onHideSuccessModal } from "generic";
import { IPreferenceStates } from "generic/types/preferencetypes";

type SuccessModalProps = {
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
};

const SuccessModal = (props: SuccessModalProps) => {
  const { CmsRichText } = props;
  const dispatch = useDispatch();
  const { successMessage } = useSelector(
    (state: { preference: IPreferenceStates }) => state.preference
  );
  const {
    cmsData: { loading: cmsLoading },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useSelector((state: { cms: any }) => state.cms);

  const time = 3000;

  useEffect(() => {
    // Added timer for hiding success modal
    if (successMessage) {
      const timer = setTimeout(() => {
        dispatch(onHideSuccessModal());
      }, time);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const checkContentFrom = () => {
    const message = successMessage.split(".");
    const messageFrom = message.includes("cmsContent");
    return messageFrom;
  };

  return (
    <div>
      <Modal
        className="my-modal"
        onHide={() => dispatch(onHideSuccessModal())}
        show={successMessage !== ""}
      >
        <div className="success-modal">
          <div className="d-flex flex-row justify-content-end">
            <Button
              className="success-modal-button"
              onClick={() => dispatch(onHideSuccessModal())}
            >
              ×
            </Button>
          </div>
          {checkContentFrom() ? (
            <>
              {!cmsLoading && (
                <CmsRichText
                  path={successMessage}
                  cssName="success-modal-message"
                />
              )}
            </>
          ) : (
            <p className="success-modal-message mb-4 mx-3">{successMessage}</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SuccessModal;
