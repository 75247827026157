import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Spinner,
  Container,
  Card,
  Button,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Loading, TextWithTooltip } from "pipedrive-frontend";
import { SetupDropdown, setShowErrorModal, setShowSuccessModal } from "generic";
import {
  onChangeMsbcDefaultFields,
  saveContactDefaultMappings,
  showTooltipText,
} from "msbc-frontend";
import { CmsRichText } from "cms";

import { IMsbcStates } from "msbc-frontend/types/msbcTypes";
import { ICmsData } from "cms/types/cmsTypes";
import { IPdInitialState } from "pipedrive-frontend/types/types";

const ContactDefaultMappings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    msbcDefaultFields: {
      loading: msbcDefaultFieldsLoading,
      data: { paymentTerms, currencies },
    },
    defaultMappings: { paymentTermsCodePreference, currencyCodePreference },
  } = useSelector((state: { msbc: IMsbcStates }) => state.msbc);

  const {
    cmsData: { loading: cmsLoading },
  } = useSelector((state: { cms: ICmsData }) => state.cms);

  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );

  return (
    <Container className="px-0">
      {cmsLoading || msbcDefaultFieldsLoading ? (
        <Loading />
      ) : (
        <>
          <Card className="sync-settings-card" style={{ marginTop: "29px" }}>
            <Card.Body className="sync-settings-card-body">
              <div>
                <CmsRichText
                  path="cmsContent.settings.contacts.defaultMappings"
                  cssName="pipedrive"
                />
                <Col sm={5} lg={3} className="mt-4 msbc-payment-terms">
                  <TextWithTooltip
                    label="Payment Terms Code"
                    tooltipText=" This field indicates the terms of payment for a
                    customer (e.g., net 30 days, upon receipt, etc.).
                    Select a default code that will be applied to all new
                    customers"
                  />
                  {paymentTerms && paymentTerms.length > 0 && (
                    <SetupDropdown
                      fieldItems={paymentTerms}
                      selectedValue={paymentTermsCodePreference}
                      onChangeValue={onChangeMsbcDefaultFields}
                      dropdownFor="defaultFields"
                      dropdownLabel="payment terms code"
                      cssName="settings-dropdown"
                    />
                  )}
                </Col>
                <Col sm={5} lg={3} className="mt-4 msbc-currency">
                  <TextWithTooltip
                    label="Currency Code"
                    tooltipText="Define the default currency to be used when a new
                    customer is created. This will determine the currency
                    in which all transactions with the customer will be
                    conducted"
                  />
                  {currencies && currencies.length > 0 && (
                    <SetupDropdown
                      fieldItems={currencies}
                      selectedValue={currencyCodePreference}
                      onChangeValue={onChangeMsbcDefaultFields}
                      dropdownFor="defaultFields"
                      dropdownLabel="currency code"
                      cssName="settings-dropdown"
                    />
                  )}
                </Col>
              </div>
            </Card.Body>
          </Card>
          <div
            className="d-flex flex-row justify-content-end mb-4"
            style={{ marginTop: "27px" }}
          >
            {!paymentTermsCodePreference || !currencyCodePreference ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>
                    {showTooltipText({
                      paymentTermsCodePreference,
                      currencyCodePreference,
                    })}
                  </Tooltip>
                }
              >
                <Button className="pd-disabled-btn">Save</Button>
              </OverlayTrigger>
            ) : (
              <Button
                className="pd-save-changes-btn"
                onClick={() =>
                  saveContactDefaultMappings({
                    setIsLoading,
                    userIds: pipedriveUserIds,
                    dispatch,
                    paymentTermsCodePreference,
                    currencyCodePreference,
                    setShowSuccessModal,
                    setShowErrorModal,
                  })
                }
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Save</span>
                )}
              </Button>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default ContactDefaultMappings;
