import axios from "axios";
import { getUrlInMsbc } from "./url";

import { setShowErrorModal, setShowSuccessModal } from "generic";
import { setIsEnvPreferencesSaved } from "../slice/msbcSlice";

const onSavePreferences = async ({
  tenantId,
  userIds,
  environmentPreference,
  companyPreference,
  setIsLoading,
  dispatch,
  notification,
  triggerAppName,
}) => {
  try {
    setIsLoading(true);
    let data;
    if (triggerAppName.toLowerCase() === "pipedrive") {
      data = {
        ...userIds,
        tenantId,
        environment: environmentPreference,
        msbcCompanyId: companyPreference,
      };
    } else {
      data = {
        environment: environmentPreference,
        companyId: companyPreference,
        ...userIds,
        tenantId,
      };
    }
    const response = await axios.post(getUrlInMsbc("REACT_APP_SAVE_ENV"), data);
    dispatch(setIsEnvPreferencesSaved({ isSaved: true }));

    if (response.status === 200 && window.location.pathname === "/settings") {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const searchParams = new URLSearchParams(url.search);
      searchParams.delete("tenantId");
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
      window.location.reload();
    }

    if (notification) {
      dispatch(
        setShowSuccessModal({
          message:
            "cmsContent.notifications.preference.successContent.preferencesSaved.message",
        })
      );
    }
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};

export default onSavePreferences;
