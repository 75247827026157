import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { setShowErrorModal, fetchStatus } from "generic";

import { getUrlInPd } from "../helpers/url";

import {
  IPdFieldResponse,
  IPdFieldProps,
  IPdFinancialProps,
  IPdFinancialResponse,
  IPdInitialState,
} from "../types/types";

const initialState: IPdInitialState = {
  fields: {
    loading: false,
    data: {},
    error: "",
    currentRequestId: null,
  },
  pipedriveUserIds: {},
  currentWindow: "",
  progress: {
    "Invoice Sync Rules": true,
    "Customer Preferences": false,
    "Product Sync Rules": false,
  },
  timeDropdownFields: [
    {
      name: "Last Year",
      value: "lastYear",
    },
    {
      name: "Last Quarter",
      value: "lastQuarter",
    },
    {
      name: "Last 12 Months",
      value: "last12Months",
    },
    {
      name: "Year To Date",
      value: "yearToDate",
    },
  ],
  financialData: {
    loading: false,
    data: {},
    error: "",
    currentRequestId: null,
  },
  lastSynced: undefined,
  timePreference: undefined,
  financialObject: {},
};

/**
 * This functions fetches fields from Pipedrive
 */
export const fetchPipedriveFields = createAsyncThunk(
  "pipedrive/fetchPipedriveFields",
  async (props: IPdFieldProps, { dispatch }) => {
    try {
      const { userIds } = props;
      const res: AxiosResponse<IPdFieldResponse> =
        await axios.get<IPdFieldResponse>(
          `${getUrlInPd("REACT_APP_FETCH_TRIGGER_APP_FIELDS")}`,
          {
            params: userIds,
          }
        );
      return res.data;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    }
  }
);

/**
 * This function is used to fetch the financial data
 */
export const fetchFinancialData = createAsyncThunk(
  "pipedrive/fetchFinancialData",
  async (props: IPdFinancialProps, { dispatch }) => {
    const { userIds, objectId } = props;
    try {
      const res: AxiosResponse<IPdFinancialResponse> =
        await axios.get<IPdFinancialResponse>(
          getUrlInPd("REACT_APP_FETCH_FINANCIAL_DATA"),
          {
            params: {
              ...userIds,
              objectId,
            },
          }
        );

      return res.data;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    }
  }
);

export const pipedriveSlice = createSlice({
  name: "pipedrive",
  initialState,
  reducers: {
    /**
     * Reducers updates the userIds for Pipedrive
     * @param state - initial state
     * @param param1 - payload
     */
    updatePipedriveUserIds: (state, { payload }) => {
      const { companyId, userId } = payload;

      state.pipedriveUserIds = {
        companyId: parseInt(companyId),
        userId: parseInt(userId),
      };
    },
    /**
     * Reducers updates the current window
     * @param state - initial state
     * @param param1 - payload
     */
    onChangeCurrentWindow(state, { payload }) {
      state.currentWindow = payload.currentWindow;
    },
    /**
     * Reducer updates the progress
     * @param state - initial state
     * @param param1 - payload
     */
    onChangeProgress(state, { payload }) {
      state.progress = {
        ...state.progress,
        ...payload,
      };
    },
    /**
     * Reducer updates the time preference
     * @param state - initial state
     * @param param1 - payload
     */
    onChangeTime(state, { payload }) {
      state.timePreference = payload.selectedOption;
    },
  },
  extraReducers: (builder) => {
    // Checks the status of the request for fetching fields
    builder.addCase(fetchPipedriveFields.pending, (state, action) => {
      if (!state.fields.loading) {
        state.fields.loading = true;
        state.fields.currentRequestId = action.meta.requestId;
      }
    });

    builder.addCase(fetchPipedriveFields.fulfilled, (state, action) => {
      if (
        state.fields.loading &&
        state.fields.currentRequestId === action.meta.requestId
      ) {
        state.fields.loading = false;
        state.fields.data = action.payload;
        state.fields.error = "";
        state.fields.currentRequestId = null;
      }
    });

    builder.addCase(fetchPipedriveFields.rejected, (state, action) => {
      if (
        state.fields.loading &&
        state.fields.currentRequestId === action.meta.requestId
      ) {
        state.fields.loading = false;
        state.fields.data = {};
        state.fields.error = action.error.message ?? "Something went wrong";
        state.fields.currentRequestId = null;
      }
    });

    // Checks the status of the request for fetching db data
    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      const { preferences } = action.payload;
      const { customerSync } = preferences;
      state.timePreference = customerSync?.time
        ? customerSync?.time
        : undefined;
    });

    // Checks the status of the request for fetching financial data
    builder.addCase(fetchFinancialData.pending, (state, action) => {
      if (!state.financialData.loading) {
        state.financialData.loading = true;
        state.financialData.currentRequestId = action.meta.requestId;
      }
    });
    builder.addCase(fetchFinancialData.fulfilled, (state, action) => {
      if (
        state.financialData.loading &&
        state.financialData.currentRequestId === action.meta.requestId
      ) {
        state.financialData.loading = false;
        state.financialData.data = action.payload;
        state.financialData.error = "";
        state.financialData.currentRequestId = null;

        const {
          totalBookedInvoices,
          sumOfBookedInvoices,
          averageOfBookedInvoices,
          accountBalance,
          totalOverdueInvoices,
          lastSynced,
        } = action.payload;

        state.lastSynced = lastSynced ? (lastSynced as string) : undefined;

        state.financialObject = {
          "Total number of booked invoices generated": totalBookedInvoices,
          "Sales revenue of booked invoices": sumOfBookedInvoices,
          "Average of booked invoices": averageOfBookedInvoices,
          "Account balance": accountBalance,
          "Total number of overdue invoices": totalOverdueInvoices,
        };
      }
    });
    builder.addCase(fetchFinancialData.rejected, (state, action) => {
      if (
        state.financialData.loading &&
        state.financialData.currentRequestId === action.meta.requestId
      ) {
        state.financialData.loading = false;
        state.financialData.error = action.error.message ?? "";
        state.financialData.data = {};
        state.financialData.currentRequestId = null;
      }
    });
  },
});

export default pipedriveSlice.reducer;

export const {
  updatePipedriveUserIds,
  onChangeTime,
  onChangeCurrentWindow,
  onChangeProgress,
} = pipedriveSlice.actions;
