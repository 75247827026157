import React, { useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { setDropdownPreferences } from "../slice/msbcSlice";

import onSavePreferences from "../helpers/saveEnvSelection";

import { IMsbcStates } from "../types/msbcTypes";

interface IEnvProps {
  cardCssName: string;
  CmsRichText: React.ComponentType<{ path: string; cssName?: string }>;
  cmsCssName: string;
  userIds: {
    [key: string]: string | number;
  };
  triggerAppName: string;
  changeCurrentWindow: () => void;
  isBackBtn?: boolean;
  iframeBackNavigation?: () => void;
}

interface IToggleTextProps {
  dropdownFor: string;
}

const EnvSelection = (props: IEnvProps) => {
  const {
    cardCssName,
    CmsRichText,
    cmsCssName,
    userIds,
    triggerAppName,
    changeCurrentWindow,
    isBackBtn,
    iframeBackNavigation,
  } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const {
    environmentPreference,
    companyPreference,
    environment: { loading: environmentLoading },
    environment: {
      environmentDetails: { environment },
    },
    company: {
      loading: companiesLoading,
      companyDetails: { companies },
    },
    tenantId,
  } = useSelector((state: { msbc: IMsbcStates }) => state.msbc);

  const toggleText = ({ dropdownFor }: IToggleTextProps) => {
    if (dropdownFor === "environment") {
      if (!environmentPreference) {
        return "Select environment";
      } else {
        const field =
          environment &&
          environment.find(
            (fieldItem) => fieldItem.name === environmentPreference
          );
        if (field) {
          return field.name;
        } else {
          return "selected environment deleted";
        }
      }
    }
    if (dropdownFor === "company") {
      if (!companyPreference) {
        return "select company";
      } else {
        const field = companies.find(
          (company) => company.id === companyPreference
        );
        if (field) {
          return field.name;
        } else {
          return "selected company deleted";
        }
      }
    }
  };

  const showToolTipText = () => {
    if (!environmentPreference) {
      return "Please select the environment";
    }
    if (!companyPreference) {
      return "Please select the company";
    }
  };

  return (
    <div
      className={`${cardCssName} py-5 px-5 d-flex flex-column justify-content-center`}
    >
      <div>
        <CmsRichText
          path="cmsContent.preference.heading"
          cssName={cmsCssName}
        />
      </div>
      {environmentLoading ? (
        <div className="d-flex flex-row justify-content-center align-items-center my-3">
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="sm"
          ></Spinner>
        </div>
      ) : (
        <div>
          <CmsRichText
            path="cmsContent.preference.description"
            cssName={cmsCssName}
          />
          <Col className="environment-dropdown">
            <label className="label-text">Select Environment</label>
            <Dropdown className="mt-2">
              <Dropdown.Toggle className="setup-fields-dropdown">
                {toggleText({ dropdownFor: "environment" })}
              </Dropdown.Toggle>
              <Dropdown.Menu
                renderOnMount={true}
                popperConfig={{ strategy: "fixed" }}
                className="setup-fields-dropdown-items-container"
              >
                {environment &&
                  environment.length > 0 &&
                  environment.map((env) => (
                    <Dropdown.Item
                      key={env.type}
                      className="px-3 dropdown-item"
                      onClick={() =>
                        dispatch(
                          setDropdownPreferences({
                            dropdownFor: "environment",
                            selectedOption: env.name,
                          })
                        )
                      }
                    >
                      {env.name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </div>
      )}
      {companiesLoading ? (
        <div className="d-flex flex-row justify-content-center align-items-center my-3">
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="sm"
          ></Spinner>
        </div>
      ) : (
        <>
          {environmentPreference && companies && (
            <Col className="mt-3 company-dropdown">
              <label className="label-text">Select Company</label>
              <Dropdown className="mt-2">
                <Dropdown.Toggle className="setup-fields-dropdown">
                  {toggleText({ dropdownFor: "company" })}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  renderOnMount={true}
                  popperConfig={{ strategy: "fixed" }}
                  className="setup-fields-dropdown-items-container"
                >
                  {companies &&
                    companies.length > 0 &&
                    companies.map((company) => (
                      <Dropdown.Item
                        className="px-3 dropdown-item"
                        key={company.id}
                        onClick={() =>
                          dispatch(
                            setDropdownPreferences({
                              dropdownFor: "company",
                              selectedOption: company.id,
                            })
                          )
                        }
                      >
                        {company.name}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          )}
        </>
      )}
      <div className="d-flex flex-row justify-content-between mt-3">
        {isBackBtn && (
          <Button
            className="hb-outline-button save-changes-button"
            onClick={() => iframeBackNavigation()}
          >
            Back
          </Button>
        )}
        {!companyPreference || !environmentPreference ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip style={{ fontSize: "11px" }}>
                {showToolTipText()}
              </Tooltip>
            }
          >
            <Button
              className="save-changes-button"
              style={{ opacity: "0.9", cursor: "not-allowed" }}
            >
              {window.location.pathname === "/environment" ||
              window.location.pathname === "/settings"
                ? "Save"
                : "Next"}
            </Button>
          </OverlayTrigger>
        ) : (
          <Button
            className="save-changes-button"
            onClick={() => {
              onSavePreferences({
                environmentPreference,
                setIsLoading,
                dispatch,
                companyPreference,
                notification:
                  window.location.pathname === "/environment" ? true : false,
                tenantId,
                userIds: userIds,
                triggerAppName,
              });
              if (window.location.pathname === "/setup") {
                changeCurrentWindow();
              }
            }}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>
                {window.location.pathname === "/environment" ||
                window.location.pathname === "/settings"
                  ? "Save"
                  : "Next"}
              </span>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EnvSelection;
