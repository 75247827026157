import AppExtensionsSDK from "@pipedrive/app-extensions-sdk";

import { setShowErrorModal } from "generic";

/**
 * Initializes pipedrive sdk
 * @param id - pipedrive app id
 * @param height - iframe height
 * @param dispatch - redux dispatch
 */
const initalizePipedriveSDK = async (id: string, height: number, dispatch) => {
  try {
    await new AppExtensionsSDK({ identifier: id }).initialize({
      size: { width: 1200, height: height },
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log(error);
    let message;
    if (error.response && error.response.data.message)
      message = error.response.data.message;
    else message = error.message;
    dispatch(setShowErrorModal({ message: message }));
  }
};

export { initalizePipedriveSDK };
