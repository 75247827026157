import React from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  SetupDropdown,
  onDeletePipeline,
  onAddPipeline,
  onDeleteSelectedPipelinePreferences,
  setSelectedInvoiceFields,
} from "generic";
import { useSelector, useDispatch } from "react-redux";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { IInvoiceState, IPipelinesStructure } from "generic/types/invoiceTypes";

type PipelineProps = {
  dropdownForInvoice?: IPipelinesStructure[];
  dropdownForInvoiceLabel?: string;
};

const AddPipelines = (props: PipelineProps) => {
  const { dropdownForInvoice, dropdownForInvoiceLabel } = props;
  const {
    pipelines: { pipelines },
    dealStage: { dealStages },
    invoiceDropdown,
    pipelinePreference,
    dealStagePreference,
    invoicePreference,
    selectedPipelines,
  } = useSelector((state: { invoice: IInvoiceState }) => state.invoice);

  const dispatch = useDispatch();

  return (
    <div>
      <label className="pd-pipeline-label">Add Pipeline</label>
      <div className="mb-3">
        <div className="pipedrive-pipeline-dropdown">
          {pipelines && (
            <SetupDropdown
              fieldItems={pipelines}
              onChangeValue={setSelectedInvoiceFields}
              selectedValue={pipelinePreference}
              cssName="install-flow-dropdown"
              dropdownFor="pipelines"
              dropdownLabel="Pipeline"
            />
          )}
        </div>

        <div className="pipedrive-dealstage-dropdown">
          {dealStages && pipelinePreference && (
            <SetupDropdown
              fieldItems={dealStages}
              onChangeValue={setSelectedInvoiceFields}
              selectedValue={dealStagePreference}
              cssName="install-flow-dropdown"
              dropdownFor="dealStage"
              dropdownLabel="Deal stage"
            />
          )}
        </div>

        <div className="pipedrive-generate-dropdown">
          {dealStagePreference && invoiceDropdown && !dropdownForInvoice && (
            <SetupDropdown
              fieldItems={invoiceDropdown}
              dropdownFor="preferenceForCreation"
              dropdownLabel="Order/Invoice"
              onChangeValue={setSelectedInvoiceFields}
              selectedValue={invoicePreference}
              cssName="install-flow-dropdown"
            />
          )}
        </div>
        <div className="pipedrive-generate-dropdown">
          {dealStagePreference && dropdownForInvoice && (
            <SetupDropdown
              fieldItems={dropdownForInvoice}
              dropdownFor="preferenceForCreation"
              dropdownLabel={dropdownForInvoiceLabel}
              onChangeValue={setSelectedInvoiceFields}
              selectedValue={invoicePreference}
              cssName="install-flow-dropdown"
            />
          )}
        </div>
      </div>
      <div className="d-flex flex-row">
        {!pipelinePreference || !dealStagePreference || !invoicePreference ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip style={{ fontSize: "12px" }}>
                Please select the pipeline, deal stage and preference for
                creation of Order/Invoice
              </Tooltip>
            }
          >
            <Button className="save-changes-disabled-button mt-2">
              Add Pipeline
            </Button>
          </OverlayTrigger>
        ) : (
          <Button
            className="save-changes-button mt-2"
            onClick={() => dispatch(onAddPipeline())}
          >
            Add Pipeline
          </Button>
        )}
        {pipelinePreference && (
          <Button
            className="clear-data-btn mt-2 ms-3"
            onClick={() => dispatch(onDeleteSelectedPipelinePreferences())}
          >
            Clear data
          </Button>
        )}
      </div>
      {selectedPipelines.length > 0 && (
        <div className="mt-3">
          <div>
            {selectedPipelines.map((item, index) => (
              <div
                className="settings-selected-pipeline-container w-100 d-flex flex-row justify-content-between align-items-center px-3 mb-2"
                key={index}
              >
                <div className="d-flex flex-row">
                  <p className="me-3 mb-0" style={{ fontSize: "12px" }}>
                    <b>Pipeline: </b>
                    <span>{item.pipeline.name}</span>
                  </p>
                  <p className="me-3 mb-0" style={{ fontSize: "12px" }}>
                    <b>Deal Stage: </b>
                    <span>{item.dealStage.name}</span>
                  </p>
                  <p className="mb-0" style={{ fontSize: "12px" }}>
                    <b>Generate: </b>
                    <span>{item.generate.name}</span>
                  </p>
                </div>
                <RiDeleteBinLine
                  size={20}
                  color="#213343"
                  style={{ cursor: "pointer" }}
                  onClick={() => dispatch(onDeletePipeline({ index }))}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPipelines;
